export const ActionTypes = {
    APPLICATION_COUNTRY: 'APPLICATION_COUNTRY',
    APPLICATION_TITLE: 'APPLICATION_TITLE',
    APPLICATION_APP_TITLE: 'APPLICATION_APP_TITLE',
    APPLICATION_BE_REQUEST: 'APPLICATION_BE_REQUEST',
    APPLICATION_BE_REQUEST_COMPLETED: 'APPLICATION_BE_REQUEST_COMPLETED',
    APPLICATION_USER_CREDENTIALS_CLEAR: 'APPLICATION_USER_CREDENTIALS_CLEAR',
    APPLICATION_USER_CREDENTIALS_SET: 'APPLICATION_USER_CREDENTIALS_SET',
    APPLICATION_USER_CREDENTIALS_REFRESH: 'APPLICATION_USER_CREDENTIALS_REFRESH',
    APPLICATION_FOLDER_SELECT: 'APPLICATION_FOLDER_SELECT',
    APPLICATION_SIGNATURES_FILTER_SET: 'APPLICATION_SIGNATURES_FILTER_SET',
    APPLICATION_FOLDER_CREATE: 'APPLICATION_FOLDER_CREATE',
    APPLICATION_FOLDER_RENAME: 'APPLICATION_FOLDER_RENAME',
    APPLICATION_FOLDER_RENAME_OK: 'APPLICATION_FOLDER_RENAME_OK',
    APPLICATION_MESSAGE_FILTER_SET: 'APPLICATION_MESSAGE_FILTER_SET',
    APPLICATION_MESSAGE_FILTER_KEYWORD_SET: 'APPLICATION_MESSAGE_FILTER_KEYWORD_SET',
    APPLICATION_MESSAGE_EDIT: 'APPLICATION_MESSAGE_EDIT',
    APPLICATION_MESSAGE_SELECT: 'APPLICATION_MESSAGE_SELECT',
    APPLICATION_SIGNATURE_SELECT: 'APPLICATION_SIGNATURE_SELECT',
    APPLICATION_MESSAGE_REFRESH: 'APPLICATION_MESSAGE_REFRESH',
    APPLICATION_MESSAGE_REFRESH_BE_REQUEST: 'APPLICATION_MESSAGE_REFRESH_BE_REQUEST',
    APPLICATION_MESSAGE_REFRESH_BE_REQUEST_COMPLETED: 'APPLICATION_MESSAGE_REFRESH_BE_REQUEST_COMPLETED',
    APPLICATION_MESSAGE_PRE_DOWNLOAD: 'APPLICATION_MESSAGE_PRE_DOWNLOAD',
    APPLICATION_SIGNATURE_PRE_DOWNLOAD: 'APPLICATION_SIGNATURE_PRE_DOWNLOAD',
    APPLICATION_MESSAGE_REPLACE_IMAGE: 'APPLICATION_MESSAGE_REPLACE_IMAGE',
    APPLICATION_OUTBOX_SEND: 'APPLICATION_OUTBOX_SEND',
    APPLICATION_OUTBOX_UPDATE_PROGRESS: 'APPLICATION_OUTBOX_UPDATE_PROGRESS',
    APPLICATION_OUTBOX_SET_SENT: 'APPLICATION_OUTBOX_SET_SENT',
    APPLICATION_OUTBOX_SET_ERROR: 'APPLICATION_OUTBOX_SET_ERROR',
    APPLICATION_OUTBOX_MESSAGE_PROCESSED: 'APPLICATION_OUTBOX_MESSAGE_PROCESSED',
    APPLICATION_OUTBOX_EVENT_NOTIFIED: 'APPLICATION_OUTBOX_EVENT_NOTIFIED',
    APPLICATION_ERROR_SET: 'APPLICATION_ERROR_SET',
    APPLICATION_EMAILS_PRE_DOWNLOAD: 'APPLICATION_EMAILS_PRE_DOWNLOAD', 
    APPLICATION_EMAIL_SELECT: 'APPLICATION_EMAIL_SELECT',
    APPLICATION_SMSLIST_PRE_DOWNLOAD: 'APPLICATION_SMSLIST_PRE_DOWNLOAD', 
    APPLICATION_SMS_SELECT: 'APPLICATION_SMS_SELECT',
    APPLICATION_DOCUMENTS_PRE_DOWNLOAD: 'APPLICATION_DOCUMENTS_PRE_DOWNLOAD', 
    APPLICATION_DOCUMENT_SELECT: 'APPLICATION_DOCUMENT_SELECT',
    APPLICATION_SERVICE_SELECT: 'APPLICATION_SERVICE_SELECT',
    LOGIN_FORM_VALUES_SET: 'LOGIN_FORM_VALUES_SET',
    MESSAGES_BE_REQUEST: 'MESSAGES_BE_REQUEST',
    MESSAGES_BE_REQUEST_COMPLETED: 'MESSAGES_BE_REQUEST_COMPLETED',
    MESSAGES_SET_CACHE: 'MESSAGES_SET_CACHE',
    MESSAGES_SET_FOLDER_CACHE: 'MESSAGES_SET_FOLDER_CACHE',
    MESSAGES_UPDATE_CACHE: 'MESSAGES_UPDATE_CACHE',
    MESSAGES_UPDATE_CACHE_IF_EXIST: 'MESSAGES_UPDATE_CACHE_IF_EXIST',
    MESSAGES_DELETE_FROM_CACHE: 'MESSAGES_DELETE_FROM_CACHE',
    MESSAGES_RENAME_CACHE: 'MESSAGES_RENAME_CACHE',
    MESSAGES_SET_SELECTED: 'MESSAGES_SET_SELECTED',
    MESSAGES_CLEAR_SELECTED: 'MESSAGES_CLEAR_SELECTED',
    MESSAGES_LOCK_ADD: 'MESSAGES_LOCK_ADD',
    MESSAGES_LOCK_REMOVE: 'MESSAGES_LOCK_REMOVE',
    LEFEBVRE_USER: 'LEFEBVRE_USER',
    LEFEBVRE_IDCLIENTELEF: 'LEFEBVRE_IDCLIENTELEF',
    LEFEBVRE_IDCLIENTE: 'LEFEBVRE_IDCLIENTE',
    LEFEBVRE_CASEFILE: 'LEFEBVRE_CASEFILE',
    LEFEBVRE_DATABASE: 'LEFEBVRE_DATABASE',
    LEFEBVRE_EMAIL: 'LEFEBVRE_EMAIL',
    LEFEBVRE_EMAIL_SET_SHOWN: 'LEFEBVRE_EMAIL_SET_SHOWN',
    LEFEBVRE_ACCOUNT: 'LEFEBVRE_ACCOUNT',
    LEFEBVRE_RESET_EMAIL: 'LEFEBVRE_RESET_EMAIL',
    LEFEBVRE_MAILCONTACTS: 'LEFEBVRE_MAILCONTACTS',
    LEFEBVRE_ACCOUNT_GUID: 'LEFEBVRE_ACCOUNT_GUID',
    LEFEBVRE_ACCOUNT_SIGN: 'LEFEBVRE_ACCOUNT_SIGN',
    LEFEBVRE_IDMAIL: "LEFEBVRE_IDMAIL",
    LEFEBVRE_TOKEN: "LEFEBVRE_TOKEN",
    LEFEBVRE_USER_NAME: 'LEFEBVRE_USER_NAME',
    LEFEBVRE_USER_AVAILABLE_SIGNATURES: 'LEFEBVRE_USER_AVAILABLE_SIGNATURES',
    LEFEBVRE_USER_AVAILABLE_EMAILS: 'LEFEBVRE_USER_AVAILABLE_EMAILS',
    LEFEBVRE_USER_NUM_AVAILABLE_SIGNATURES: 'LEFEBVRE_USER_NUM_AVAILABLE_SIGNATURES',
    LEFEBVRE_USER_NUM_AVAILABLE_EMAILS: 'LEFEBVRE_USER_NUM_AVAILABLE_EMAILS',
    LEFEBVRE_USER_BRANDINGS: 'LEFEBVRE_USER_BRANDINGS',
    LEFEBVRE_USER_APP: 'LEFEBVRE_USER_APP',
    LEFEBVRE_ENTITY_TYPE: 'LEFEBVRE_ENTITY_TYPE',
    LEFEBVRE_ENTITY: 'LEFEBVRE_ENTITY',
    LEFEBVRE_ID_USER_APP: 'LEFEBVRE_ID_USER_APP',
    LEFEBVRE_ID_DOCUMENT: 'LEFEBVRE_ID_DOCUMENT',
    LEFEBVRE_ADMINCONTACTS: 'LEFEBVRE_ADMINCONTACTS',
    LEFEBVRE_ROLES: 'LEFEBVRE_ROLES',
    LEFEBVRE_TARGETSERVICE: 'LEFEBVRE_TARGETSERVICE',
    LEFEBVRE_CENCONTACTS: 'LEFEBVRE_CENCONTACTS',
    LEFEBVRE_LEXCONTACTS: 'LEFEBVRE_LEXCONTACTS',
    LEFEBVRE_COMPANY_ID: 'LEFEBVRE_COMPANY_ID',
    LEFEBVRE_ENV: 'LEFEBVRE_ENV',
};