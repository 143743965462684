import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ACTIONS from "../../actions/lefebvre";
import { clearUserCredentials, setUserCredentials } from "../../actions/application";
import history from "../../routes/history";
import { parseJwt, getUserId, getGuid, getUserName, getApp, getIdEntityType, getIdEntity, getBbdd, getIdUserApp, getService } from "../../services/jwt";
import Cookies from 'js-cookie';
import { getUserSignatures, createBranding2, getBrandingTemplate, createUser, addOrUpdateBranding, addOrUpdateBrandingEmail, createTemplate, verifyJwtSignature } from "../../services/api-signaturit";
import LefebvreDallozBaseTemplate from "../../templates/LefebvreDallozBaseTemplate.json";

class UserLefebvre extends Component {
    constructor(props) {
        super(props);

        this.state = {
            readyToRedirect: false,
            readyToRedirectToLogin: false,
            isNewAccount: false,
            type: ''
        };
    }

    configureTemplates(type, auth){
        const templates = ["lefebvredalloz"];
        let template;
        templates.forEach(app => {        
            switch (app) {
                case "lefebvredalloz":
                    //template = ( type === "baseTemplates" ? LefebvreBaseTemplate : LefebvreDefaultTemplate);
                    template = LefebvreDallozBaseTemplate;
                    break;
                default:
                    break;
            }  
            createTemplate(template, auth)
            .then(res => {
                null
            })
        });
    }

    async componentDidMount() {
        let documentsInfo = [];
        const payload = (this.props.match.params.token ? parseJwt(this.props.match.params.token) : undefined);
        var user = (this.props.match.params.token ? getUserId(payload) : this.props.match.params.idUser);
        var name = (this.props.match.params.token ? (getUserName(payload) ? null : user) : "Anónimo");
        var guid = (this.props.match.params.token ? getGuid(payload) : null);
        var app = (this.props.match.params.token ? getApp(payload) : "lefebvredalloz");
        var idEntityType = (this.props.match.params.token ? getIdEntityType(payload) : null);
        var idEntity = (this.props.match.params.token ? getIdEntity(payload) : null);
        var bbdd = (this.props.match.params.token ? getBbdd(payload) : null);
        var idUserApp = (this.props.match.params.token ? getIdUserApp(payload) : null);
        var service = (this.props.match.params.token ? (getService(payload)) ? getService(payload) : 'signature' : 'signature');
        
        // var configureBaseTemplates = (this.props.match.params.token ? getConfigureBaseTemplates(payload) : false);
        // var configureDefaultTemplates = (this.props.match.params.token ? getConfigureDefaultTemplates(payload) : false);
       

        this.props.setUser(`IM0${user}`);
        this.props.setGuid(guid);
        this.props.setUserName(name);
        this.props.setUserApp(app);
        this.props.setIdUserApp(idUserApp);
        (idEntityType ? this.props.setIdEntityType(idEntityType) : null);
        (idEntity ? this.props.setIdEntity(idEntity) : null);
        (bbdd ? this.props.setDataBase(bbdd) : null);
        (service ? this.props.setTargetService(service) : null);       

        if (Date.now() >= payload.exp * 1000) {
            this.setState({type: 'expired'});
        } else {
            var signatureRole = payload.roles.some( e => e === 'Signaturit' || e === 'Firma Digital' || e === 'signature');
            var roleOk = signatureRole;

            if ( !roleOk && user === 'E1621396' ){
                roleOk = true;
            }

            if (roleOk){    
                var rolesList = [];
                (signatureRole) ? rolesList.push('Firma Digital') : null;
                this.props.setRoles(rolesList);

                getBrandingTemplate(app, this.props.match.params.token)
                .then(res => {
                    if (res.data == null || res.data == false) {
                        this.configureTemplates("baseTemplates", this.props.match.params.token);
                    }
                })
                
                // if (configureBaseTemplates){
                //     await this.configureTemplates("baseTemplates");
                // } 
                // if (configureDefaultTemplates){
                //     await this.configureDefaultTemplates("defaultTemplates");
                // }
        
                verifyJwtSignature(this.props.match.params.token)
                .then( res => {
                    if (res == true){
                        //const cookieName = user.split('@')[0];

                        var token = Cookies.get(`Lefebvre.Signaturit.${user}`)
                        
                        if (token){
                            Cookies.remove(`Lefebvre.Signaturit.${user}`, { path: '/', domain: (window.REACT_APP_ENVIRONMENT==='LOCAL' ? 'localhost': '.lefebvre.es')});
                        }

                        if (token === undefined) {
                            token = this.props.match.params.token;
                        }
                        //store the new token in cookie
                        Cookies.set(`Lefebvre.Signaturit.${user}`, this.props.match.params.token, {
                            expires: 1,
                            domain: (window.REACT_APP_ENVIRONMENT==='LOCAL' ? 'localhost': 'lefebvre.es')
                          });
        
                        this.props.setToken(this.props.match.params.token);
                        //token = this.props.match.params.token;
                    
                        if (signatureRole){
                            getUserSignatures(user, this.props.match.params.token, this.props.country)
                            .then( userInfo => {
                                if (userInfo && userInfo.errors && userInfo.errors.length > 0 && userInfo.errors[0].code && userInfo.errors[0].code === "SG18"){
                                    // No existe registro del usuario. Se tiene que crear registro de usuario nuevo con branding.
                                    // Primero se verifica el branding de la aplicación por la que entra
                                    getBrandingTemplate(app, this.props.match.params.token)
                                        .then(template => {
                                            var auxTemplate = JSON.stringify(template.data.configuration);
                                            auxTemplate = auxTemplate.replace(/{{lef_userName}}/g, name).replace(/{{lef_userLogo}}/g, '');
                                            var newTemplate = JSON.parse(auxTemplate);
                                            // Se crea el branding en Signaturit
                                            createBranding2(this.props.country, newTemplate, this.props.match.params.token, user)
                                                .then( branding => {
                                                    var userBranding = [{app: app, externalId: branding.id}];
                                                    // Se guarda el registro del usuario con los datos del branding recién creado.
                                                    createUser(user, this.props.match.params.token, this.props.country, userBranding);
                                                    this.props.setUserBrandings('signature', userBranding);
                                                })
                                                .catch(err => {
                                                    console.log('Error in createBranding', err);
                                                })
                                        })
                                        .catch(err => {
                                            console.log('Error in getBrandingTemplate:', err);
                                        })
                                } 
                                else {
                                    if (userInfo && userInfo.data && userInfo.data.brandings 
                                        && ((userInfo.data.brandings.constructor === Object && Object.entries(userInfo.data.brandings).length === 0) 
                                            || !userInfo.data.brandings.find(b => b.app === app))){
                                        // El usuario existe pero no tenemos branding configurado para la aplicación desde la que entra.
                                        getBrandingTemplate(app, this.props.match.params.token)
                                            .then(template => {
                                                var auxTemplate = JSON.stringify(template.data.configuration);
                                                auxTemplate = auxTemplate.replace(/{{lef_userName}}/g, name).replace(/{{lef_userLogo}}/g, '');
                                                var newTemplate = JSON.parse(auxTemplate);
                                                // Se crea el branding en signaturit
                                                createBranding2(this.props.country, newTemplate, this.props.match.params.token, user)
                                                    .then( res => {
                                                        var userBranding = [{app: app, externalId: res.id}];
                                                        // Se añade la información del branding al registro del usuario.
                                                        addOrUpdateBranding(user, userBranding[0], this.props.match.params.token, this.props.country);
                                                        this.props.setUserBrandings('signature', userBranding);
                                                    })
                                                    .catch( err => {
                                                        console.log('Se ha producido un error al guardar el branding', err);
                                                    })
                                            })
                                            .catch(err => {
                                                console.log('Error in getBrandingTemplate', err);
                                            })
                                    } else {
                                        // Usuario y branding ya existentes
                                        this.props.setUserBrandings('signature', userInfo.data.brandings);
                                    }
                                }
                            })
                        }
                        this.setState({readyToRedirect: true})
                    }
                    else {
                        this.setState({type: 'unauthorized'})
                    }
                })
                .catch(err => {
                    this.setState({type: 'unauthorized'})
                })
            }
            else {
                this.setState({type: 'unauthorized'})
            }
        }
    }
    
    render() {
        const {
            readyToRedirect,
            type,
            readyToRedirectToLogin,
            isNewAccount
        } = this.state;
        if (readyToRedirect) {
            this.props.setUserCredentials(this.props.lefebvre.user, this.props.lefebvre.user, {authenticated: true, encrypted: this.props.lefebvre.token, salt: "1234", name: ""})
            return <Redirect to="/" />;
        } else if (type !== ''){
            switch (type) {
                case 'expired':
                    return <Redirect to ="/expired-fr" />;
                    break;
                case 'unauthorized':
                    return <Redirect to = "/unauthorized-fr" />;
                default:
                    break;
            }
            return <Redirect to="/" />;
        } else {
            return null;
        }

        //if (isNewAccount || readyToRedirectToLogin) {
        //  //this.props.logout();
        //  return <Redirect to="/login" />;
        //}
        //return <Redirect to="/" />;
        //return null;
    }
}

const mapStateToProps = state => ({
    country: state.application.country,
    lefebvre: state.lefebvre
});

const mapDispatchToProps = dispatch => ({
    setUser: user => dispatch(ACTIONS.setUser(user)),
    setAccount: account => dispatch(ACTIONS.setAccount(account)),
    // setCaseFile: casefile => dispatch(ACTIONS.setCaseFile(casefile)),
    setDataBase: dataBase => dispatch(ACTIONS.setDataBase(dataBase)),
    //setIdEmail: emailInfo => dispatch(ACTIONS.setIdEmail(emailInfo)),
    setMailContacts: mailContacts => dispatch(ACTIONS.setMailContacts(mailContacts)),
    logout: () => {
        dispatch(clearUserCredentials());
        history.push("/login");
    },
    setToken: token => dispatch(ACTIONS.setToken(token)),
    setUserCredentials: (userId, hash, credentials) => dispatch(setUserCredentials(userId, hash, credentials)),
    setGuid: guid => dispatch(ACTIONS.setGUID(guid)),
    setUserName: name => dispatch(ACTIONS.setUserName(name)),
    setAvailableSignatures: num => dispatch(ACTIONS.setAvailableSignatures(num)),
    setNumAvailableSignatures: num => dispatch(ACTIONS.setNumAvailableEmails(num)),
    setUserBrandings: (service, brandings) => dispatch(ACTIONS.setUserBrandings(service, brandings)),
    setUserApp: app => dispatch(ACTIONS.setUserApp(app)),
    setIdEntityType: id => dispatch(ACTIONS.setIdEntityType(id)),
    setIdEntity: id => dispatch(ACTIONS.setIdEntity(id)),
    setIdUserApp: id => dispatch(ACTIONS.setIdUserApp(id)),
    setIdDocuments: ids => dispatch(ACTIONS.setIdDocuments(ids)),
    setAdminContacts: adminContacts => dispatch(ACTIONS.setAdminContacts(adminContacts)),
    setRoles: roles => dispatch(ACTIONS.setRoles(roles)),
    setTargetService: service => dispatch(ACTIONS.setTargetService(service)),
    setCenContacts: contacts => dispatch(ACTIONS.setCenContacts(contacts))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLefebvre);