import React, {Fragment} from 'react';
import { connect } from "react-redux";
import {translate} from 'react-i18next';
import ButtonModules from './button-modules';
import ButtonUser from './button-user';
import ButtonMinihub from './button-minihub';
import mainCss from '../../styles/main.scss';
import styles from './top-bar-message-list.scss';
import {Link} from "react-router-dom";
import { setMessageFilterKey, setMessageFilterKeyword } from "../../actions/application";

const picUrl2 = '/assets/images/LogoLefebvreFirma_negativo.png';
const picUrl3 =  '/assets/images/lefebvre-dalloz (blanco).svg';

export class TopBarMessageList extends React.Component{
    constructor(props){
        super(props);
        this.state = {searchTerm: ""};
    }
    render() {
      const { country } = this.props;
      return(
        <div className={mainCss['mdc-top-app-bar__row']}>      
          <section className={`${mainCss['mdc-top-app-bar__section']} ${mainCss['mdc-top-app-bar__section--align-start']}  ${styles['modules-item-custom']}`}>  
            <div>
              {
                (country === 'FR')
                ? <Link to="/"><img className={`${styles['main-header-img-fr']}`} border="0" alt="Lefebvre-Dalloz" src={picUrl3}></img></Link>
                : <Link to="/"><img className={`${styles['main-header-img']}`} border="0" alt="Lefebvre" src={picUrl2}></img></Link>
              }
            </div>
          </section>
          <section  className={`${mainCss['mdc-top-app-bar__section']} ${mainCss['mdc-top-app-bar__section--align-start']}  ${styles['modules-item-custom']}`}/>
          
          <section className={`${mainCss['mdc-top-app-bar__section']} ${mainCss['mdc-top-app-bar__section--align-end']}  ${styles['modules-item-custom']}`}>
            <ButtonUser className={`${styles['modules-item-custom']}`} />
            <div className={`${styles['modules-item-custom-space']}`}></div>
              {country === 'ES' && <ButtonMinihub className={`${styles['modules-item-custom']}`} />}
          </section>              
        </div>
      )       
    }
}

const mapStateToProps = state => ({
  country: state.application.country,
}) 

const mapDispatchToProps = dispatch => ({
  setMessageFilter: messageFilter => dispatch(setMessageFilterKey(messageFilter)),
  setMessageFilterKeyword: keyword => dispatch(setMessageFilterKeyword(keyword))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(TopBarMessageList));