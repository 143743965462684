import React, { Component } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';

import stylesES from './footer.scss'
import stylesFR from './footerFR.scss'

class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {
      styles: null
    }
    switch (this.props.country) {
      case "FR":
        this.state.styles = stylesFR;
        break;
      case "ES":
        this.state.styles = stylesES;
      default:
        break;
    }
  }
  render() {
    const { country } = this.props;
    const { styles } = this.state;
    return (
      <footer className={styles['footer']}>
        <div className={`row ${styles['footer-row']}`}>
          {
            country === 'ES' && 
            <div className={`col-md-5 ${styles['footer-logo']}`}>
              <a
                rel='noopener noreferrer'
                href='https://lefebvre.es'
                target='_blank'>
                <img
                  alt='LEFEBVRE'
                  src='https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-ij-bl-160x57.png'
                />
              </a>
            </div>
          }
          
          <div className={`${styles['col-md-6']} ${styles['footer-social']} col-md-6`}>
            {
            country === 'FR'
            ?
              <ul className={styles['social-squares']}>
                <li style={{ marginRight: 0 }}>
                  <a
                    rel='noopener noreferrer'
                    href='https://twitter.com/LefebvreDalloz'
                    target='_blank'
                    title='Twitter'>
                    <span className='lf-icon-twitter-round'></span>
                  </a>
                </li>
                <li>
                  <a
                    rel='noopener noreferrer'
                    href='https://www.linkedin.com/company/lefebvre-dalloz/'
                    target='_blank'
                    title='Linkedin'>
                    <span className='lf-icon-linkedin-round'></span>
                  </a>
                </li>
                <li>
                  <a
                    rel='noopener noreferrer'
                    href='https://www.instagram.com/lefebvre_dalloz/'
                    target='_blank'
                    title='Facebook'>
                    <span className='lf-icon-instagram-round'></span>
                  </a>
                </li>
              </ul>   
            :
              <ul className={styles['social-squares']}>
                <li>
                  <a
                    rel='noopener noreferrer'
                    href='https://www.facebook.com/Lefebvre.es'
                    target='_blank'
                    title='Facebook'>
                    <span className='lf-icon-facebook-round'></span>
                  </a>
                </li>
                <li>
                  <a
                    rel='noopener noreferrer'
                    href='https://www.linkedin.com/company/lefebvre_es'
                    target='_blank'
                    title='Linkedin'>
                    <span className='lf-icon-linkedin-round'></span>
                  </a>
                </li>
                <li>
                  <a
                    rel='noopener noreferrer'
                    href='https://www.youtube.com/channel/UCAxbOiNpUotcbZIRL3IxEDg'
                    target='_blank'
                    title='Youtube'>
                    <span className='lf-icon-youtube-round'></span>
                  </a>
                </li>
                <li style={{ marginRight: 0 }}>
                  <a
                    rel='noopener noreferrer'
                    href='https://twitter.com/edicionesfl'
                    target='_blank'
                    title='Twitter'>
                    <span className='lf-icon-twitter-round'></span>
                  </a>
                </li>
              </ul>
            }
            
          </div>
        </div>
        <div className={`row ${styles['compliance-footer']}`}>
          {
            country === 'FR'
            ?
              <p className='col-xs-12'>
                {i18n.t('footerFR.all-reserved-right')}
                <span>
                  {window.LEGAL_WARNING_URL_FR && window.LEGAL_WARNING_URL_FR !== '' && (
                    <span>
                      &nbsp;
                      <a
                        rel='noopener noreferrer'
                        href={window.LEGAL_WARNING_URL_FR}
                        target='_blank'
                        title={i18n.t('footerFR.legal-warning')}>
                        {i18n.t('footerFR.legal-warning')}
                      </a>{' '}
                    </span>
                  )}
                  {window.PRIVACY_POLICY_URL_FR && window.PRIVACY_POLICY_URL_FR !== '' && (
                    <span>
                      &nbsp;|&nbsp;
                      <a
                        rel='noopener noreferrer'
                        href={window.PRIVACY_POLICY_URL_FR}
                        target='_blank'
                        title={i18n.t('footerFR.privacy-policy')}>
                        {i18n.t('footerFR.privacy-policy')}
                      </a>{' '}
                    </span>
                  )}
                  {window.COOKIES_POLICY_URL_FR && window.COOKIES_POLICY_URL_FR !== '' && (
                    <span>
                      &nbsp;|&nbsp;
                      <a
                        rel='noopener noreferrer'
                        href={window.COOKIES_POLICY_URL_FR}
                        target='_blank'
                        title={i18n.t('footerFR.cookies-policy')}>
                        {i18n.t('footerFR.cookies-policy')}
                      </a>
                    </span>
                  )}
                </span>
              </p>
            :
              <p className='col-xs-12'>
                {i18n.t('footer.all-reserved-right')}
                <span>
                  {window.LEGAL_WARNING_URL && window.LEGAL_WARNING_URL !== '' && (
                    <span>
                      &nbsp;
                      <a
                        rel='noopener noreferrer'
                        href={window.LEGAL_WARNING_URL}
                        target='_blank'
                        title={i18n.t('footer.legal-warning')}>
                        {i18n.t('footer.legal-warning')}
                      </a>{' '}
                    </span>
                  )}
                  {window.PRIVACY_POLICY_URL && window.PRIVACY_POLICY_URL !== '' && (
                    <span>
                      &nbsp;|&nbsp;
                      <a
                        rel='noopener noreferrer'
                        href={window.PRIVACY_POLICY_URL}
                        target='_blank'
                        title={i18n.t('footer.privacy-policy')}>
                        {i18n.t('footer.privacy-policy')}
                      </a>{' '}
                    </span>
                  )}
                  {window.COOKIES_POLICY_URL && window.COOKIES_POLICY_URL !== '' && (
                    <span>
                      &nbsp;|&nbsp;
                      <a
                        rel='noopener noreferrer'
                        href={window.COOKIES_POLICY_URL}
                        target='_blank'
                        title={i18n.t('footer.cookies-policy')}>
                        {i18n.t('footer.cookies-policy')}
                      </a>
                    </span>
                  )}
                  {window.TERMS_AND_CONDITIONS_URL &&
                    window.TERMS_AND_CONDITIONS_URL !== '' && (
                      <span>
                        &nbsp;|&nbsp;
                        <a
                          rel='noopener noreferrer'
                          href={window.TERMS_AND_CONDITIONS_URL}
                          target='_blank'
                          title={i18n.t('footer.terms-and-conditions')}>
                          {i18n.t('footer.terms-and-conditions')}
                        </a>
                      </span>
                    )}
                </span>
              </p>            
          }
          
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => ({
  country: state.application.country
});

export default connect(mapStateToProps, null)(Footer);
