import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import MenuContainer from '../folders/menu-container';
import { DroppablePayloadTypes } from '../folders/menu-list';
import IconButton from '../buttons/icon-button';
import mainCss from '../../styles/main.scss';
import styles from './side-bar.scss';
import { editNewMessage } from '../../services/application';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { getAvailableSignatures, getNumAvailableSignatures } from '../../services/api-signaturit';
import { setAvailableSignatures, setNumAvailableSignatures, setUserApp } from '../../actions/lefebvre';
import { setTitle, setAppTitle } from '../../actions/application';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SendingTypeSelector from './sending-type-selector/sending-type-selector';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragOver: false,
      hideAlertDialog: false,
      hideSendingTypeDialog: false,
      hideConfirmDialog: false
    };
    this.handleOnNewMessage = this.onNewMessage.bind(this);
    this.handleOnNewEmailCertificate = this.onNewEmailCertificate.bind(this);
    this.handleOnNewSmsCertificate = this.onNewSmsCertificate.bind(this);
    this.handleOnNewDocumentCertificate = this.onNewDocumentCertificate.bind(this);
    this.handleOnNewSending = this.onNewSending.bind(this);
    //this.dialogClose = this.dialogClose.bind(this);
    
    //Sin firmas 
    this.animationSettings = { effect: 'None' };
    this.alertButtonRef = element => {
      this.alertButtonEle = element;
    };
    this.alertButtons = [{
      // Click the footer buttons to hide the Dialog
      click: () => {
          this.setState({ hideAlertDialog: false });
      },
      buttonModel: { content: 'Aceptar', isPrimary: true }
    }];
  }

  buttonClick(args) {
    if (args.target.innerHTML.toLowerCase() == 'alert') {
        this.setState({ hideAlertDialog: true });
    }
  }

  dialogClose() {
    this.setState({
        hideAlertDialog: false,
        hideConfirmDialog: false
    });
    //this.alertButtonEle.style.display = 'inline-block';
  }

  sendTypeDialogClose() {
    this.setState({
      hideSendingTypeDialog: false
  });
  }

  dialogOpen() {
    //this.alertButtonEle.style.display = 'none';
  }

  onFocus(args) {
      this.spanEle.classList.add('e-input-focus');
  }

  onBlur(args) {
      this.spanEle.classList.remove('e-input-focus');
  }

  getConfirmDiscargMsg(){
    if (this.props.lefebvre.userApp === "centinela"){
      return `
        <span class="lf-icon-question modal-icon-content"></span>
        <div class="modal-text-content">
          ${i18n.t('cancelCentinelaConfirmation.text')}
        </div>
      `;
    } else if (this.props.lefebvre.userApp === "lexon"){
      return `
        <span class="lf-icon-question modal-icon-content"></span>
        <div class="modal-text-content">
          ${i18n.t('cancelLexonConfirmation.text')}
        </div>
      `;
    } else if (this.props.lefebvre.userApp === "customerarea"){
      return `
        <span class="lf-icon-question modal-icon-content"></span>
        <div class="modal-text-content">
          ${i18n.t('cancelCustomerareaConfirmation.text')}
        </div>
      `;
    } else {
      return "";
    }
  }



  render() {
    const { t, collapsed, lefebvre } = this.props;
    const { dragOver } = this.state;

    const contenido = `
      <img border='0' src='assets/images/icon-warning.png'></img>
      <div class="modal-text-align-content">
        ${i18n.t('noCreditsModal.text')}
        ${i18n.t('noCreditsModal.text2')}
      </div>`;

    const confirmDiscard = this.getConfirmDiscargMsg();

    const confirmButtons = [
      {
          click: () => {
          this.setState({ hideConfirmDialog: false });
          },
          buttonModel: {  content: i18n.t('confirmationModal.no'), cssClass: 'btn-modal-close' }
      },
      {
          click: () => {
              this.setState({ hideConfirmDialog: false });
              this.onDiscardSignatureOk();
          },
          buttonModel: { content: i18n.t('confirmationModal.yes'), isPrimary: true }
      }
    ];

    return (
      <aside
        onDragOver={this.handleOnDragOver}
        onDragLeave={this.handleOnDragLeave}
        onDrop={this.handleOnDrop}
        className={`${styles['side-bar']}
          ${mainCss['mdc-drawer']}
          ${mainCss['mdc-drawer--dismissible']}
          ${SideBar.getCollapsedClassName(collapsed)}
          ${dragOver ? styles.dropZone : ''}`}>
        <div
          className={`${mainCss['mdc-drawer__header']} 
          ${styles['top-container']} 
          ${!collapsed ? styles['divheader'] : 
          styles['divheader-without-side-bar']}`}>
          {this.props.errors.diskQuotaExceeded && (
            <span
              isotip={t('sideBar.errors.diskQuotaExceeded')}
              isotip-position='bottom-start'
              isotip-size='small'>
              disc_full
            </span>
          )}
          <div className={`${!collapsed ? styles['add-signature-toggle'] : ''}`}>

            { collapsed ?  
              <span
               className={styles['toggle-without-side-bar']}
               isotip-position='bottom-end'
               isotip-size='larger'>
                <IconButton onClick={this.props.sideBarToggle}>
                <span className='lf-icon-angle-right'></span>
                </IconButton>   
              </span> :  
              <span
               className={styles['button-toggle']}
               isotip={t('sideBar.hide')}
               isotip-position='bottom-end'
               isotip-size='larger'>
                <IconButton onClick={this.props.sideBarToggle}>
                <span className='lf-icon-angle-left'></span>
                </IconButton> 
               </span> 
            } 
            <button
              style={{ height: 48 }}
              className={`${mainCss['mdc-button']}
                      ${mainCss['mdc-button']} ${styles['nueva-firma']}`}
              onClick={this.handleOnNewSending}>
              <img
                className={styles.plusbuttton}
                border='0'
                src='assets/images/plus.png'></img>
              { !collapsed ?<span className='mdc-button__label' style={{ fontSize: 10.6 }}>
                {t('sideBar.newRequest')}
              </span> : "" }
            </button>
          </div>
        </div>
        <PerfectScrollbar>
          <MenuContainer collapsed={collapsed} country={this.props.application.country}/>
        </PerfectScrollbar>
        <DialogComponent 
          id="sendingTypeDialog" 
          header={i18n.t('sideBar.typeSending')}
          visible={this.state.hideSendingTypeDialog} 
          animationSettings={this.animationSettings} 
          width='40%' 
          showCloseIcon={true} 
          open={this.dialogOpen.bind(this)} 
          close={this.sendTypeDialogClose.bind(this)} >
          <SendingTypeSelector 
            onNewMessage={this.handleOnNewMessage}
            onNewEmailCertificate={this.handleOnNewEmailCertificate}
            onNewSmsCertificate={this.handleOnNewSmsCertificate}
            onNewDocumentCertificate={this.handleOnNewDocumentCertificate}
            lefebvre={lefebvre}
          />
        </DialogComponent>
        <DialogComponent 
          id='noSignaturesDialog'
          header='' 
          visible={this.state.hideAlertDialog} 
          animationSettings={this.animationSettings} 
          width='50%' 
          showCloseIcon={true} 
          content={contenido}//'Lo sentimos has agotado el número máximo de firmas contratadas. Si lo deseas, puedes contactar con nuestro departamento de atención a cliente en el teléfono 911231231 o pinchando aquí' 
          ref={alertdialog => this.alertDialogInstance = alertdialog} 
          open={this.dialogOpen.bind(this)} 
          close={this.dialogClose.bind(this)}
        />
        <DialogComponent 
          id="confirmDialog" 
          header='' 
          visible={this.state.hideConfirmDialog} 
          showCloseIcon={true} 
          animationSettings={this.animationSettings} 
          width='60%' 
          content={confirmDiscard} 
          ref={dialog => this.confirmDialogInstance = dialog} 
          //target='#target' 
          buttons={confirmButtons} 
          open={this.dialogOpen.bind(this)} 
          close={this.dialogClose.bind(this)}
        />
      </aside>
    );
  }


  onNewSending() {
    this.setState({hideSendingTypeDialog: true});
  }

  onDiscardSignatureOk(){
    const {lefebvre, application} = this.props

    cancelSignatureCen(lefebvre.guid)
    .then(res => {
      null
    })
    .catch(err => {
      console.log(err);
    })

    this.setState({ hideConfirmDialog: false });

    getAvailableSignatures(lefebvre.idClienteLef, 1)
      .then(response => {
        setAvailableSignatures(response.data);
        if (response.data === false || response.data === "false"){
          //alert('Ha agotado todas sus solicitudes de firma. Debe comprar más');
          this.setState({ hideAlertDialog: true });
          // Lo pongo para poder probar siempre aunque devuelva false, luego hay que quitar las tres líneas que siguen este comentario.
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            this.props.setAvailableSignatures(response.data);
            this.props.setTitle(t('messageEditor.title'));
            this.props.setAppTitle(i18n.t('topBar.app'));
            this.props.newMessage('signature', lefebvre.sign);
            this.props.setUserApp('lefebvre');
            this.props.setMailContacts(null);
            this.props.setAdminContacts(null);
            this.props.setGuid(null);
            this.props.setIdDocuments(null);

          }
        } else {
          this.props.setAvailableSignatures(response.data);
          this.props.setTitle(t('messageEditor.title'));
          this.props.setAppTitle(i18n.t('topBar.app'));
          this.props.newMessage('signature', lefebvre.sign);
          this.props.setUserApp('lefebvre');
          this.props.setMailContacts(null);
          this.props.setAdminContacts(null);
          this.props.setGuid(null);
          this.props.setIdDocuments(null);
        }
      })
      .catch(err => {
        if (err.message === "Failed to fetch"){
          //Mostrar aviso no se han podido recuperar firmas
          //alert('No se ha podido comprobar si tiene firmas disponibles');
          this.setState({ hideAlertDialog: true });
          // this.props.setAvailableSignatures(1);
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            this.props.setAppTitle(i18n.t('topBar.app'));
            this.props.newMessage('signature', lefebvre.sign);
            this.props.setTitle(t('messageEditor.title'));
            this.props.setUserApp('lefebvre');
            this.props.setMailContacts(null);
            this.props.setAdminContacts(null);
            this.props.setGuid(null);
            this.props.setIdDocuments(null);
          }
        }
      })
    
    getNumAvailableSignatures(lefebvre.idClienteLef)
      .then(res => this.props.setNumAvailableSignatures(parseInt(res.data)))
      .catch(err => {
          console.log(err);
          this.props.setNumAvailableSignatures(0);
      });
  }

  onNewMessage() {
    const { lefebvre, t, application } = this.props;

    if ((lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === "2" || lefebvre.userApp === "lex" || lefebvre.userApp === "lexon" || lefebvre.userApp === "1" || lefebvre.userApp === "customerarea" || lefebvre.userApp === "9") && (application.selectedSignature === null || application.selectedSignature === {})){
      this.setState({hideConfirmDialog: true});
    } 
    else {
      getAvailableSignatures(lefebvre.idClienteLef, 1)
      .then(response => {
        setAvailableSignatures(response.data);
        if (response.data === false || response.data === "false"){
          //alert('Ha agotado todas sus solicitudes de firma. Debe comprar más');
          this.setState({ hideAlertDialog: true });
          // Lo pongo para poder probar siempre aunque devuelva false, luego hay que quitar las tres líneas que siguen este comentario.
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.setAvailableSignatures(response.data);
              this.props.newMessage('signature', lefebvre.sign);
              this.props.setAppTitle(i18n.t('topBar.app'));
              this.props.setTitle(i18n.t('messageEditor.title'));
              this.props.setUserApp('lefebvre');
            }
          }
        } else {
          this.props.setAvailableSignatures(response.data);
          this.props.newMessage('signature', lefebvre.sign);
          this.props.setAppTitle(i18n.t('topBar.app'));
              this.props.setTitle(i18n.t('messageEditor.title'));
          this.props.setUserApp('lefebvre');        }
      })
      .catch(err => {
        if (err.message === "Failed to fetch"){
          //Mostrar aviso no se han podido recuperar firmas
          //alert('No se ha podido comprobar si tiene firmas disponibles');
          this.setState({ hideAlertDialog: true });
          // this.props.setAvailableSignatures(1);
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.newMessage('signature', lefebvre.sign);
              this.props.setAppTitle(i18n.t('topBar.app'));
              this.props.setTitle(i18n.t('messageEditor.title'));
              this.props.setUserApp('lefebvre');
            }
          }
        }
      })

      getNumAvailableSignatures(lefebvre.idClienteLef)
      .then(res => this.props.setNumAvailableSignatures(parseInt(res.data)))
      .catch(err => {
          console.log(err);
          this.props.setNumAvailableSignatures(0);
      });
    }
    this.sendTypeDialogClose();
  }

  onNewEmailCertificate() {
    const { lefebvre, t, application } = this.props;

    if ((lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === "2" || lefebvre.userApp === "lex" || lefebvre.userApp === "lexon" || lefebvre.userApp === "1" || lefebvre.userApp === "customerarea" || lefebvre.userApp === "9") && (application.selectedSignature === null || application.selectedSignature === {})){
      this.setState({hideConfirmDialog: true});
    } 
    else {
      getAvailableSignatures(lefebvre.idClienteLef, 1)
      .then(response => {
        setAvailableSignatures(response.data);
        if (response.data === false || response.data === "false"){
          //alert('Ha agotado todas sus solicitudes de firma. Debe comprar más');
          this.setState({ hideAlertDialog: true });
          // Lo pongo para poder probar siempre aunque devuelva false, luego hay que quitar las tres líneas que siguen este comentario.
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.setAvailableSignatures(response.data);
              this.props.newMessage('emailCertificate', null);
              this.props.setAppTitle(i18n.t('topBar.certifiedEmail'));
              this.props.setTitle(i18n.t('messageEditor.certifiedEmailTitle'));
              this.props.setUserApp('lefebvre');
            }
          }
        } else {
          this.props.setAvailableSignatures(response.data);
          this.props.newMessage('emailCertificate', null);
          this.props.setAppTitle(i18n.t('topBar.certifiedEmail'));
          this.props.setTitle(i18n.t('messageEditor.certifiedEmailTitle'));
          this.props.setUserApp('lefebvre');        }
      })
      .catch(err => {
        if (err.message === "Failed to fetch"){
          //Mostrar aviso no se han podido recuperar firmas
          //alert('No se ha podido comprobar si tiene firmas disponibles');
          this.setState({ hideAlertDialog: true });
          // this.props.setAvailableSignatures(1);
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.newMessage('emailCertificate', null);
              this.props.setAppTitle(i18n.t('topBar.certifiedEmail'));
              this.props.setTitle(i18n.t('messageEditor.certifiedEmailTitle'));
              this.props.setUserApp('lefebvre');
            }
          }
        }
      })

      getNumAvailableSignatures(lefebvre.idClienteLef)
      .then(res => this.props.setNumAvailableSignatures(parseInt(res.data)))
      .catch(err => {
          console.log(err);
          this.props.setNumAvailableSignatures(0);
      });
    }
    this.sendTypeDialogClose();
  }

  onNewSmsCertificate() {
    const { lefebvre, t, application } = this.props;

    if ((lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === "2" || lefebvre.userApp === "lex" || lefebvre.userApp === "lexon" || lefebvre.userApp === "1" || lefebvre.userApp === "customerarea" || lefebvre.userApp === "9") && (application.selectedSignature === null || application.selectedSignature === {})){
      this.setState({hideConfirmDialog: true});
    } 
    else {
      getAvailableSignatures(lefebvre.idClienteLef, 1)
      .then(response => {
        setAvailableSignatures(response.data);
        if (response.data === false || response.data === "false"){
          //alert('Ha agotado todas sus solicitudes de firma. Debe comprar más');
          this.setState({ hideAlertDialog: true });
          // Lo pongo para poder probar siempre aunque devuelva false, luego hay que quitar las tres líneas que siguen este comentario.
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.setAvailableSignatures(response.data);
              this.props.newMessage('smsCertificate', null);
              this.props.setAppTitle(i18n.t('topBar.certifiedSms'));
              this.props.setTitle(i18n.t('messageEditor.certifiedSmsTitle'));
            }
          }
        } else {
          this.props.setAvailableSignatures(response.data);
          this.props.newMessage('smsCertificate', null);
          this.props.setAppTitle(i18n.t('topBar.certifiedSms'));
          this.props.setTitle(i18n.t('messageEditor.certifiedSmsTitle'));
          this.props.setUserApp('lefebvre');        }
      })
      .catch(err => {
        if (err.message === "Failed to fetch"){
          //Mostrar aviso no se han podido recuperar firmas
          //alert('No se ha podido comprobar si tiene firmas disponibles');
          this.setState({ hideAlertDialog: true });
          // this.props.setAvailableSignatures(1);
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.newMessage('smsCertificate', null);
              this.props.setAppTitle(i18n.t('topBar.certifiedSms'));
              this.props.setTitle(i18n.t('messageEditor.certifiedSmsTitle'));
              this.props.setUserApp('lefebvre');
            }
          }
        }
      })

      getNumAvailableSignatures(lefebvre.idClienteLef)
      .then(res => this.props.setNumAvailableSignatures(parseInt(res.data)))
      .catch(err => {
          console.log(err);
          this.props.setNumAvailableSignatures(0);
      });
    }
    this.sendTypeDialogClose();
  }

  onNewDocumentCertificate() {
    const { lefebvre, t, application } = this.props;

    if ((lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === "2" || lefebvre.userApp === "lex" || lefebvre.userApp === "lexon" || lefebvre.userApp === "1" || lefebvre.userApp === "customerarea" || lefebvre.userApp === "9") && (application.selectedSignature === null || application.selectedSignature === {})){
      this.setState({hideConfirmDialog: true});
    } 
    else {
      getAvailableSignatures(lefebvre.idClienteLef, 1)
      .then(response => {
        setAvailableSignatures(response.data);
        if (response.data === false || response.data === "false"){
          //alert('Ha agotado todas sus solicitudes de firma. Debe comprar más');
          this.setState({ hideAlertDialog: true });
          // Lo pongo para poder probar siempre aunque devuelva false, luego hay que quitar las tres líneas que siguen este comentario.
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.setAvailableSignatures(response.data);
              this.props.newMessage('documentCertificate', null);
              this.props.setAppTitle(i18n.t('topBar.certifiedDocument'));
              this.props.setTitle(i18n.t('messageEditor.certifiedDocumentTitle'));
              this.props.setUserApp('lefebvre');
            }
          }
        } else {
          this.props.setAvailableSignatures(response.data);
          this.props.newMessage('documentCertificate', null);
          this.props.setAppTitle(i18n.t('topBar.certifiedDocument'));
	        this.props.setTitle(i18n.t('messageEditor.certifiedDocumentTitle'));
          this.props.setUserApp('lefebvre');        }
      })
      .catch(err => {
        if (err.message === "Failed to fetch"){
          //Mostrar aviso no se han podido recuperar firmas
          //alert('No se ha podido comprobar si tiene firmas disponibles');
          this.setState({ hideAlertDialog: true });
          // this.props.setAvailableSignatures(1);
          if (window.REACT_APP_ENVIRONMENT === 'PRE' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){
            if (lefebvre.userId === 'E1621396'){
              this.props.newMessage('documentCertificate', null);
    	        this.props.setAppTitle(i18n.t('topBar.certifiedDocument'));
	            this.props.setTitle(i18n.t('messageEditor.certifiedDocumentTitle'));
              this.props.setUserApp('lefebvre');
            }
          }
        }
      })

      getNumAvailableSignatures(lefebvre.idClienteLef)
      .then(res => this.props.setNumAvailableSignatures(parseInt(res.data)))
      .catch(err => {
          console.log(err);
          this.props.setNumAvailableSignatures(0);
      });
    }
    this.sendTypeDialogClose();
  }

  static getCollapsedClassName(collapsed) {
    return collapsed ? '' : `${styles.open} ${mainCss['mdc-drawer--open']}`;
  }
}

SideBar.propTypes = {
  t: PropTypes.func.isRequired,
  sideBarToggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  application: PropTypes.object,
  errors: PropTypes.object,
  newMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  application: state.application,
  errors: state.application.errors,
  lefebvre: state.lefebvre
});

const mapDispatchToProps = dispatch => ({
  newMessage: (sendingType, sign) => editNewMessage(dispatch, sendingType, [], [], sign),
  setAvailableSignatures: num => dispatch(setAvailableSignatures(num)),
  setNumAvailableSignatures: num => dispatch(setNumAvailableSignatures(num)),
  setTitle: title => dispatch(setTitle(title)),
  setAppTitle: title => dispatch(setAppTitle(title)),
  setUserApp: app => dispatch(setUserApp(app))
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    setAvailableSignatures: num => dispatchProps.setAvailableSignatures(num),
    setNumAvailableSignatures: num => dispatchProps.setNumAvailableSignatures(num),
    setTitle: title => dispatchProps.setTitle(title),
    setUserApp: app => dispatchProps.setUserApp(app)
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate()(SideBar));
