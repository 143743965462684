import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import HeaderAddress from './header-address';
import { getCredentials } from '../../selectors/application';
import { editMessage, setTitle, setSelectedService, setSignaturesFilterKey, selectEmail, selectSms, selectSignature } from '../../actions/application';
import { getAddresses } from '../../services/message-addresses';
import { persistApplicationNewMessageContent } from '../../services/indexed-db';
import styles from './message-editor.scss';
import mainCss from '../../styles/main.scss';
import i18n from 'i18next';
import ACTIONS from '../../actions/lefebvre';
import ComposeMessageEditor from './composeMessageEditor.jsx';

import Spinner from "../spinner/spinner";
import {
  createEmail,
  addOrUpdateEmail,
  getUserEmails,
  notifySignature,
  notifyCen,
  notifyLex,
  notifyCus,
  cancelSignatureCen,
  cancelSignatureLex,
  cancelSignatureCus,
  preloadEmails,
  getNumAvailableSignatures,
  getAvailableSignatures,
  searchEmails
} from '../../services/api-signaturit';
import * as uuid from 'uuid/v4';
import { getFileType } from '../../services/mimeType';
import  AttachmentsWidget  from './widgets/attachments-widget2';
import  CertificatesWidget  from './widgets/certificates-widget';
import { DialogComponent } from '@syncfusion/ej2-react-popups';


class EmailMessageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkDialogVisible: false,
      linkDialogUrl: '',
      dropZoneActive: false,
      editorState: {},
      selectedCertificationOption: 1,
      certificationType: 'delivery',
      hideAlertDialog: false,
      creditsDialog: false,
      hideConfirmDialog: false,
      bigAttachments: false,
      centinelaDownloadError: (props.attachmentsDownloadError !== undefined) ? props.attachmentsDownloadError : false,
      lexonDownloadError: (props.attachmentsDownloadError !== undefined) ? props.attachmentsDownloadError : false,
      customerareaDownloadError: (props.attachmentsDownloadError !== undefined) ? props.attachmentsDownloadError : false,
      maxSigners: 40,
      maxDocuments: 15,
      isCallApis: false,
      isFileType: false,
      isContacts: false,
      createError: false
    };

    this.fileInput = null;
    this.editorRef = null;
    this.headerFormRef = React.createRef();
    this.handleSetState = (patchedState) => this.setState(patchedState);
    this.handleSubmit = this.submit.bind(this);
    // Global events
    this.handleOnDrop = this.onDrop.bind(this);
    this.handleOnDragOver = this.onDragOver.bind(this);
    this.handleOnDragLeave = this.onDragLeave.bind(this);
    // Header Address Events
    this.handleAddAddress = this.addAddress.bind(this);
    this.handleRemoveAddress = this.removeAddress.bind(this);
    this.handleMoveAddress = this.moveAddress.bind(this);
    // Subject events
    this.handleOnSubjectChange = this.onSubjectChange.bind(this);
    // Editor events
    this.handleEditorChange = this.editorChange.bind(this);
    this.onAttachButton = this.onAttachButton.bind(this);
    this.onAttachSelected = this.onAttachSelected.bind(this);
    this.callApis = this.callApis.bind(this);
    this.combineInfo = this.combineInfo.bind(this);
    this.getDocumentsNamesAndIds = this.getDocumentsNamesAndIds.bind(this);
    this.getDocumentsIds = this.getDocumentsIds.bind(this);
    this.getDocumentsNames = this.getDocumentsNames.bind(this);
    this.buildDocumentsInfo = this.buildDocumentsInfo.bind(this);

    this.onChangeCertification = this.onChangeCertification.bind(this);

    this.dialogClose = this.dialogClose;
    this.dialogOpen = this.dialogOpen;
    this.animationSettings = { effect: 'None' };
    this.showCancelCenModal = this.showCancelCenModal.bind(this);
   
    this.resetIsFileDrop = this.resetIsFileDrop.bind(this);
  }

  onChangeCertification(certificates) {
    let selectedOptions = [];
    let max = 0;
    certificates.forEach(certificate => {
      if (certificate.checked){
        selectedOptions.push({option: certificate.option, certificate: certificate.id});
        (max <= certificate.option) ? max = certificate.option : null;
      }
    });
    this.setState({
      selectedCertificationOption: max,
      certificationType: selectedOptions.length > 0 ? selectedOptions[max-1].certificate : 'delivery'
    });
  }


  showCancelCenModal(){
    this.setState({ hideConfirmDialog: true});
  }


  resetIsFileDrop(){
    this.setState({isFileType: false});
  }

  resetReceivedInfo(){
    this.props.setMailContacts(null);
    this.props.setAdminContacts(null);
    this.props.setUserApp('lefebvre');
    this.props.setGuid(null);
    this.props.setTitle('');
    this.props.setIdDocuments(null);
  }


  dialogClose(){
    var reload = this.state.createError;
    if (this.state.centinelaDownloadError === true || this.state.lexonDownloadError === true || this.state.customerareaDownloadError === true){
      this.props.onShowError();
    } 
    this.setState({
        hideAlertDialog: false, 
        bigAttachments: false, 
        centinelaDownloadError: false,
        lexonDownloadError: false,
        customerareaDownloadError: false,
        hideConfirmDialog: false,
        createError: false,
        creditsDialog: false
    });
    if (reload){
      location.reload();
    }
  }

  dialogOpen(){
    (this.alertDialogInstance && this.alertDialogInstance.cssClass) ? this.alertDialogInstance.cssClass = 'e-fixed' : null;    
    (this.noSignaturesDialog && this.noSignaturesDialog.cssClass) ? this.noSignaturesDialog.cssClass = 'e-fixed' : null;
  }

  onDiscardSignatureOk(){
    const {close, lefebvre, application} = this.props
    if (lefebvre.userApp === 'centinela'){
      cancelSignatureCen(lefebvre.guid)
      .then(res => {
       null
      })
      .catch(err => {
        console.log(err);
      })
    } else if (lefebvre.userApp === 'lexon'){
      cancelSignatureLex(lefebvre.guid, lefebvre.companyId, lefebvre.idUserApp, this.props.credentials.encrypted, lefebvre.env)
      .then(res => {
       null
      })
      .catch(err => {
        console.log(err);
      })
    } else if (lefebvre.userApp === 'customerarea'){
      cancelSignatureCus(lefebvre.guid, lefebvre.token)
      .then(res => {
       null
      })
      .catch(err => {
        console.log(err);
      })
    }

    this.setState({ hideConfirmDialog: false });
    this.resetReceivedInfo();
    close(application);
}

  componentDidMount() {
    if (this.fileInput) {
      this.fileInput.onchange = this.onAttachSelected;
    }
    
    this.setState({isContacts: this.props.lefebvre.roles.some(e => e === 'Centinela' || e === "Lex-On" || e === "Portal de Clientes")});
    //createSignature();
  }

  removeMessageEditor(aplication) {
    const { close, lefebvre } = this.props;

    if (lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === 'centinelaReport' || lefebvre.userApp === "2" || lefebvre.userApp === "4" || lefebvre.userApp === "lexon" || lefebvre.userApp === "1" || lefebvre.userApp === "customerarea"|| lefebvre.userApp === "9"){
      this.setState({hideConfirmDialog: true});
    } else {
      this.resetReceivedInfo();
      close(aplication);
    }
  }

  getInfoDialogContent(){
    const {lefebvre} = this.props;
    const mustHaveAttachments = (this.state.certificationType === 'open_document' || this.state.certificationType === 'open_every_document' || this.state.certificationType === 'download_document' || this.state.certificationType === 'download_every_document');
    var base = `
      <span class="lf-icon-information modal-icon-content"></span>
      <div class="modal-text-content">
       {{text}}
      </div>`;
    
    var text = '';

    if (this.state.centinelaDownloadError === true && lefebvre.userApp === 'centinela'){
      text =  i18n.t('attachNotFoundCentinela.text');
    } else if (this.state.lexonDownloadError === true && lefebvre.userApp === 'lexon'){
      text =  i18n.t('attachNotFoundLexon.text');
    } else if (this.state.customerareaDownloadError === true && lefebvre.userApp === 'customerarea'){
      text =  i18n.t('attachNotFoundCustomerarea.text');
    } else if (this.props.to && this.props.to.length === 0){
      text = i18n.t('noSignersModal.text');
    } else if (this.state.createError === true){
      text = i18n.t('genericErrorModal.text');
    } else if (
      ((lefebvre.userApp === 'centinela' || lefebvre.userApp === 'lexon' || lefebvre.userApp === 'customerarea') && this.props.attachments.length === 0)
      || 
      (mustHaveAttachments && this.props.attachments.length === 0)
    ){
      text = i18n.t('noAttachmentsModal.text');
    } else if (this.state.bigAttachments){
      text = i18n.t('bigFileModal.text');
    } else if ((this.state.certificationType === 'open_document' || this.state.certificationType === 'open_every_document' || this.state.certificationType === 'download_document' || this.state.certificationType === 'download_every_document')
      && this.props.application.newMessage.attachments.some(a => a.contentType.toUpperCase() !== 'APPLICATION/PDF')){
      text = i18n.t('onlyPdfModal.text');
    } else if (this.props.attachments.length > 15){
      text = i18n.t('maxAttachmentsModal.text');
    } else if (this.props.to.length > 40){
      text = i18n.t('maxRecipientsModal.text')
    } else {
      text = i18n.t('genericErrorModal.text');
    }
    return base.replace('{{text}}', text);
  }

  getConfirmDiscardContent(){
    if (this.props.lefebvre.userApp === 'centinela'){
      return `
        <span class="lf-icon-question modal-icon-content"></span>
        <div class="modal-text-content">
          ${i18n.t('cancelCentinelaConfirmation.text')}
        </div>
      `;
    } else if (this.props.lefebvre.userApp === 'lexon'){
      return `
        <span class="lf-icon-question modal-icon-content"></span>
        <div class="modal-text-content">
          ${i18n.t('cancelLexonConfirmation.text')}
        </div>
      `;
    } else if (this.props.lefebvre.userApp === 'customerarea'){
      return `
        <span class="lf-icon-question modal-icon-content"></span>
        <div class="modal-text-content">
          ${i18n.t('cancelCustomerareaConfirmation.text')}
        </div>
      `;
    }
  }

  getNoSignaturesContent(){
    return `
      <img border='0' src='assets/images/icon-warning.png'></img>
      <div class="modal-text-align-content">
        ${i18n.t('noCreditsModal.text')}
        ${i18n.t('noCreditsModal.text2')}
      </div>
    `;
  }

  render() {
    console.log('Debug: render()');
    console.log(this.state.hideAlertDialog);
    console.log(this.state.centinelaDownloadError);

    // const noSignersModal = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('noSignersModal.text')}
    //   </div>`;

    // const noAttachModal = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('noAttachmentsModal.text')}
    //   </div>`;

    // const bigFileModal = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('bigFileModal.text')}
    //   </div>
    // `;

    // const attachNotFound = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('attachNotFoundCentinela.text')}
    //   </div>
    // `;

    // const confirmDiscard = `
    //   <span class="lf-icon-question modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('cancelCentinelaConfirmation.text')}
    //   </div>
    // `;

    // const onlyPdfModal = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('onlyPdfModal.text')}
    //   </div>
    // `;

    // const errCreatingRequest = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('genericErrorModal.text')}
    //   </div>
    // `;

    // const maxRecipients = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('maxRecipientsModal.text')}
    //   </div>
    // `;

    // const maxDocuments = `
    //   <span class="lf-icon-information modal-icon-content"></span>
    //   <div class="modal-text-content">
    //     ${i18n.t('maxAttachmentsModal.text')}
    //   </div>
    // `;

    // const noSignaturesContent = `
    //   <img border='0' src='assets/images/icon-warning.png'></img>
    //   <div class="modal-text-align-content">
    //     ${i18n.t('noCreditsModal.text')}
    //     ${i18n.t('noCreditsModal.text2')}
    //   </div>
    // `;

    // const onlyPdf = ( 
    //   (this.state.certificationType === 'open_document' || this.state.certificationType === 'open_every_document' || this.state.certificationType === 'download_document' || this.state.certificationType === 'download_every_document')
    //   && this.props.application.newMessage.attachments.some(a => a.contentType.toUpperCase() !== 'APPLICATION/PDF')
    // )

    const infoDialogContent = this.getInfoDialogContent();
    const confirmDiscard = this.getConfirmDiscardContent();
    const noSignaturesContent = this.getNoSignaturesContent();

    const confirmButtons = [
      {
          click: () => {
          this.setState({ hideConfirmDialog: false });
          },
          buttonModel: {  content: i18n.t('confirmationModal.no'), cssClass: 'btn-modal-close' }
      },
      {
          click: () => {
              this.setState({ hideConfirmDialog: false });
              this.onDiscardSignatureOk();
          },
          buttonModel: { content: i18n.t('confirmationModal.yes'), isPrimary: true }
      }
    ];

    const {
      t,
      className,
      application,
      sendingType,
      to,
      cc,
      bcc,
      attachments,
      subject,
      content,
      lefebvre
    } = this.props;
 
    return (
      <div
        className={`${className} ${styles['message-editor']}`}
        onDrop={this.handleOnDrop}
        onDragOver={this.handleOnDragOver}
        onDragLeave={this.handleOnDragLeave}>
         {this.state.isCallApis ? 
          <div className={styles['spinner-container']}> 
           <div className={styles['spinner']}>
            <Spinner /> 
           </div>
          </div> : ''}
        {this.state.dropZoneActive ? (
          <div className={styles.dropZone}>
            <div className={styles.dropZoneMessage}>
              <i className={'material-icons'}>attach_file</i>
              {t('messageEditor.dropZoneMessage')}
            </div>
          </div>
        ) : null}
        <div className={styles.header}>
          <form ref={this.headerFormRef}>
            <HeaderAddress
              id={'to'}
              addresses={to}
              onAddressAdd={this.handleAddAddress}
              onAddressRemove={this.handleRemoveAddress}
              onAddressMove={this.handleMoveAddress}
              className={styles.address}
              chipClassName={styles.chip}
              autoSuggestClassName={styles.autoSuggest}
              autoSuggestMenuClassName={styles.autoSuggestMenu}
              getAddresses={this.props.getAddresses}
              label={i18n.t('messageEditor.to')}
              lefebvre={lefebvre}
              isContacts={this.state.isContacts}
              sendingType={sendingType}
            />
            {/* <HeaderAddress
              id={'cc'}
              addresses={cc}
              onAddressAdd={this.handleAddAddress}
              onAddressRemove={this.handleRemoveAddress}
              onAddressMove={this.handleMoveAddress}
              className={styles.address}
              chipClassName={styles.chip}
              autoSuggestClassName={styles.autoSuggest}
              autoSuggestMenuClassName={styles.autoSuggestMenu}
              getAddresses={this.props.getAddresses}
              label={t('messageEditor.cc')}
              lefebvre={lefebvre}
            /> */}
            <div className={styles.subject}>
              <input
                type={'text'}
                placeholder={t('messageEditor.subject')}
                value={subject}
                onChange={this.handleOnSubjectChange}
              />
            </div>
          </form>
        </div>
        <div
          className={styles['editor-wrapper']}
          onClick={() => this.editorWrapperClick()}>
          <div className={styles['editor-container']}>
            <ComposeMessageEditor
              ref={(ref) => (this.editorRef = ref)}
              onChange={this.handleEditorChange}
              defaultValue={content}
            />
          </div>
          <div className={styles['side-container']}>
            <AttachmentsWidget 
              sendingType={sendingType}
              onConfirmAttachRemoval={this.showCancelCenModal}
              isFileTypeDrop={this.state.isFileType}
              resetIsFileDrop={this.resetIsFileDrop}
              fatherContainer={'EmailMessageEditor'}
            ></AttachmentsWidget>
            <CertificatesWidget 
              userApp={lefebvre.userApp}
              onChange={this.onChangeCertification}
              sendingType={sendingType}
            />
          </div>
          <div className={styles['action-buttons']}>
            <button
              className={`${mainCss['mdc-button']} ${mainCss['mdc-button--unelevated']} ${styles['action-button']} ${styles.cancel}`}
              onClick={() => this.removeMessageEditor(application)}>
              {t('messageEditor.discard')}
            </button>
            <button
              className={`${mainCss['mdc-button']} ${mainCss['mdc-button--unelevated']} ${styles['action-button']} ${styles.send}`}
              //disabled={this.props.attachments.length === 0}
              onClick={this.handleSubmit}>
              {t('messageEditor.send')}
            </button>
                        
          </div>
        </div>

        <DialogComponent 
          id="info2Dialog" 
          //header=' ' 
          visible={this.state.hideAlertDialog || this.state.centinelaDownloadError || this.state.lexonDownloadError || this.state.customerareaDownloadError} 
          animationSettings={this.animationSettings} 
          width='60%' 
          content={infoDialogContent}
          ref={alertdialog => this.alertDialogInstance = alertdialog} 
          open={this.dialogOpen.bind(this)} 
          close={this.dialogClose.bind(this)}
          showCloseIcon={true}
        />
        <DialogComponent 
          id="confirmDialog" 
          header=' ' 
          visible={this.state.hideConfirmDialog} 
          showCloseIcon={true} 
          animationSettings={this.animationSettings} 
          width='60%' 
          content={confirmDiscard} 
          ref={dialog => this.confirmDialogInstance = dialog} 
          buttons={confirmButtons} 
          open={() => this.dialogOpen} 
          close={() => this.dialogClose}
        />
        <DialogComponent 
          id='noSignaturesDialog'
          header='' 
          visible={this.state.creditsDialog} 
          animationSettings={this.animationSettings} 
          width='50%' 
          showCloseIcon={true} 
          content={noSignaturesContent}//'Lo sentimos has agotado el número máximo de firmas contratadas. Si lo deseas, puedes contactar con nuestro departamento de atención a cliente en el teléfono 911231231 o pinchando aquí' 
          ref={alertdialog => this.noSignaturesDialog = alertdialog} 
          open={this.dialogOpen} 
          close={this.dialogClose}
        />        
      </div>
    );
  }

  bigAttachments(){
    let maxSize = 15;
    let totalSize = 0
    this.props.attachments.map(attachment => totalSize = totalSize + (((3 * (attachment.content.length / 4)) - ((attachment.content.match(/==/g) || []).length) - ((attachment.content.match(/=/g) || []).length)) / 1000000));
    return (totalSize >= maxSize);
  }

  
  submit() {
    console.log('Debug: submit()');
    console.log(this.props.to.length);
    console.log(this.props.attachments.length);
    console.log(this.state.certificationType);

    if (this.props.to.length === 0 ){
      console.log('Debug: true 1');
      this.setState({ hideAlertDialog: true });
    } else if ( this.props.attachments.length === 0 
      && (this.state.certificationType === 'open_document' || this.state.certificationType === 'open_every_document' || this.state.certificationType === 'download_document' || this.state.certificationType === 'download_every_document')){
        console.log('Debug: true 2');
        this.setState({hideAlertDialog: true})
    } 
    else if ( (this.state.certificationType === 'open_document' || this.state.certificationType === 'open_every_document' || this.state.certificationType === 'download_document' || this.state.certificationType === 'download_every_document')
      && this.props.application.newMessage.attachments.some(a => a.contentType.toUpperCase() !== 'APPLICATION/PDF')){
        console.log('Debug: true 3');
        this.setState({hideAlertDialog: true})
    }
    else if (this.bigAttachments()){
      console.log('Debug: true 4');
      this.setState({ hideAlertDialog: true, bigAttachments: true});
    } else if (this.props.attachments.length > this.state.maxDocuments){
      console.log('Debug: true 5');
      this.setState({ hideAlertDialog: true});
    } else if (this.props.to && this.props.to.length > this.state.maxSigners){
      console.log('Debug: true 6');
      this.setState({ hideAlertDialog: true});
    }
    else {
      if (this.headerFormRef.current.reportValidity()) {
        // Get content directly from editor, state content may not contain latest changes
        const content = this.getEditor().getContent();
        const { to, cc, subject } = this.props;
        const { lefebvre } = this.props;
        const userBranding = (lefebvre && lefebvre.userBrandings && lefebvre.userBrandings.certifiedEmail) 
          ? lefebvre.userBrandings.certifiedEmail.find((b) => b.app === lefebvre.userApp) 
          : '';
          
        let guid = lefebvre.guid;
        if (guid === null) {
          guid = uuid();
        }
  
        if (this.props.attachments) {
          let attachmentsList = [];
          this.props.attachments.forEach((attachment) => {
            //var attachment = this.props.attachments[0];
            var file = new File([attachment.content], attachment.fileName, {
              type: getFileType(attachment.fileName),
              lastModified: new Date(),
            });
            attachmentsList.push({file: file, pages: attachment.pages});
          });
          this.callApis(
            to,
            cc,
            subject,
            content.innerHTML,
            this.props.attachments,
            lefebvre.userId,
            guid,
            this.state.certificationType,
            userBranding
          );
        }
        //createSignature(to, subject, content.innerHTML, document.getElementById('file-input').files[0], reminders, expiration, lefebvre.userId, guid);
      }
    }
  }

  example() {
    var lookup = {};
    var items = json.DATA;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var name = item.name;

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push(name);
      }
    }
  }

  getDocumentsNames(signature) {
    var lookup = {};
    var items = signature.documents;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var name = item.file.name;
      var id = item.id;

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push(name);
      }
    }
    return result;
  }

  getDocumentsIds(signature) {
    var lookup = {};
    var items = signature.documents;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var id = item.id;

      if (!(id in lookup)) {
        lookup[id] = 1;
        result.push(id);
      }
    }
    return result;
  }

  getDocumentsNamesAndIds(signature) {
    var lookup = {};
    var items = signature.documents;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var name = item.file.name;
      var id = item.id;
      var info = { name: name, id: id };

      if (!(info in lookup)) {
        lookup[info] = 1;
        result.push(info);
      }
    }
    return result;
  }

  combineInfo(externalIds, internalIds) {
    let merged = [];

    for (let i = 0; i < externalIds.length; i++) {
      merged.push({
        ...externalIds[i],
        ...internalIds.find(
          (itmInner) => itmInner.name === externalIds[i].name
        ),
      });
    }
    return merged;
  }

  buildDocumentsInfo(email) {
    let result;
    result = (email && email.certificates) 
      ? email.certificates.map((c) => {
          return {
            email: c.email,
            name: c.name,
            externalId: c.id,
            document: (c.file)
              ? {
                externalFileName: c.file.name,
                internalInfo: ( this.props.lefebvre && this.props.lefebvre.idDocuments ) 
                  ? this.props.lefebvre.idDocuments.find((d) =>{
                    if (d.docName.replace(/[)( ]/g, '_') === c.file.name) {
                      return {
                        docId: d.docId,
                        docName: d.docName 
                      }
                    }
                  })
                  : null
              }
              : null
          }
        })
      : null

    return result;
  }

  callApis(
    recipients,
    cc,
    subject,
    content,
    files,
    userId,
    guid,
    type,
    userBrandingId
  ) {
    const { lefebvre } = this.props;
    if (userBrandingId === null || userBrandingId === undefined || userBrandingId === 'null' || userBrandingId === ''){
      userBrandingId = window.DEFAULT_BRANDING_ES;
    }

    getAvailableSignatures(lefebvre.idClienteLef, 1)
      .then(response => {
        if ((response.data === false || response.data === "false") && lefebvre.userId !== "E1621396"){
          this.setState({creditsDialog:true})
        } else {
          if ((response.data === false || response.data === "false")){
            this.setState({creditsDialog:true})
          }
          this.setState({isCallApis: true});
          createEmail(
            this.props.country,
            recipients,
            cc,
            subject,
            content,
            files,
            userId,
            guid,
            type,
            userBrandingId.externalId,
            this.props.credentials.encrypted
          )
          .then((emailInfo) => {
            if (emailInfo.status_code) {
            } else {
              getUserEmails(userId, this.props.credentials.encrypted).then((userInfo) => {
                var documentsInfo = this.buildDocumentsInfo(emailInfo);
                const lexAccess = (lefebvre.userApp === 'lexon' ? {idCompany: lefebvre.companyId, idUser: lefebvre.idUserApp, env: lefebvre.env} : null);
                addOrUpdateEmail(
                  userId,
                  emailInfo.id,
                  guid,
                  lefebvre.userApp,
                  emailInfo.created_at,
                  type,
                  lexAccess,
                  documentsInfo,
                  this.props.credentials.encrypted
                );
      
                let idUserApp = lefebvre.idUserApp;
                let numDocs = documentsInfo.length;
                
                this.props.setMailContacts(null);
                this.props.setAdminContacts(null);
                this.props.setUserApp('lefebvre');
                this.props.setGuid(null);
                //this.props.setTitle('');
                this.props.setIdDocuments(null);
                this.props.close(this.props.application);
                this.props.preloadEmails(this.props.application.country, lefebvre.userId, this.props.application.user.credentials.encrypted);
                this.props.setTitle(i18n.t('topBar.certifiedEmail'));
                this.props.setSelectedService('certifiedEmail'); 
                this.props.setSignaturesFilterKey('Mostrar todas');
                
                notifySignature(
                  lefebvre.userId,
                  idUserApp,
                  1, //numDocs
                  2  //Email
                );
                
                getNumAvailableSignatures(lefebvre.idClienteLef)
                  .then( res => this.props.setNumAvailableSignatures(parseInt(res.data)))
                  .catch(err => {
                      console.log(err);
                  });
                
                if (lefebvre && (lefebvre.userApp === 'centinela' || lefebvre.userApp === 'centinelaReport') && lefebvre.idDocuments){
                  lefebvre.idDocuments.forEach(document => {
                    notifyCen('certifiedEmail', lefebvre.guid, document.docId, recipients, lefebvre.userApp)
                    .catch(err => console.log(err));
                  });
                } else if (lefebvre && (lefebvre.userApp === 'lexon') && lefebvre.idDocuments) {
                  lefebvre.idDocuments.forEach(document => {
                    notifyLex(lefebvre.guid, lefebvre.companyId, lefebvre.idUserApp, recipients.map(r => r.address), null, lefebvre.env)
                  });
                } else if (lefebvre && (lefebvre.userApp === 'customerarea') && lefebvre.idDocuments) {
                  lefebvre.idDocuments.forEach(document => {
                    notifyCus( lefebvre.guid, lefebvre.token)
                  });
                } 
                }
              );

              

              this.openEmail(userId, guid);
            }
            
          })
          .catch(() => {
            this.setState({createError: true, hideAlertDialog: true});
          });          
        }
      })
      .catch(err => {
        console.log(err);
        if (window.REACT_APP_ENVIRONMENT === 'PREPRODUCTION' || window.REACT_APP_ENVIRONMENT === 'LOCAL'){ 
            this.props.setAvailableSignatures(true); // Esto se pone mientras el equipo encargado del api lo arregla
        }
      })
  }

  validateAddress(updatedMessage, id, address, name) {
    const addressData = {address: address, name: name}
    if(updatedMessage.to.length == this.state.maxSigners
       && id != 'cc') {
    } else if(updatedMessage.to.length == this.state.maxSigners 
      && id == 'cc') {
      updatedMessage[id] = [...updatedMessage[id], addressData];
      this.props.editMessage(updatedMessage);
    } else {
      updatedMessage[id] = [...updatedMessage[id], addressData];
      this.props.editMessage(updatedMessage);
    }
  }

  /**
   * Adds an address to the list matching the id.
   *
   * @param id
   * @param address
   */
  addAddress(id, address, name) {
    if (address.length > 0) {
      const updatedMessage = { ...this.props.editedMessage };
      const recipientRepeats = updatedMessage.to.some(data => {
      return (data.address === address);    
     });
   
     if(!recipientRepeats){
      this.validateAddress(updatedMessage, id, address, name);
     }
      
    }
  }

  /**
   * Removes the address from the under the field matching the id.
   *
   * @param id
   * @param address
   */
  removeAddress(id, address) {
    const updatedMessage = { ...this.props.editedMessage };
    updatedMessage[id] = [...updatedMessage[id]];
    updatedMessage[id].splice(updatedMessage[id].indexOf(address), 1);
    this.props.editMessage(updatedMessage);
  }

  /**
   * Moves an address from the address list under the field matching the fromId to the address field
   * matching the toId.
   *
   * @param fromId
   * @param toId
   * @param address
   */
  moveAddress(fromId, toId, address, name) {
    const updatedMessage = { ...this.props.editedMessage };
    const addressData = {address: address, name: name}
    // Remove
    updatedMessage[fromId].splice(updatedMessage[fromId].indexOf(address), 1);
    // Add
    updatedMessage[toId] = [...updatedMessage[toId], addressData];
    this.props.editMessage(updatedMessage);
  }

  onSubjectChange(event) {
    const target = event.target;
    const updatedMessage = { ...this.props.editedMessage };
    this.props.editMessage({ ...updatedMessage, subject: target.value });
  }

  onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dropZoneActive: false })
    
    const addAttachment = (file, dataUrl) => {
       const fileType = file.name.split('.');
        if(fileType[1].toLowerCase() == 'pdf' || fileType[1].toLowerCase() == 'docx' 
        || fileType[1].toLowerCase() == 'doc') {

          const newAttachment = {
            fileName: file.name,
            size: file.size,
            contentType: file.type,
            content: dataUrl.currentTarget.result.replace(
                /^data:[^;]*;base64,/,
                ''
            ),
            };

          if (fileType[1].toLowerCase() === 'pdf'){
            const pdfjsLib = require('pdfjs-dist');
            pdfjsLib.GlobalWorkerOptions.workerSrc = '../../../../assets/scripts/pdf.worker.js'

            pdfjsLib.getDocument({data: atob(newAttachment.content)})
            .promise.then(doc => {
              var numPages = doc.numPages;
              newAttachment.pages = numPages;
          
              const updatedMessage = { ...this.props.editedMessage };
              updatedMessage.attachments = updatedMessage.attachments
                  ? [...updatedMessage.attachments, newAttachment]
                  : [newAttachment];
              this.props.editMessage(updatedMessage);
            });
          } else {
            const updatedMessage = { ...this.props.editedMessage };

            updatedMessage.attachments = updatedMessage.attachments
                ? [...updatedMessage.attachments, newAttachment]
                : [newAttachment];
            this.props.editMessage(updatedMessage);
          }
        } else {
            this.setState({isFileType: true});
        }
       
    };
    if (this.props.editedMessage.attachments.length === 0){
      // let file = event.dataTransfer.files[event.dataTransfer.files.length-1];
      Array.from(event.dataTransfer.files).forEach((file) => {
        const fileReader = new FileReader();
        fileReader.onload = addAttachment.bind(this, file);
        fileReader.readAsDataURL(file);
        this.setState({isFileType: false});
      });
    }
    
    return true;
  }

  onDragOver(event) {
    event.preventDefault();
    if (
      event.dataTransfer.types &&
      Array.from(event.dataTransfer.types).includes('Files')
    ) {
      this.setState({ dropZoneActive: true });
    }
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setState({ dropZoneActive: false });
  }

  removeAttachment(attachment) {
    const updatedMessage = { ...this.props.editedMessage };
    if (updatedMessage.attachments && updatedMessage.attachments.length) {
      updatedMessage.attachments = updatedMessage.attachments.filter(
        (a) => a !== attachment
      );
      this.props.editMessage(updatedMessage);
    }
  }

  onAttachButton() {
    return this.fileInput && this.fileInput.click();
  }

  onAttachSelected(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ dropZoneActive: false });
    const addAttachment = (file, dataUrl) => {
      const newAttachment = {
        fileName: file.name,
        size: file.size,
        contentType: file.type,
        content: dataUrl.currentTarget.result.replace(
          /^data:[^;]*;base64,/,
          ''
        ),
      };
      const updatedMessage = { ...this.props.editedMessage };
      updatedMessage.attachments = updatedMessage.attachments
        ? [...updatedMessage.attachments, newAttachment]
        : [newAttachment];
      this.props.editMessage(updatedMessage);
    };
    Array.from(event.target.files).forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = addAttachment.bind(this, file);
      fileReader.readAsDataURL(file);
    });
    return true;
  }

  openEmail(userId, guid){
    searchEmails('ES', userId, this.props.credentials.encrypted, 'lefebvre_guid', guid, 0)
        .then(email => {
          if (email.length !== 0) {
            this.props.signatureClicked(null);
            this.props.emailClicked(null);
            this.props.smsClicked(null);
            this.props.setUserApp('lefebvre');
            this.props.setTitle(i18n.t('topBar.certifiedEmail'));
            this.props.emailClicked(email[0]);
            this.props.setGuid(null);
          }
          this.setState({isCallApis: false});
        })
        .catch( err => {
          console.log(err);
          this.props.setUserApp('lefebvre');
          this.setState({isCallApis: false});
        })
    
    return false;
  }
  getEditor() {
    if (this.editorRef && this.editorRef.refEditor) {
      return this.editorRef.refEditor;
    }
    return null;
  }

  editorWrapperClick() {
    this.getEditor();
  }

  /**
   * Every change in the editor will trigger this method.
   *
   * For performance reasons, we'll only persist the editor content every EDITOR_PERSISTED_AFTER_CHARACTERS_ADDED
   *
   * @param content
   */
  editorChange(content) {
    this.props.editMessage({ ...this.props.editedMessage, content });
    persistApplicationNewMessageContent(this.props.application, content);
  }
}

EmailMessageEditor.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

EmailMessageEditor.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  application: state.application,
  credentials: getCredentials(state),
  editedMessage: state.application.newMessage,
  sendingType: state.application.newMessage.sendingType,
  to: state.application.newMessage.to,
  cc: state.application.newMessage.cc,
  bcc: state.application.newMessage.bcc,
  attachments: state.application.newMessage.attachments,
  subject: state.application.newMessage.subject,
  name: state.application.newMessage.name,
  editor: state.application.newMessage.editor,
  content: state.application.newMessage.content,
  getAddresses: (value) => getAddresses(value, state.messages.cache),
  lefebvre: state.lefebvre,
  country: state.application.country,
});

const mapDispatchToProps = (dispatch) => ({
  close: (application) => {
    dispatch(editMessage(null));
    // Clear content (editorBlur may be half way through -> force a message in the service worker to clear content after)
    // noinspection JSIgnoredPromiseFromCall
    persistApplicationNewMessageContent(application, '');
  },
  editMessage: (message) => {
    dispatch(editMessage(message));
  },
  // setCaseFile: casefile => dispatch(ACTIONS.setCaseFile(casefile)),
  setMailContacts: (mailContacts) =>
    dispatch(ACTIONS.setMailContacts(mailContacts)),
  setGuid: (guid) => dispatch(ACTIONS.setGUID(guid)),
  setAvailableSignatures: (num) =>
    dispatch(ACTIONS.setAvailableSignatures(num)),
  setTitle: title => dispatch(setTitle(title)),
  setUserApp: app => dispatch(ACTIONS.setUserApp(app)),
  setAdminContacts: contacts => dispatch(ACTIONS.setAdminContacts(contacts)),
  setIdDocuments: id => dispatch(ACTIONS.setIdDocuments(id)),
  preloadEmails: (client, userId, auth) => preloadEmails(dispatch, client, userId, auth),
  setSelectedService: selectService  => dispatch(setSelectedService(selectService)),
  setSignaturesFilterKey: key => dispatch(setSignaturesFilterKey(key)),
  smsClicked: sms => dispatch(selectSms(sms)),
  emailClicked: email => dispatch(selectEmail(email)),
  signatureClicked: signature => dispatch(selectSignature(signature))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(EmailMessageEditor));