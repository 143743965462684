import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import MessageFilters, { getFromKey } from '../../services/message-filters';
import mainCssES from '../../styles/main.scss';
import stylesES from './button-user.scss';
import mainCssFR from '../../styles/mainFR.scss';
import stylesFR from './button-userFR.scss';

import MenuUser from '../menu-user/menu-user';
import MenuUserFR from '../menu-user/menu-userFR';

export class ButtonFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      styles: null,
      mainCss: null,
    };
    switch (this.props.application.country) {
      case "FR":
        this.state.styles = stylesFR;
        this.state.mainCss = mainCssFR;
        break;
      case "ES":
        this.state.styles = stylesES;
        this.state.mainCss = mainCssES;
      default:
        break;
    }
    this.handleOnToggleDialog = this.onToggleDialog.bind(this);
    this.handleOnCloseDialog = this.onCloseDialog.bind(this);
    this.onToggleDialog = this.onToggleDialog.bind(this);
  }

  render() {
    const { t, activeMessageFilter, application } = this.props;
    const { dialogVisible, styles, mainCss } = this.state;
    const active = activeMessageFilter.key !== MessageFilters.ALL.key;

    return (
      <span
        className={`${styles['button-filter']} ${styles['icon-user-space']} ${mainCss['mdc-menu-surface--anchor']}`}
        isotip={t('mainBar.quickUser')}
        isotip-position='bottom-end'
        isotip-size='small'
        isotip-hidden={dialogVisible.toString()}>
          {
            application.country === 'FR'
            ? 
              <MenuUserFR
                fullName=''
                onToggleDialog={v => {
                  this.setState({ dialogVisible: v });
                }}
              />
            :
              <MenuUser
                fullName=''
                onToggleDialog={v => {
                  this.setState({ dialogVisible: v });
                }}
              />
          }
        
      </span>
    );
  }

  // componentDidMount() {
  //   window.addEventListener('click', this.handleOnCloseDialog);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('click', this.handleOnCloseDialog);
  // }

  onToggleDialog(visible) {
    this.setState({ dialogVisible: visible });
  }

  onCloseDialog() {
    this.setState({ dialogVisible: false });
  }
}

const mapStateToProps = state => ({
  activeMessageFilter: getFromKey(state.application.messageFilterKey),
  application: state.application
});

export default connect(mapStateToProps)(translate()(ButtonFilter));
