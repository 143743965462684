/* eslint-disable indent */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import history from '../routes/history';
import Spinner from './spinner/spinner';
import TopBar from './top-bar/top-bar';
import MainBar from './main-bar/main-bar';
import SideBar from './side-bar/side-barFR';
import MessageEditor from './message-editor/message-editorFR';
import MessageList from './message-list/message-listFR';
import MessageViewer from './message-viewer/message-viewerFR';
import Cookies from 'js-cookie';
import { removeState } from '../services/state';

import {
  clearUserCredentials,
  selectMessage,
  selectFolder,
  outboxEventNotified,
  editMessage,
  setError,
  selectSignature,
  setTitle,
  setAppTitle, 
  setSelectedService
} from '../actions/application';
import { clearSelected } from '../actions/messages';
import {
  setGUID,
  setSign,
  setUserApp
} from '../actions/lefebvre';

import { editNewMessage, clearSelectedMessage } from '../services/application';
import styles from './appFR.scss';
import { translate } from 'react-i18next';
import Sidebar from 'react-sidebar';
import { preloadSignatures2} from "../services/api-signaturit";
import { backendRequest, backendRequestCompleted } from '../actions/messages';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      sidebarDocked: false,
      sideBar: {
        collapsed: false
      },
      sidebarComponent: (
        <img
          border='0'
          alt='Lefebvre'
          src='/assets/images/lexon-fake.png'></img>
      ),
      actualSidebarComponent: 0,
      isUpdatedDefaultAccount: (this.props.application.user) ? true: false,
      hideAlertDialog: false,
    };

    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  render() {
    const { t, lefebvre } = this.props;
    const { sideBar, isUpdatedDefaultAccount } = this.state;

    if (!isUpdatedDefaultAccount) {
      return null;
    }

    return (
      <Sidebar
        sidebar={this.state.sidebarComponent}
        open={false}
        pullRight={true}
        docked={this.state.sidebarDocked}
        styles={{
          sidebar: {
            background: 'white',
            zIndex: 999,
            overflowY: 'hidden',
            WebkitTransition: '-webkit-transform 0s',
            willChange: 'transform'
          },
          content: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: 'hidden',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
            transition: 'left .0s ease-out, right .0s ease-out'
          },
          overlay: {
            zIndex: 1,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            visibility: 'hidden',
            //transition: "opacity .3s ease-out, visibility .0s ease-out",
            backgroundColor: 'rgba(0,0,0,.3)'
          },
          dragHandle: {
            zIndex: 1,
            position: 'fixed',
            top: 0,
            bottom: 0
          }
        }}>
        <div>
          <MainBar
            sideBarCollapsed={sideBar.collapsed}
            sideBarToggle={this.toggleSideBar}
          />
          <TopBar
            sideBarCollapsed={sideBar.collapsed}
            sideBarToggle={this.toggleSideBar}
          />
          <div id='mainnav-app' />
          {/*<SplitPane split="vertical" minSize={200} maxSize={800} defaultSize={450}  primary="second">*/}
          <div className={styles.app}>
            <Spinner
              visible={this.props.application.activeRequests > 0}
              className={styles.spinner}
              pathClassName={styles.spinnerPath}
            />

            <SideBar
              collapsed={sideBar.collapsed}
              sideBarToggle={this.toggleSideBar}
              casefile={lefebvre.idCaseFile}
              bbdd={lefebvre.bbdd}
            />
            <div id='mainnav-app' />

            <div
              className={`${styles['content-wrapper']}
                                ${
                                  sideBar.collapsed
                                    ? styles['without-side-bar']
                                    : styles['with-side-bar']
                                } ${styles['custom-padding-top']}`}>
              {this.renderContent()}
            </div>

            <div className={styles.productpanel}>
            </div>
          </div>
        </div>
      </Sidebar>
    );
  }

  renderContent() {
    const { application } = this.props;   
    if (
      application.newMessage &&
      Object.keys(application.newMessage).length > 0
      && application.newMessage.sendingType == 'signature'
    ) {
      return <MessageEditor className={styles['message-viewer']} />;
    } else if (application.selectedSignature && Object.keys(application.selectedSignature).length > 0) {
      return <MessageViewer className={styles['message-viewer']} />;
    }
    
    return (
      <Fragment>
        <MessageList className={styles['message-grid']} />
      </Fragment>
    );
  }

  openSignature(signature){
    this.props.signatureClicked(signature);
    this.props.setGUID(null);
    return false;
  }


  async componentDidMount() {
    document.title = 'Lefebvre Dalloz - Signature';
    this.refreshPollTimeout = setInterval(this.startPoll.bind(this), window.POLLING_INTERVAL);
    const { lefebvre } = this.props;
    this.props.setSelectedService('signature');
    this.props.setAppTitle(i18n.t('topBar.app'));
    this.props.preloadSignatures(lefebvre.userId);
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  componentWillUnmount() {
    clearInterval(this.refreshPollTimeout);
  }

  routeLogout() {
    const { userId, token } = this.props.lefebvre;
    Cookies.remove(`Lefebvre.Signaturit.${userId}`, { path: '/', domain: (window.REACT_APP_ENVIRONMENT==='LOCAL' ? 'localhost': '.lefebvre.es')});
    removeState();
    this.props.logout();
    this.props.history.push(`/expired`)
    // window.location.reload();
  }

  startPoll() {
      const {lefebvre, application} = this.props;
      if ((application.selectedSignature === null || (application.selectedSignature.constructor === Object && Object.entries(application.selectedSignature).length === 0)))  {
        this.props.preloadSignatures(lefebvre.userId)
        .then(res => null)
        .catch(err => {
          if (err && err === 'Access token expired'){
            this.routeLogout();
            this.props.history.push(`/expired-fr`);
          }
          else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')){
            this.routeLogout();
            this.props.history.push('/unauthorized-fr');
          }
          
        });
      } else {
        this.props.preloadSignatures(lefebvre.userId)
        .then(res => null)
        let signature = application.signatures.find(s => s.id === application.selectedSignature.id)
        this.props.signatureClicked(signature);
      }  
  }

  toggleSideBar() {
    const toggleCollapsed = !this.state.sideBar.collapsed;
    this.setState({
      sideBar: {
        collapsed: toggleCollapsed
      }
    });
  }
}

App.propTypes = {
  application: PropTypes.object,
  outbox: PropTypes.object,
  folders: PropTypes.object,
  loadMessageByFolder: PropTypes.func,
  newMessage: PropTypes.func.isRequired 
};

const mapStateToProps = state => ({
  application: state.application,
  auth: state.application.user.credentials.encrypted,
  outbox: state.application.outbox,
  folders: state.folders,
  messages: state.messages,
  lefebvre: state.lefebvre,
  email: state.login.formValues.user,
  all: state
});

const mapDispatchToProps = dispatch => ({
  newMessage: (sendingType, to, cc, sign, attachments) => editNewMessage(dispatch, sendingType, to, cc, sign, attachments),
  selectFolder: (folder, user) => {
    dispatch(selectFolder(folder));
    clearSelectedMessage(dispatch);
    dispatch(clearSelected());
  },
  messageClicked: (credentials, downloadedMessages, folder, message) => {
    dispatch(selectMessage(message))
  },
  outboxEventNotified: () => dispatch(outboxEventNotified()),
  logout: () => {
    dispatch(clearUserCredentials());
    history.push('/login');
  },
  close: application => {
    dispatch(editMessage(null));
  },
  setError: (err, msg) => dispatch(setError(err, msg)),
  setGUID: guid => dispatch(setGUID(guid)),
  setSign: sign => dispatch(setSign(sign)),
  setUserApp: app => dispatch(setUserApp(app)),
  preloadSignatures: (client, userId, auth) => preloadSignatures2(dispatch, client, userId, auth),
  signatureClicked: signature => dispatch(selectSignature(signature)),
  backendRequest: () => dispatch(backendRequest()),
  backendRequestCompleted: () => dispatch(backendRequestCompleted()),
  setSelectedService: service => dispatch(setSelectedService(service)),
  setAppTitle: title => dispatch(setAppTitle(title)),
  setTitle: title => dispatch(setTitle(title)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    selectFolder: folder =>
      dispatchProps.selectFolder(folder, stateProps.application.user),
    messageClicked: message =>
      dispatchProps.messageClicked(
        stateProps.application.user.credentials,
        stateProps.application.downloadedMessages,
        stateProps.receivedFolder,
        message
      ),
    // setEmailShown: flag => dispatchProps.setEmailShown(flag),
    outboxEventNotified: () => dispatchProps.outboxEventNotified(),
    close: application => dispatchProps.close(stateProps.application),
    setError: (err, msg) => dispatchProps.setError(err, msg),
    // resetIdEmail: () => dispatchProps.resetIdEmail(),
    // setCaseFile: casefile => dispatchProps.setCaseFile(casefile),
    preloadSignatures: (userId) => dispatchProps.preloadSignatures(stateProps.application.country, userId, stateProps.application.user.credentials.encrypted),
    signatureClicked: signature => dispatchProps.signatureClicked(signature),
    backendRequest: () => dispatchProps.backendRequest(),
    backendRequestCompleted: () => dispatchProps.backendRequestCompleted(),
    setSelectedService: service => dispatchProps.setSelectedService(service),
    setAppTitle: title => dispatchProps.setAppTitle(title),
    setTitle: title => dispatchProps.setTitle(title),
  });


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate()(App));
