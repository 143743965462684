import React, {Component} from 'react';
import { connect } from 'react-redux';
import stylesES from './widgets.scss';
import mainCssES from '../../../styles/main.scss';
import stylesFR from './widgetsFR.scss';
import mainCssFR from '../../../styles/mainFR.scss';
import { editMessage } from '../../../actions/application';
import i18n from 'i18next';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

class AttachmentsWidget extends Component{
    constructor(props){
        super(props);
        this.state = {
            warningDialog: false,
            optionSelected: 1,
            isFileType: false,
            isFileTypeDrop: false,
            styles: null,
            mainCss: null,
        };
        switch (props.country) {
            case "FR":
              this.state.styles = stylesFR;
              this.state.mainCss = mainCssFR;
              break;
            case "ES":
              this.state.styles = stylesES;
              this.state.mainCss = mainCssES;
              break;
            default:
              this.state.styles = stylesES;
              this.state.mainCss = mainCssES;
              break;
        }
        this.fileInput = null;
        this.onAttachButton = this.onAttachButton.bind(this);
        this.onAttachSelected = this.onAttachSelected.bind(this);
        this.dialogClose = this.dialogClose.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
        this.animationSettings = { effect: 'None' };
    }

    componentDidMount() {
        if (this.fileInput) {
          this.fileInput.onchange = this.onAttachSelected;
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ isFileTypeDrop: props.isFileTypeDrop })
      }

    getSignatureLimitWarningContent(){
      return `
        <div style="display: flex; justify-content: space-between; align-items: center; gap: 20px; padding: 20px;">
        <img border='0' src='assets/images/icon-warning.png' alt='juwu' width="100px" height="100px"></img>
        <div class="modal-text-align-content">
          ${i18n.t('signatureLimitWarning.text')}
          <br>
          ${i18n.t('signatureLimitWarning.text2')}
          <br>
          ${i18n.t('signatureLimitWarning.text3')}
        </div>
        </div>`;    
    }

    dialogOpen(instance){
        switch (instance) {
            case "signatureLimitWarning":
              (this.signatureLimitWarning && this.signatureLimitWarning.cssClass) ? this.signatureLimitWarning.cssClass = 'e-fixed' : null;
              break;
            default:
                break;
        }
    }

    dialogClose(){
        if (this.state.centinelaDownloadError === true || this.state.lexonDownloadError === true || this.state.customerareaDownloadError === true){
          this.props.onShowError();
        }
      }

    
    render() {
        const actionButtons = [
            {
                click: () => {
                    this.setState({ optionSelected: 1, warningDialog: false });
                },
                buttonModel: {  content: i18n.t('signatureLimitWarning.optionSingle'), cssClass: 'btn-modal-close', iconPosition: 'IconPosition.Right'},
            },
            {
                click: () => {
                    this.setState({ optionSelected: 2, warningDialog: false });
                },
                buttonModel: { content: i18n.t('signatureLimitWarning.optionAll'), cssClass: 'btn-modal-close', iconPosition: 'IconPosition.Right' }
            }
          ];

        const signatureLimitWarningContent = this.getSignatureLimitWarningContent()
        const { styles, mainCss } = this.state;
        return (
            <div className={styles['widget']}>
                <div className={styles['p10']}>
                    <span className={"lf-icon-add " + styles['title-icon']}></span><span className={styles["generic-title"]}>{i18n.t('attachmentsWidget.title')}</span>
                    {this.props.attachments.map((a, i) => (
                        <p id={`p_${i}`} key={`p_${i}`} className={styles["subtitle"]}>{a.fileName} - ({(((3 * (a.content.length / 4)) - ((a.content.match(/==/g) || []).length) - ((a.content.match(/=/g) || []).length)) / 1000000).toFixed(2)} MB)
                            <a id={`a_${i}`} key={`a_${i}`} href="#" onClick={() => this.removeAttachment(a)}>
                                <span id={`s_${i}`} key={`s_${i}`}  className={`lf-icon-trash right ${styles["icon-trash"]} ${styles['right']}`}></span> 
                            </a>
                        </p>
                    ))}
                    <button
                        className={`${mainCss['mdc-button']} ${mainCss['font-fr']} ${mainCss['mdc-button--unelevated']} ${styles['right']}`}
                        onClick={this.onAttachButton}
                        disabled={(this.props.fatherContainer === 'MessageEditor' && this.props.attachments.length > 0) 
                            || (this.props.fatherContainer === "SmsMessageEditor" && this.props.certificationType === "delivery")}
                    >
                        {i18n.t('attachmentsWidget.attachButton')}
                        <input
                            ref={r => (this.fileInput = r)}
                            id='file-input'
                            type='file'
                            name='name'
                            style={{ display: 'none' }}
                            multiple={this.props.fatherContainer === 'MessageEditor' ? false : true}    
                        />
                    </button>
                    <div className="clearfix"></div>
                </div>
                {this.state.isFileType || this.state.isFileTypeDrop ? <span className={styles['alert-file-type']}>
                   {i18n.t('attachmentsWidget.messageAlert')}
                    <i className='lf-icon-close-round-full'></i>
                </span> : ''}
                {this.props.sendingType == 'signature' ?  
                   <div className={styles["sign"]}>
                    {/* <span className={(this.state.optionSelected === 1 ? 'lf-icon-box-active' : 'lf-icon-box-inactive')}></span>
                    <a href="#" onClick={()=> this.setState({optionSelected: 1})}> {i18n.t('attachmentsWidget.pagesConfiguration.single')}</a>
                    <br></br>
                    <span className={(this.state.optionSelected === 2 ? 'lf-icon-box-active' : 'lf-icon-box-inactive')}></span>
                    <a href="#" onClick={()=> this.setState({optionSelected: 2})}> {i18n.t('attachmentsWidget.pagesConfiguration.all')}</a> */}

                    {/* <span className={(this.state.optionSelected === 1 ? 'lf-icon-step-final' : 'lf-icon-step-first')} style={{color: '#001970'}}></span>
                    <a href="#" onClick={()=> this.setState({optionSelected: 1})}> {i18n.t('attachmentsWidget.pagesConfiguration.single')}</a>
                    <br></br>
                    <span className={(this.state.optionSelected === 2 ? 'lf-icon-step-final' : 'lf-icon-step-first')}  style={{color: '#001970'}}></span>
                    <a href="#" onClick={()=> this.setState({optionSelected: 2})}> {i18n.t('attachmentsWidget.pagesConfiguration.all')}</a> */}

                    {/* <span className={(this.state.optionSelected === 1 ? 'lf-icon-radio-button-active' : 'lf-icon-step-first')} style={{color: '#001970'}}></span>
                    <a href="#" onClick={()=> this.setState({optionSelected: 1})}> {i18n.t('attachmentsWidget.pagesConfiguration.single')}</a>
                    <br></br>
                    <span className={(this.state.optionSelected === 2 ? 'lf-icon-radio-button-active' : 'lf-icon-step-first')}  style={{color: '#001970'}}></span>
                    <a href="#" onClick={()=> this.setState({optionSelected: 2})}> {i18n.t('attachmentsWidget.pagesConfiguration.all')}</a> */}
                    
                    
                    <a href="#" onClick={()=> {this.setState({optionSelected: 1}); this.props.onSelectNumPages(1)}}> 
                     <span className={`${(this.state.optionSelected === 1 ? 'lf-icon-check-round-full' : 'lf-icon-step-first')} ${styles['round-check']}`} ></span>
                     <span className="ml-1">{i18n.t('attachmentsWidget.pagesConfiguration.single')}</span>
                    </a>
                    <br></br>
                   
                    <a href="#" onClick={()=> {this.setState({optionSelected: 2, warningDialog: true}); this.props.onSelectNumPages(2)}}>  
                     <span className={`${(this.state.optionSelected === 2 ? 'lf-icon-check-round-full' : 'lf-icon-step-first')} ${styles['round-check']}`  }></span>
                     <span className="ml-1">{i18n.t('attachmentsWidget.pagesConfiguration.all')}</span>
                    </a>
                    
                   </div> 
                : null}
                <div className="clearfix"></div>
                <DialogComponent 
                   id='info2Dialog'
                   animationSettings={this.animationSettings} 
                   visible={this.state.optionSelected === 2 && this.state.warningDialog === true && this.props.country !== "FR"} 
                   width='75%' 
                   showCloseIcon={true} 
                   buttons={actionButtons} 
                   content={signatureLimitWarningContent}
                   ref={warningdialog => this.signatureLimitWarning = warningdialog} 
                   open={this.dialogOpen.bind(this)} 
                   close={this.dialogClose.bind(this)}
                />
            </div>)
    }

    onAttachButton() {
        this.setState({isFileType: false, isFileTypeDrop:false});
        this.props.resetIsFileDrop();
        return this.fileInput && this.fileInput.click();
    }

    onAttachSelected(event) {
        event.preventDefault();
        event.stopPropagation();

        const addAttachment = (file, dataUrl) => {
           const fileType = file.name.split('.');
            if(fileType[fileType.length-1].toUpperCase() == 'PDF' || fileType[fileType.length-1].toUpperCase() == 'DOCX' 
            || fileType[fileType.length-1].toUpperCase() == 'DOC') {

                const newAttachment = {
                    fileName: file.name,
                    size: file.size,
                    contentType: file.type,
                    content: dataUrl.currentTarget.result.replace(
                        /^data:[^;]*;base64,/,
                        ''
                    ),
                };

                if (fileType[fileType.length-1].toUpperCase() === 'PDF'){
                    const pdfjsLib = require('pdfjs-dist');
                    pdfjsLib.GlobalWorkerOptions.workerSrc = '../../../../assets/scripts/pdf.worker.js'
        
                    pdfjsLib.getDocument({data: atob(newAttachment.content)})
                    .promise.then(doc => {
                      var numPages = doc.numPages;
                      newAttachment.pages = numPages;
        
                      const updatedMessage = { ...this.props.editedMessage };
                      updatedMessage.attachments = updatedMessage.attachments
                          ? [...updatedMessage.attachments, newAttachment]
                          : [newAttachment];
                      this.props.editMessage(updatedMessage);
                    });
                } else {
                    const updatedMessage = { ...this.props.editedMessage };
        
                    updatedMessage.attachments = updatedMessage.attachments
                        ? [...updatedMessage.attachments, newAttachment]
                        : [newAttachment];
                    this.props.editMessage(updatedMessage);
                }
            } else {
                this.setState({isFileType: true});
            }  
        };
        Array.from(event.target.files).forEach((file) => {
          const fileReader = new FileReader();
          fileReader.onload = addAttachment.bind(this, file);
          fileReader.readAsDataURL(file);
        });
        return true;
    }


    removeAttachment(attachment) {
        const userApp = this.props.userApp;
        if (((userApp === 'centinela' || userApp === 'cen' || userApp == 2) || (userApp === 'lexon' || userApp === 'lex' || userApp == 1)|| (userApp === 'customerarea'|| userApp == 9)) && attachment.origin === userApp ){
            this.props.onConfirmAttachRemoval();
        } else {
            this.setState({isFileType: false, isFileTypeDrop: false});
            this.props.resetIsFileDrop();
            const updatedMessage = { ...this.props.editedMessage };
            if (updatedMessage.attachments && updatedMessage.attachments.length) {
                updatedMessage.attachments = updatedMessage.attachments.filter(
                (a) => a !== attachment
                );
                this.props.editMessage(updatedMessage);
            }
        }
    }
    
}

const mapStateToProps = (state) => ({
    attachments: state.application.newMessage.attachments,
    editedMessage: state.application.newMessage,
    userApp: state.lefebvre.userApp
  });
  
  const mapDispatchToProps = (dispatch) => ({
    editMessage: (message) => {
        dispatch(editMessage(message));
      }
    
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )((AttachmentsWidget));