import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import HeaderAddress from './header-address';
import { getCredentials } from '../../selectors/application';
import { editMessage, setTitle, setSelectedService, setSignaturesFilterKey } from '../../actions/application';
import { sendMessage } from '../../services/smtp';
import { getAddresses } from '../../services/message-addresses';
import { persistApplicationNewMessageContent } from '../../services/indexed-db';
import styles from './message-editorFR.scss';
import mainCss from '../../styles/mainFR.scss';
import i18n from 'i18next';
import ACTIONS from '../../actions/lefebvre';
import ComposeMessageEditor from './composeMessageEditor.jsx';

import SpinnerFr from "../spinner/spinnerFr";
import {
  createSignature2,
  addOrUpdateSignature,
  getUserSignatures,
  notifySignature,
  cancelSignatureCen,
  preloadSignatures2,
  getNumAvailableSignatures,
  notifyCen
} from '../../services/api-signaturit';
import * as uuid from 'uuid/v4';
import { getFileType } from '../../services/mimeType';
import  AttachmentsWidget  from './widgets/attachments-widget2';
import { ExpirationWidget } from './widgets/expiration-widget';
import { RemindersWidget } from './widgets/reminders-widget';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import RolSelector from './rol-selector/rol-selector';

class MessageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkDialogVisible: false,
      linkDialogUrl: '',
      dropZoneActive: false,
      // Stores state of current selection in the dialog (is title, underlined... H1, H2, ..., italic, underline)
      // Used in editor buttons to activate/deactivate them
      editorState: {},
      selectedReminderOption: '',
      reminderDays: 0,
      selectedExpirationOption: '',
      expirationDays: 7,
      hideAlertDialog: false,
      hideConfirmDialog: false,
      hideRolDialog: false,
      bigAttachments: false,
      numPagesOption: 1,
      MaximumSigners: 40,
      isCallApis: false,
      isFileType: false,
      isContacts: false,
      multiPageError: false,
      country: props.country
    };

    this.fileInput = null;
    this.editorRef = null;
    this.headerFormRef = React.createRef();
    this.handleSetState = (patchedState) => this.setState(patchedState);
    this.handleSubmit = this.submit.bind(this);
    // Global events
    this.handleOnDrop = this.onDrop.bind(this);
    this.handleOnDragOver = this.onDragOver.bind(this);
    this.handleOnDragLeave = this.onDragLeave.bind(this);
    // Header Address Events
    this.handleAddAddress = this.addAddress.bind(this);
    this.handleRemoveAddress = this.removeAddress.bind(this);
    this.handleMoveAddress = this.moveAddress.bind(this);
    // Subject events
    this.handleOnSubjectChange = this.onSubjectChange.bind(this);
    // Editor events
    this.handleEditorChange = this.editorChange.bind(this);
    this.onAttachButton = this.onAttachButton.bind(this);
    this.onAttachSelected = this.onAttachSelected.bind(this);
    this.handleOnReminderDaysChange = this.onReminderDaysChange.bind(this);
    this.handleOnExpirationDaysChange = this.onExpirationDaysChange.bind(this);
    this.callApis = this.callApis.bind(this);
    this.combineInfo = this.combineInfo.bind(this);
    this.getDocumentsNamesAndIds = this.getDocumentsNamesAndIds.bind(this);
    this.getDocumentsIds = this.getDocumentsIds.bind(this);
    this.getDocumentsNames = this.getDocumentsNames.bind(this);
    this.buildDocumentsInfo = this.buildDocumentsInfo.bind(this);

    this.onChangeReminder = this.onChangeReminder.bind(this);
    this.onChangeExpiration = this.onChangeExpiration.bind(this);

    this.dialogClose = this.dialogClose.bind(this);
    this.dialogOpen = this.dialogOpen.bind(this);
    this.animationSettings = { effect: 'None' };
    this.handleNumPagesOption = this.handleNumPagesOption.bind(this);
    this.getRoleInfo = this.getRoleInfo.bind(this);
    this.resetIsFileDrop = this.resetIsFileDrop.bind(this);
  }

  handleNumPagesOption(option){
    this.setState({numPagesOption: option});
  }

  resetIsFileDrop(){
    this.setState({isFileType: false});
  }

  resetReceivedInfo(){
    this.props.setMailContacts(null);
    this.props.setAdminContacts(null);
    this.props.setUserApp('lefebvredalloz');
    this.props.setGuid(null);
    this.props.setTitle('');
    this.props.setIdDocuments(null);
  }

  getRoleInfo(recipients){

    if (this.headerFormRef.current.reportValidity()) {
        // Get content directly from editor, state content may not contain latest changes
        const content = this.getEditor().getContent();
        const { cc, subject } = this.props;
        const { lefebvre } = this.props;
        const userBranding = (lefebvre && lefebvre.userBrandings && lefebvre.userBrandings.signature) 
          ? lefebvre.userBrandings.signature.find((b) => b.app === lefebvre.userApp) 
          : '';
  
        let reminders = [];
        switch (this.state.selectedReminderOption) {
          case 'option1': // every x days
            if (this.state.selectedExpirationOption === 'exp_option1') {
              for (let index = 0; index < this.state.expirationDays; index++) {
                if (this.state.reminderDays * (index + 1) <= this.state.expirationDays) {
                  reminders[index] = this.state.reminderDays * (index + 1);
                }
              }
            } else {
              for (let index = 0; index < 30; index++) {
                reminders[index] = this.state.reminderDays * (index + 1);
              }
            }
            break;
          case 'option2': //daily
            if (this.state.selectedExpirationOption === 'exp_option1') {
              for (let index = 0; index < this.state.expirationDays; index++) {
                reminders[index] = index + 1;
                //reminders.push(index + 1);
              }
            } else {
              for (let index = 0; index < 30; index++) {
                reminders[index] = index + 1;
              }
            }
            break;
          case 'option3': //weekly
            if (this.state.selectedExpirationOption === 'exp_option1') {
              for (let index = 0; index < this.state.expirationDays; index++) {
                if (7 * (index + 1) < this.state.expirationDays) {
                  reminders[index] = 7 * (index + 1);
                }
              }
            } else {
              for (let index = 0; index < 30; index++) {
                reminders[index] = 7 * (index + 1);
              }
            }
            break;
          default:
            reminders[0] = -1;
            break;
        }
  
        let expiration;
        switch (this.state.selectedExpirationOption) {
          case 'exp_option1': // expires
            expiration = this.state.expirationDays;
            break;
          case 'exp_option2': // never expires
            expiration = 0;
            break;
          default:
            expiration = -1;
            break;
        }
 
        let guid = lefebvre.guid;
        if (guid === null) {
          guid = uuid();
        }
  

        if (this.props.attachments) {
          let attachmentsList = [];
          this.props.attachments.forEach((attachment) => {
            //var attachment = this.props.attachments[0];
            var file = new File([attachment.content], attachment.fileName, {
              type: getFileType(attachment.fileName),
              lastModified: new Date(),
            });
            attachmentsList.push({file: file, pages: attachment.pages});
          });
          //this.callApis(to, subject, content.innerHTML, file, this.props.attachments[0].content, reminders, expiration, lefebvre.userId, guid, userBranding.externalId);


          this.callApis(
            recipients,
            cc,
            subject,
            content.innerHTML,
            this.props.attachments,
            reminders,
            expiration,
            lefebvre.userId,
            guid,
            (userBranding && userBranding.externalId) ? userBranding.externalId : ''
          );
        }
        //createSignature(to, subject, content.innerHTML, document.getElementById('file-input').files[0], reminders, expiration, lefebvre.userId, guid);
      }
  }

  dialogClose(){
    this.setState({
        hideAlertDialog: false, 
        bigAttachments: false, 
        hideConfirmDialog: false, 
        hideRolDialog: false,
        multiPageError: false
    });
  }

  dialogOpen(instance){
    switch (instance) {
        case "alertDialog":
            (this.alertDialogInstance && this.alertDialogInstance.cssClass) ? this.alertDialogInstance.cssClass = 'e-fixed' : null;
            break;
        default:
            break;
    }
}

  onDiscardSignatureOk(){
    const {close, lefebvre, application} = this.props
    cancelSignatureCen(lefebvre.guid)
    .then(res => {
      null
    })
    .catch(err => {
      console.log(err);
    })

    this.setState({ hideConfirmDialog: false });
    this.resetReceivedInfo();
    close(application);
}

  componentDidMount() {
    if (this.fileInput) {
      this.fileInput.onchange = this.onAttachSelected;
    }
    
    this.setState({isContacts: this.props.lefebvre.roles.some(e => e === "Centinela")});
    //createSignature();
  }

  componentDidUpdate(prevProps, prevState){
    // All pages signature is only available with PDFs. Verify condition to show modal and reset attached document
    if (
      ((prevProps.attachments !== this.props.attachments) && this.props.attachments.length > 0) || // verify condition with added attachments
      (prevState.numPagesOption !== this.state.numPagesOption && this.props.attachments.length > 0) // verify condition with option changed
    ){
      if (this.props.attachments[0].contentType.toLowerCase() !== 'application/pdf' && this.state.numPagesOption === 2){
        this.setState({hideAlertDialog: true, multiPageError: true})
        this.removeAttachment(this.props.attachments[0]);
      }
    }
  }

  removeMessageEditor(aplication) {
    const { close, lefebvre } = this.props;

    if (lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === "2"){
      this.setState({hideConfirmDialog: true});
    } else {
      this.resetReceivedInfo();
      close(aplication);
    }
  }


  onChangeReminder(reminder) {
    this.setState({
      selectedReminderOption: `option${reminder.option}`,
      reminderDays: reminder.data
    })
  }

  onChangeExpiration(expiration) {
    this.setState({
      selectedExpirationOption: `exp_option${expiration.option}`,
      expirationDays: expiration.data
    })
  }

  getInfoDialogContent(){
    var base = `
      <span class="lf-icon-information modal-icon-content"></span>
      <div class="modal-text-content">
       {{text}}
      </div>`;
    
    var text = '';

    if (this.props.attachments.length === 0 && this.state.multiPageError === false){
      text = i18n.t('noAttachmentsModal.text');
    } else 
    if (this.state.bigAttachments){
      text = i18n.t('bigFileModal.text');
    } else
    if (this.state.multiPageError){
      text = i18n.t('multiPageErrorModal.text');
    } else {
      text = i18n.t('noSignersModal.text');
    }

    return base.replace('{{text}}', text);
  }

  render() {
    const confirmButtons = [
      {
          click: () => {
          this.setState({ hideConfirmDialog: false });
          },
          buttonModel: {  content: i18n.t('confirmationModal.no'), cssClass: 'btn-modal-close' }
      },
      {
          click: () => {
              this.setState({ hideConfirmDialog: false });
              this.onDiscardSignatureOk();
          },
          buttonModel: { content: i18n.t('confirmationModal.yes'), isPrimary: true }
      }
    ];

    const infoDialogContent = this.getInfoDialogContent();

    const {
      t,
      className,
      application,
      sendingType,
      to,
      cc,
      subject,
      content,
      lefebvre,
      country
    } = this.props;
 
    return (
      <div
        id="main-message-editor"
        className={`${className} ${styles['message-editor']}`}
        onDrop={this.handleOnDrop}
        onDragOver={this.handleOnDragOver}
        onDragLeave={this.handleOnDragLeave}>
         {this.state.isCallApis ? 
          <div className={styles['spinner-container']}> 
           <div className={styles['spinner']}>
            <SpinnerFr /> 
           </div>
          </div> : ''}
        {this.state.dropZoneActive ? (
          <div className={styles.dropZone}>
            <div className={styles.dropZoneMessage}>
              <i className={'material-icons'}>attach_file</i>
              {t('messageEditor.dropZoneMessage')}
            </div>
          </div>
        ) : null}
        <div className={styles.header}>
          <form ref={this.headerFormRef}>
            <HeaderAddress
              id={'to'}
              addresses={to}
              onAddressAdd={this.handleAddAddress}
              onAddressRemove={this.handleRemoveAddress}
              onAddressMove={this.handleMoveAddress}
              className={styles.address}
              chipClassName={styles.chip}
              autoSuggestClassName={styles.autoSuggest}
              autoSuggestMenuClassName={styles.autoSuggestMenu}
              getAddresses={this.props.getAddresses}
              label={i18n.t('messageEditor.to')}
              lefebvre={lefebvre}
              isContacts={false}
              sendingType={sendingType}
            />
            <HeaderAddress
              id={'cc'}
              addresses={cc}
              onAddressAdd={this.handleAddAddress}
              onAddressRemove={this.handleRemoveAddress}
              onAddressMove={this.handleMoveAddress}
              className={styles.address}
              chipClassName={styles.chip}
              autoSuggestClassName={styles.autoSuggest}
              autoSuggestMenuClassName={styles.autoSuggestMenu}
              getAddresses={this.props.getAddresses}
              label={t('messageEditor.cc')}
              lefebvre={lefebvre}
              sendingType={sendingType}
            />
            <div className={styles.subject}>
              <input
                type={'text'}
                placeholder={t('messageEditor.subject')}
                value={subject}
                onChange={this.handleOnSubjectChange}
              />
            </div>
          </form>
        </div>
        <div
          className={styles['editor-wrapper']}
          onClick={() => this.editorWrapperClick()}>
          <div className={styles['editor-container']}>
            <ComposeMessageEditor
              ref={(ref) => (this.editorRef = ref)}
              onChange={this.handleEditorChange}
              defaultValue={content}
            />
          </div>
          <div className={styles['side-container']}>
            <AttachmentsWidget 
              // onAttachButton={this.onAttachButton()} 
              // onAttachSelected={this.onAttachSelected()}
              // removeAttachment={this.removeAttachment()}
              sendingType={sendingType}
              onSelectNumPages={this.handleNumPagesOption}
              isFileTypeDrop={this.state.isFileType}
              resetIsFileDrop={this.resetIsFileDrop}
              fatherContainer={'MessageEditor'}
              country={country}
            ></AttachmentsWidget>
            <ExpirationWidget onChange={this.onChangeExpiration} country={country}></ExpirationWidget>
            <RemindersWidget onChange={this.onChangeReminder} country={country}></RemindersWidget>
          </div>
          <div className={styles['action-buttons']}>
            <button
              className={`${mainCss['mdc-button']} ${mainCss['mdc-button--unelevated']} ${styles['action-button']} ${styles.cancel}`}
              onClick={() => this.removeMessageEditor(application)}>
              {t('messageEditor.discard')}
            </button>
            <button
              className={`${mainCss['mdc-button']} ${mainCss['mdc-button--unelevated']} ${styles['action-button']} ${styles.send}`}
              //disabled={this.props.attachments.length === 0}
              onClick={this.handleSubmit}>
              {t('messageEditor.send')}
            </button>
                        
          </div>
        </div>

        <DialogComponent 
          id="info2Dialog-fr" 
          //header=' ' 
          visible={this.state.hideAlertDialog} 
          animationSettings={this.animationSettings} 
          width='60%' 
          content={infoDialogContent}
          ref={alertdialog => this.alertDialogInstance = alertdialog} 
          open={this.dialogOpen("info2Dialog")} 
          close={this.dialogClose}
          showCloseIcon={true}
        />
        <DialogComponent 
          id="rolDialog-fr" 
          header={i18n.t("messageEditor.grid.recipientsRole")} 
          visible={this.state.hideRolDialog} 
          showCloseIcon={true} 
          animationSettings={this.animationSettings} 
          width='80%'
          //content={RolSelector} 
          ref={dialog => this.rolDialog = dialog} 
          //target='#target' 
          open={this.dialogOpen("rolDialog")} 
          close={this.dialogClose}
        >
          <RolSelector 
          recipients={to}
          onFinishRoles={this.getRoleInfo}
          dialogClose={this.dialogClose.bind(this)}
          country={country}
          />
        </DialogComponent>
      </div>
    );
  }

  bigAttachments(){
    let maxSize = 15000000;
    let totalSize = 0
    this.props.attachments.map(attachment => totalSize = totalSize + attachment.size);
    return (totalSize >= maxSize);
  }

  
  submit() {
    if (this.props.to.length === 0 || this.props.attachments.length === 0){
      this.setState({ hideAlertDialog: true });
    } else if (this.bigAttachments()){
      this.setState({ hideAlertDialog: true, bigAttachments: true});
    }
    else {
      this.setState({hideRolDialog:true});
    }
  }

  example() {
    var lookup = {};
    var items = json.DATA;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var name = item.name;

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push(name);
      }
    }
  }

  getDocumentsNames(signature) {
    var lookup = {};
    var items = signature.documents;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var name = item.file.name;
      var id = item.id;

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push(name);
      }
    }
    return result;
  }

  getDocumentsIds(signature) {
    var lookup = {};
    var items = signature.documents;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var id = item.id;

      if (!(id in lookup)) {
        lookup[id] = 1;
        result.push(id);
      }
    }
    return result;
  }

  getDocumentsNamesAndIds(signature) {
    var lookup = {};
    var items = signature.documents;
    var result = [];

    for (var item, i = 0; (item = items[i++]);) {
      var name = item.file.name;
      var id = item.id;
      var info = { name: name, id: id };

      if (!(info in lookup)) {
        lookup[info] = 1;
        result.push(info);
      }
    }
    return result;
  }

  combineInfo(externalIds, internalIds) {
    let merged = [];

    for (let i = 0; i < externalIds.length; i++) {
      merged.push({
        ...externalIds[i],
        ...internalIds.find(
          (itmInner) => itmInner.name === externalIds[i].name
        ),
      });
    }
    return merged;
  }

  buildDocumentsInfo(signature) {
    let result;

    result = signature.documents.map((e) => {
      return {
        externalFileName: e.file.name,
        externalId: e.id,
        signer: { name: e.name, email: e.email },
        internalInfo: ( this.props.lefebvre && this.props.lefebvre.idDocuments ) ? this.props.lefebvre.idDocuments.find((d) => {
          // if (d.docName.replace(/[\])}[{( ]/g, '') === e.file.name) { //replaces () {} [] ' ' with _
          // Example of how signaturit changes names: blank spaces and parenthesis with under scores
          // Original Name: Small Business_unlocked_1 2(3)4[5]6{7}8-9,10'11¡12¿13¨14´15ç16+17^18;19.20$21%22&23º24ª.pdf
          // Signatur Name: Small_Business_unlocked_1_2_3_4[5]6{7}8-9,10'11¡12¿13¨14´15ç16+17^18;19.20$21%22&23º24ª.pdf
          if (d.docName.replace(/[)( ]/g, '_') === e.file.name) {
            return d.docId;
            
          }
        }) : null,
      };
    });

    return result;
  }

  callApis(
    recipients,
    cc,
    subject,
    content,
    files,
    reminders,
    expiration,
    userId,
    guid,
    userBrandingId
  ) {
    const { lefebvre } = this.props;
    if (userBrandingId === null || userBrandingId === undefined || userBrandingId === 'null' || userBrandingId === ''){
      userBrandingId = window.DEFAULT_BRANDING_FR;
    }

    this.setState({isCallApis: true});
    createSignature2(
      this.props.application.country,
      recipients,
      cc,
      subject,
      content,
      files,
      this.state.numPagesOption,
      reminders,
      expiration,
      userId,
      guid,
      userBrandingId,
      this.props.credentials.encrypted
    ).then((signatureInfo) => {
      if (signatureInfo.status_code) {
      } else {
        getUserSignatures(userId, this.props.credentials.encrypted, this.props.application.country).then((userInfo) => {
          var documentsInfo = this.buildDocumentsInfo(signatureInfo);
          addOrUpdateSignature(
            userId,
            signatureInfo.id,
            guid,
            lefebvre.userApp,
            signatureInfo.created_at,
            null,
            documentsInfo,
            this.props.credentials.encrypted,
            this.props.application.country
          );
          
          this.props.setMailContacts(null);
          this.props.setAdminContacts(null);
          this.props.setUserApp('lefebvredalloz');
          this.props.setGuid(null);
          this.props.setTitle(i18n.t('topBar.app'));
          this.props.setIdDocuments(null);
          this.props.setSelectedService('signature');
          this.props.setSignaturesFilterKey('Mostrar todas');
          this.props.preloadSignatures(this.props.application.country, lefebvre.userId, this.props.application.user.credentials.encrypted);
          this.props.close(this.props.application);
        });
      }
      this.setState({isCallApis: false, hideRolDialog: false});
    });
  }

  validateAddress(updatedMessage, id, address, name) {
    const addressData = {address: address, name: name}
    if(updatedMessage.to.length == this.state.MaximumSigners
       && id != 'cc') {
    } else if(updatedMessage.to.length == this.state.MaximumSigners 
      && id == 'cc') {
      updatedMessage[id] = [...updatedMessage[id], addressData];
      this.props.editMessage(updatedMessage);
    } else {
      updatedMessage[id] = [...updatedMessage[id], addressData];
      this.props.editMessage(updatedMessage);
    }
  }

  /**
   * Adds an address to the list matching the id.
   *
   * @param id
   * @param address
   */
  addAddress(id, address, name) {
    if (address.length > 0) {
      const updatedMessage = { ...this.props.editedMessage };
      const recipientRepeats = updatedMessage.to.some(data => {
      return (data.address === address);    
     });
   
     if(!recipientRepeats){
      this.validateAddress(updatedMessage, id, address, name);
     }
      
    }
  }

  /**
   * Removes the address from the under the field matching the id.
   *
   * @param id
   * @param address
   */
  removeAddress(id, address) {
    const updatedMessage = { ...this.props.editedMessage };
    updatedMessage[id] = [...updatedMessage[id]];
    updatedMessage[id].splice(updatedMessage[id].indexOf(address), 1);
    this.props.editMessage(updatedMessage);
  }

  /**
   * Moves an address from the address list under the field matching the fromId to the address field
   * matching the toId.
   *
   * @param fromId
   * @param toId
   * @param address
   */
  moveAddress(fromId, toId, address, name) {
    const updatedMessage = { ...this.props.editedMessage };
    const addressData = {address: address, name: name}
    // Remove
    updatedMessage[fromId].splice(updatedMessage[fromId].indexOf(address), 1);
    // Add
    updatedMessage[toId] = [...updatedMessage[toId], addressData];
    this.props.editMessage(updatedMessage);
  }

  onSubjectChange(event) {
    const target = event.target;
    const updatedMessage = { ...this.props.editedMessage };
    this.props.editMessage({ ...updatedMessage, subject: target.value });
  }

  onReminderDaysChange(event) {
    const target = event.target;
    this.setState = { reminderDays: target.value };
  }

  onExpirationDaysChange(event) {
    const target = event.target;
    this.setState = { expirationDays: target.value };
  }

  onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dropZoneActive: false })
    
    const addAttachment = (file, dataUrl) => {
       const fileType = file.name.split('.');
        if(fileType[fileType.length-1].toUpperCase() == 'PDF' || fileType[fileType.length-1] == 'DOCX' 
        || fileType[fileType.length-1].toUpperCase() == 'DOC') {

          const newAttachment = {
            fileName: file.name,
            size: file.size,
            contentType: file.type,
            content: dataUrl.currentTarget.result.replace(
                /^data:[^;]*;base64,/,
                ''
            ),
            };

          if (fileType[fileType.length-1].toUpperCase() === 'PDF'){
            const pdfjsLib = require('pdfjs-dist');
            pdfjsLib.GlobalWorkerOptions.workerSrc = '../../../../assets/scripts/pdf.worker.js'

            pdfjsLib.getDocument({data: atob(newAttachment.content)})
            .promise.then(doc => {
              var numPages = doc.numPages;
              newAttachment.pages = numPages;
              const updatedMessage = { ...this.props.editedMessage };
              updatedMessage.attachments = updatedMessage.attachments
                  ? [...updatedMessage.attachments, newAttachment]
                  : [newAttachment];
              this.props.editMessage(updatedMessage);
            });
          } else {
            const updatedMessage = { ...this.props.editedMessage };

            updatedMessage.attachments = updatedMessage.attachments
                ? [...updatedMessage.attachments, newAttachment]
                : [newAttachment];
            this.props.editMessage(updatedMessage);
          }
        } else {
            this.setState({isFileType: true});
        }
       
    };
    if (this.props.editedMessage.attachments.length === 0){
      let file = event.dataTransfer.files[event.dataTransfer.files.length-1];
      //Array.from(event.dataTransfer.files).forEach((file) => {
        const fileReader = new FileReader();
        fileReader.onload = addAttachment.bind(this, file);
        fileReader.readAsDataURL(file);
        this.setState({isFileType: false});
      //});
    }
    
    return true;
  }

  onDragOver(event) {
    event.preventDefault();
    if (
      event.dataTransfer.types &&
      Array.from(event.dataTransfer.types).includes('Files')
    ) {
      this.setState({ dropZoneActive: true });
    }
  }

  onDragLeave(event) {
    event.preventDefault();
    this.setState({ dropZoneActive: false });
  }

  removeAttachment(attachment) {
    const updatedMessage = { ...this.props.editedMessage };
    if (updatedMessage.attachments && updatedMessage.attachments.length) {
      updatedMessage.attachments = updatedMessage.attachments.filter(
        (a) => a !== attachment
      );
      this.props.editMessage(updatedMessage);
    }
  }

  onAttachButton() {
    return this.fileInput && this.fileInput.click();
  }

  onAttachSelected(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ dropZoneActive: false });
    const addAttachment = (file, dataUrl) => {
      const newAttachment = {
        fileName: file.name,
        size: file.size,
        contentType: file.type,
        content: dataUrl.currentTarget.result.replace(
          /^data:[^;]*;base64,/,
          ''
        ),
      };
      const updatedMessage = { ...this.props.editedMessage };
      updatedMessage.attachments = updatedMessage.attachments
        ? [...updatedMessage.attachments, newAttachment]
        : [newAttachment];
      this.props.editMessage(updatedMessage);
    };
    Array.from(event.target.files).forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = addAttachment.bind(this, file);
      fileReader.readAsDataURL(file);
    });
    return true;
  }

  getEditor() {
    if (this.editorRef && this.editorRef.refEditor) {
      return this.editorRef.refEditor;
    }
    return null;
  }

  editorWrapperClick() {
    this.getEditor();
  }

  /**
   * Every change in the editor will trigger this method.
   *
   * For performance reasons, we'll only persist the editor content every EDITOR_PERSISTED_AFTER_CHARACTERS_ADDED
   *
   * @param content
   */
  editorChange(content) {
    this.props.editMessage({ ...this.props.editedMessage, content });
    persistApplicationNewMessageContent(this.props.application, content);
  }
}

MessageEditor.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
};

MessageEditor.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  application: state.application,
  country: state.application.country,
  credentials: getCredentials(state),
  editedMessage: state.application.newMessage,
  sendingType: state.application.newMessage.sendingType,
  to: state.application.newMessage.to,
  cc: state.application.newMessage.cc,
  bcc: state.application.newMessage.bcc,
  attachments: state.application.newMessage.attachments,
  subject: state.application.newMessage.subject,
  name: state.application.newMessage.name,
  editor: state.application.newMessage.editor,
  content: state.application.newMessage.content,
  getAddresses: (value) => getAddresses(value, state.messages.cache),
  lefebvre: state.lefebvre,
});

const mapDispatchToProps = (dispatch) => ({
  close: (application) => {
    dispatch(editMessage(null));
    // Clear content (editorBlur may be half way through -> force a message in the service worker to clear content after)
    // noinspection JSIgnoredPromiseFromCall
    persistApplicationNewMessageContent(application, '');
  },
  editMessage: (message) => {
    dispatch(editMessage(message));
  },
  sendMessage: (
    credentials,
    { inReplyTo, references, to, cc, bcc, attachments, subject, content }
  ) =>
    sendMessage(dispatch, credentials, {
      inReplyTo,
      references,
      to,
      cc,
      bcc,
      attachments,
      subject,
      content,
    }),
  // setCaseFile: casefile => dispatch(ACTIONS.setCaseFile(casefile)),
  setMailContacts: (mailContacts) =>
    dispatch(ACTIONS.setMailContacts(mailContacts)),
  setGuid: (guid) => dispatch(ACTIONS.setGUID(guid)),
  setAvailableSignatures: (num) =>
    dispatch(ACTIONS.setAvailableSignatures(num)),
  setNumAvailableSignatures: num =>
    dispatch(ACTIONS.setNumAvailableEmails(num)),
  setTitle: title => dispatch(setTitle(title)),
  setUserApp: app => dispatch(ACTIONS.setUserApp(app)),
  setAdminContacts: contacts => dispatch(ACTIONS.setAdminContacts(contacts)),
  setIdDocuments: id => dispatch(ACTIONS.setIdDocuments(id)),
  preloadSignatures: (client, userId, auth) => preloadSignatures2(dispatch, client, userId, auth),
  setSelectedService: selectService  => dispatch(setSelectedService(selectService)),
  setSignaturesFilterKey: key => dispatch(setSignaturesFilterKey(key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(MessageEditor));