import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import stylesES from './top-bar.scss';
import stylesFR from './top-barFR.scss'
import i18n from '../../services/i18n';


export class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styles: null
    }
    switch (this.props.country) {
      case "FR":
        this.state.styles = stylesFR;
        break;
      case "ES":
        this.state.styles = stylesES;
      default:
        break;
    }
  }

  render() {
    const { styles } = this.state;
    const props = this.props;
    const {
      appTitle
    } = props;
    const collapsed = props.sideBarCollapsed;
    let title = props.title;
    if (
      props.selectedFolder &&
      props.selectedFolder.name
    ) {
      title = `${props.selectedFolder.name}`;
    }
    return (
        <div className={ 
          `${ !collapsed ? 
           styles['pasos-firma'] : 
           styles['firma-without-side-bar']}`} >
          <span className={styles['producto-procedente']}></span> { !appTitle ? i18n.t('topBar.app') : appTitle} 
          <span className={styles.miga}>{title}</span>
        </div>
    );
  }
}

TopBar.propTypes = {
  title: PropTypes.string,
  appTitle: PropTypes.string
};

const mapStateToProps = state => {
  
  return {
    title: state.application.title,
    country: state.application.country,
    appTitle: state.application.appTitle,
    newMessage: state.application.newMessage,
    folders: state.folders,
    lefebvre: state.lefebvre
  };
};

export default connect(mapStateToProps)(TopBar);