import React, {Component} from 'react';
import {connect} from 'react-redux';
import i18n from 'i18next';

import MenuItem from './menu-item';
import {
  selectFolder, 
  setTitle, 
  editMessage, 
  setAppTitle, 
  setSelectedService, 
  setSignaturesFilterKey, 
  selectSignature, 
  selectEmail,
  selectSms
} from '../../actions/application';

import {clearSelected} from '../../actions/messages';
import {clearSelectedMessage} from '../../services/application';
import { persistApplicationNewMessageContent } from '../../services/indexed-db';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { setUserApp, setGUID, setMailContacts, setAdminContacts, setIdDocuments } from '../../actions/lefebvre';
import { cancelSignatureCen } from "../../services/api-signaturit";

export const DroppablePayloadTypes = {
  FOLDER: 'FOLDER',
  MESSAGES: 'MESSAGES'
};

export class MenuListClass extends Component {
  constructor(props){
    super(props);
    this.state = {
      hideConfirmDialog: false,
      hideAlertDialog: false,
      isDisable: true
    }
  }

  componentDidMount() {
    this.props.setAppTitle(i18n.t('topBar.app'));
  }
  
  getConfirm = () => {
    this.setState({hideAlertDialog: true});
  }

  render() {
    const { collapsed, lefebvre, selectedService } = this.props;
    return (
        <div>
          <MenuItem 
           id={'signature'}
           selectedService={selectedService}
           title={i18n.t('sideBar.filterMenu')}
           icon="lf-icon-signature-certificate"
           onClick={this.onClick}
           getConfirm={this.getConfirm}
           collapsed={collapsed}
           country={this.props.application.country}
           disable={lefebvre.roles
           && lefebvre.roles.includes('Firma Digital') ?
           true : false} /> 
        </div>
    );
  }

  onClick = (event, key) => {
    const { close, lefebvre, application } = this.props;
    
      event.stopPropagation();
      this.props.signatureClicked(null);
      this.props.setSignaturesFilterKey(key);
      this.props.setTitle(event.currentTarget.childNodes[1].textContent);
      this.props.setUserApp('lefebvredalloz');
      this.props.setAppTitle(i18n.t('topBar.app'));
      this.props.setSelectedService('signature');
      this.props.close(this.props.application);
  }

  dialogClose(){
    this.setState({
        hideAlertDialog: false, 
        bigAttachments: false,
        entinelaDownloadError: false,
        hideConfirmDialog: false,
        hideAlertDialog: false
    });
  }
}

const mapStateToProps = state => ({
  application: state.application,
  foldersState: state.folders,
  messages: state.messages,
  lefebvre: state.lefebvre,
  selectedService: state.application.selectedService
});

const mapDispatchToProps = dispatch => ({
  selectFolder: (folder, user) => {
    dispatch(selectFolder(folder));
    clearSelectedMessage(dispatch);
    dispatch(clearSelected());
  },
  setSignaturesFilterKey: (key) => dispatch(setSignaturesFilterKey(key)),
  signatureClicked: signature => dispatch(selectSignature(signature)),
  emailClicked: email => { dispatch(selectEmail(email))},
  smsClicked: sms => { dispatch(selectSms(sms))},
  setTitle: title => dispatch(setTitle(title)),
  setAppTitle: title => dispatch(setAppTitle(title)),
  setSelectedService: selectService  => dispatch(setSelectedService(selectService)),
  close: (application) => {
    dispatch(editMessage(null));
    // Clear content (editorBlur may be half way through -> force a message in the service worker to clear content after)
    // noinspection JSIgnoredPromiseFromCall
    persistApplicationNewMessageContent(application, '');
  },
  setMailContacts: contacts => dispatch(setMailContacts(contacts)),
  setAdminContacts: contacts => dispatch(setAdminContacts(contacts)),
  setGuid: guid => dispatch(setGUID(guid)),
  setUserApp: app => dispatch(setUserApp(app)),
  setIdDocuments: id => dispatch(setIdDocuments(id))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => (Object.assign({}, stateProps, dispatchProps, ownProps, {
  selectFolder: folder =>
    dispatchProps.selectFolder(folder, stateProps.application.user),
  setSignaturesFilterKey: key => dispatchProps.setSignaturesFilterKey(key),
  signatureClicked: signature => dispatchProps.signatureClicked(signature),
  emailClicked: email => dispatchProps.emailClicked(email),
  smsClicked: sms => dispatchProps.smsClicked(sms),
  setTitle: title => dispatchProps.setTitle(title),
  setMailContacts: contacts => dispatchProps.setMailContacts(contacts),
  setAdminContacts: contacts => dispatchProps.setAdminContacts(contacts),
  setGuid: guid => dispatchProps.setGuid(guid),
  setUserApp: app => dispatchProps.setUserApp(app),
  setIdDocuments: id => dispatchProps.setIdDocuments(id)
}));

const MenuList = connect(mapStateToProps, mapDispatchToProps, mergeProps)(MenuListClass);
export default MenuList;
