/* eslint-disable indent */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import history from '../routes/history';
import Spinner from './spinner/spinner';
import TopBar from './top-bar/top-bar';
import MainBar from './main-bar/main-bar';
import SideBar from './side-bar/side-bar';
import MessageEditor from './message-editor/message-editor';
import EmailMessageEditor from './message-editor/email-message-editor';
import SmsMessageEditor from './message-editor/sms-message-editor';
import DocumentMessageEditor from './message-editor/document-message-editor'
import MessageList from './message-list/message-list';
import MessageViewer from './message-viewer/message-viewer';
import EmailMessageViewer from './message-viewer/email-message-viewer';
import SmsMessageViewer from './message-viewer/sms-message-viewer';

import {
  clearUserCredentials,
  selectMessage,
  selectFolder,
  outboxEventNotified,
  editMessage,
  setError,
  selectSignature,
  selectEmail,
  selectSms,
  setTitle,
  setAppTitle,
  setSelectedService
} from '../actions/application';
import { clearSelected, setSelected } from '../actions/messages';
import {
  setGUID,
  setSign,
  setIdDocuments,
  setUserApp,
  setCenContacts
} from '../actions/lefebvre';

import { editNewMessage, clearSelectedMessage } from '../services/application';
import styles from './app.scss';
import { translate } from 'react-i18next';
import Sidebar from 'react-sidebar';
import LexonComponent from '../apps/lexon_content';
import CalendarComponent from '../apps/calendar_content';
import DataBaseComponent from '../apps/database_content';
import { preloadEmails, preloadFirstSignatures, preloadSignatures2, preloadSms, getAttachmentLex, getAttachmentCen, getAttachmentCus, cancelSignatureCen, cancelSignatureLex, cancelSignatureCus, getContactsCentinelaSMS, getContactsCustomerarea, getUserCertifiedDocuments, preloadCertifiedDocuments, searchSignatures, searchEmails, searchSms, getContactsLexon, getBBDDLexon } from "../services/api-signaturit";
import { getFileType } from '../services/mimeType';
import { backendRequest, backendRequestCompleted } from '../actions/messages';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      sidebarDocked: false,
      sideBar: {
        collapsed: false
      },
      sidebarComponent: (
        <img
          border='0'
          alt='Lefebvre'
          src='/assets/images/lexon-fake.png'></img>
      ),
      actualSidebarComponent: 0,
      isUpdatedDefaultAccount: (this.props.application.user) ? true : false,
      attachmentsDownloadError: false,
      hideAlertDialog: false,
      guidNotFound: false
    };

    this.toggleSideBar = this.toggleSideBar.bind(this);
    this.onSetSidebarDocked = this.onSetSidebarDocked.bind(this);
    this.onSetSidebarOpenCalendar = this.onSetSidebarOpenCalendar.bind(this);
    this.onSetSidebarOpenLexon = this.onSetSidebarOpenLexon.bind(this);
    this.onSetSidebarOpenQMemento = this.onSetSidebarOpenQMemento.bind(this);
    this.onSetSidebarOpenCompliance = this.onSetSidebarOpenCompliance.bind(this);
    this.onSetSidebarOpenDatabase = this.onSetSidebarOpenDatabase.bind(this);
    this.handleGetUserFromLexonConnector = this.handleGetUserFromLexonConnector.bind(this);
    this.resetDownloadError = this.resetDownloadError.bind(this);
    this.activeCallSignature = false;
    this.activeCallEmail = false;
    this.activeCallSms = false;
    //this.dialogClose = this.dialogClose.bind(this);
  }

  onSetSidebarOpenCalendar(open) {
    this.setState({
      sidebarComponent: (
        <CalendarComponent sidebarDocked={this.onSetSidebarDocked} />
      )
    });
    this.setState({ sidebarDocked: open });
  }

  onSetSidebarOpenLexon(open) {
    this.setState({
      sidebarComponent: (
        <LexonComponent sidebarDocked={this.onSetSidebarDocked} />
      )
    });
    this.setState({ sidebarDocked: open });
    this.setState({ actualSidebarComponent: 1 });
  }

  onSetSidebarOpenQMemento(open) {
    this.setState({
      sidebarComponent: (
        <DataBaseComponent sidebarDocked={this.onSetSidebarDocked} />
      )
    });
    this.setState({ sidebarDocked: open });
  }

  onSetSidebarOpenCompliance(open) {
    const lexon = (
      <img
        border='0'
        alt='Lefebvre'
        src='/assets/images/lexon-fake-null.png'></img>
    );
    this.setState({ sidebarComponent: lexon });
    this.setState({ sidebarDocked: open });
  }

  onSetSidebarOpenDatabase(open) {
    const lexon = (
      <img
        border='0'
        alt='Lefebvre'
        src='/assets/images/lexon-fake-null.png'></img>
    );
    this.setState({ sidebarComponent: lexon });
    this.setState({ sidebarDocked: open });
  }

  onSetSidebarDocked(open) {
    this.setState({ sidebarDocked: open });
  }

  sendMessagePutUser(user) {
    const { selectedMessages } = this.props.messages;
    window.dispatchEvent(
      new CustomEvent('PutUserFromLexonConnector', {
        detail: {
          user,
          selectedMessages: selectedMessages,
          idCaseFile: this.props.lexon.idCaseFile,
          bbdd: this.props.lexon.bbdd,
          idCompany: this.props.lexon.idCompany,
          idEmail: this.props.lexon.idEmail,
          idFolder: this.props.lexon.idFolder,
          account: this.props.all.login.formValues.user,
          provider: 'IMAP'
        }
      })
    );
  }

  handleGetUserFromLexonConnector() {
    const { userId } = this.props.lexon;

    // Comentar esto (es para pruebas)
    // const userId = 120;
    // Comentar esto (es para pruebas)

    if (userId) {
      this.sendMessagePutUser(userId);
    }
  }

  render() {
    const { t, lefebvre } = this.props;
    const { sideBar, isUpdatedDefaultAccount } = this.state;

    if (!isUpdatedDefaultAccount) {
      return null;
    }

    return (
      <Sidebar
        sidebar={this.state.sidebarComponent}
        open={false}
        pullRight={true}
        docked={this.state.sidebarDocked}
        styles={{
          sidebar: {
            background: 'white',
            zIndex: 999,
            overflowY: 'hidden',
            WebkitTransition: '-webkit-transform 0s',
            willChange: 'transform'
          },
          content: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: 'hidden',
            overflowX: 'hidden',
            WebkitOverflowScrolling: 'touch',
            transition: 'left .0s ease-out, right .0s ease-out'
          },
          overlay: {
            zIndex: 1,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0,
            visibility: 'hidden',
            //transition: "opacity .3s ease-out, visibility .0s ease-out",
            backgroundColor: 'rgba(0,0,0,.3)'
          },
          dragHandle: {
            zIndex: 1,
            position: 'fixed',
            top: 0,
            bottom: 0
          }
        }}>
        <div>
          <MainBar
            sideBarCollapsed={sideBar.collapsed}
            sideBarToggle={this.toggleSideBar}
          />
          <TopBar
            sideBarCollapsed={sideBar.collapsed}
            sideBarToggle={this.toggleSideBar}
          />
          <div id='mainnav-app' />
          {/*<SplitPane split="vertical" minSize={200} maxSize={800} defaultSize={450}  primary="second">*/}
          <div className={styles.app}>
            <Spinner
              visible={this.props.application.activeRequests > 0}
              className={styles.spinner}
              pathClassName={styles.spinnerPath}
            />

            <SideBar
              collapsed={sideBar.collapsed}
              sideBarToggle={this.toggleSideBar}
              casefile={lefebvre.idCaseFile}
              bbdd={lefebvre.bbdd}
            />
            <div id='mainnav-app' />

            <div
              className={`${styles['content-wrapper']}
                                ${sideBar.collapsed
                  ? styles['without-side-bar']
                  : styles['with-side-bar']
                } ${styles['custom-padding-top']}`}>
              {this.renderContent()}
              {this.renderDownloadModal()}
            </div>

            <div className={styles.productpanel}>
              {/* <span
                className={styles.productsbutton}
                isotip={t('productBar.lefebvre')}
                isotip-position='bottom-end'
                isotip-size='small'>
                {lefebvre.user ? (
                  <IconButton onClick={() => this.onSetSidebarOpenLexon(true)}>
                    <img
                      border='0'
                      alt='Lex-On'
                      src='/assets/images/icon-lexon.png'></img>
                  </IconButton>
                ) : (
                  <IconButton>
                    <img
                      disabled
                      border='0'
                      alt='Lex-On'
                      src='/assets/images/icon-lexon.png'></img>
                  </IconButton>
                )}
                <div className={styles.btnselect}></div>
              </span> */}
              {/* <span
                className={styles.productsbutton}
                isotip={t("productBar.database")}
                isotip-position="bottom-end"
                isotip-size="small"
              >
                 <IconButton onClick={() => this.onSetSidebarOpenQMemento(true)}> 
                <IconButton>
                  <img
                    border="0"
                    alt="Calendar"
                    src="assets/images/icon-qmemento.png"
                    className="disabledimg"
                  ></img>
                </IconButton>
                <div className={styles.btnselect}></div>
              </span>
              <span
                className={styles.productsbutton}
                isotip-position="bottom-end"
                isotip-size="small"
              >
                {/* <IconButton
                  disabled
                  onClick={() => this.onSetSidebarOpenCompliance(true)}
                > 
                <IconButton>
                  <img
                    border="0"
                    alt="Calendar"
                    src="assets/images/icon-compliance.png"
                    className="disabledimg"
                  ></img>
                </IconButton>
                <div className={styles.btnselect}></div>
              </span>
              <span
                className={styles.productsbutton}
                isotip={t("productBar.calendar")}
                isotip-position="bottom-end"
                isotip-size="small"
              >
                <IconButton onClick={() => this.onSetSidebarOpenCalendar(true)}> 
                <IconButton>
                  <img
                    border="0"
                    alt="Calendar"
                    src="assets/images/icon-calendar.png"
                    className="disabledimg"
                  ></img>
                </IconButton>
                <div className={styles.btnselect}></div>
              </span>
              {/* <span
                className={styles.productsbutton}
                isotip={t("sideBar.hide")}
                isotip-position="bottom-end"
                isotip-size="small"
              >
                <IconButton onClick={() => this.onSetSidebarDocked(false)}>
                  close
                </IconButton>
                <div className={styles.btnselect}></div>
              </span>

              <span className={styles.spaceproduct}></span>*/}
            </div>
          </div>

          {/*<div className={styles.connector}  style={{
                      backgroundImage: 'url(' + imgUrl + ')',
                      backgroundSize: '120px',
                      backgroundPosition: 'center 110px',
                      backgroundRepeat: 'no-repeat',
                      height: '100%'
                  }}>
                      <div id="lexon-app" className={styles.panelconnectortitle}>
                      </div>
                      <div id="lexon-app-dev" className={styles.panelconnectortitle}>
                      </div>

                  </div>*/}
          {/*</SplitPane>*/}
        </div>
      </Sidebar>
    );
  }

  renderConnector() {
    this.props.history.push('/#/lexon-connector');
  }

  resetDownloadError() {
    this.setState({ attachmentsDownloadError: false });
  }

  resetGuidNotFound() {
    var guid = this.props.lefebvre.guid;
    var userApp = this.props.lefebvre.userApp;
    var token = this.props.lefebvre.token;
    this.setState({ guidNotFound: false });
    this.props.setUserApp('lefebvre');
    this.props.setGUID(null);
    if (userApp === 'centinela') {
      cancelSignatureCen(guid)
        .then(() => null)
        .catch(err => console.log('Error cancelación centinela: ' + err));
    } else if (userApp === 'lexon') {
      cancelSignatureLex(guid)
        .then(() => null)
        .catch(err => console.log('Error cancelación lexon: ' + err));
    } else if (userApp === 'customerarea') {
      cancelSignatureCus(guid, token)
        .then(() => null)
        .catch(err => console.log('Error cancelación customerarea: ' + err));
    }
  }

  // guidNotFoundDialogClose(){
  //   this.setState({ showGuidNotFoundCenDialog: false});
  //   cancelSignatureCen(this.props.lefebvre.guid);
  //   this.props.setUserApp('lefebvre');
  //   this.props.setGuid(null);
  // }

  getDownloadModal(app) {
    if (app === 'centinela') {
      return `  
      <div style='width: 100%;'>  
        <div class="modal-text-align-content">
          ${i18n.t('downloadindCentinelaModal.text')}
        </div>
        <div class='${styles['progress-line']}'/>
      </div>`;
    }
    else if (app === 'lexon') {
      return `  
      <div style='width: 100%;'>  
        <div class="modal-text-align-content">
          ${i18n.t('downloadindLexonModal.text')}
        </div>
        <div class='${styles['progress-line']}'/>
      </div>`;
    }else if (app === 'customerarea') {
      return `  
      <div style='width: 100%;'>  
        <div class="modal-text-align-content">
          ${i18n.t('downloadindCustomerareaModal.text')}
        </div>
        <div class='${styles['progress-line']}'/>
      </div>`;
    }
    else {
      return '';
    }
  }

  renderDownloadModal() {
    const { lefebvre } = this.props;
    const content = this.getDownloadModal(lefebvre.userApp);
    if (lefebvre.guid && lefebvre.userApp && this.state.hideAlertDialog) {
      return (
        <Fragment>
          <DialogComponent
            id="info2Dialog"
            visible={this.state.hideAlertDialog}
            animationSettings={this.animationSettings}
            width='500px'
            content={content}
            ref={alertdialog => this.alertDialogInstance = alertdialog}
            isModal={true}
          />
        </Fragment>
      )
    } else return <></>
  }

  renderContent() {
    const { application, lefebvre } = this.props;
    const content = this.getDownloadModal(lefebvre.userApp);

    if (
      application.newMessage &&
      Object.keys(application.newMessage).length > 0
      && application.newMessage.sendingType == 'signature'
    ) {
      return <MessageEditor className={styles['message-viewer']} attachmentsDownloadError={this.state.attachmentsDownloadError} onShowError={this.resetDownloadError} />;
    } else if (
      application.newMessage &&
      Object.keys(application.newMessage).length > 0
      && application.newMessage.sendingType == 'emailCertificate'
    ) {
      return <EmailMessageEditor className={styles['message-viewer']} attachmentsDownloadError={this.state.attachmentsDownloadError} onShowError={this.resetDownloadError} />;
    } else if
      (
      application.newMessage &&
      Object.keys(application.newMessage).length > 0
      && application.newMessage.sendingType == 'smsCertificate'
    ) {
      return <SmsMessageEditor className={styles['message-viewer']} attachmentsDownloadError={this.state.attachmentsDownloadError} onShowError={this.resetDownloadError} />;
    } else if
      (
      application.newMessage &&
      Object.keys(application.newMessage).length > 0
      && application.newMessage.sendingType == 'documentCertificate'
    ) {
      return <DocumentMessageEditor className={styles['message-viewer']} attachmentsDownloadError={this.state.attachmentsDownloadError} onShowError={this.resetDownloadError} />;
    } else if (application.selectedSignature && Object.keys(application.selectedSignature).length > 0) {
      return <MessageViewer className={styles['message-viewer']} />;
    } else if (application.selectedEmail && Object.keys(application.selectedEmail).length > 0) {
      return <EmailMessageViewer className={styles['message-viewer']} />;
    } else if (application.selectedSms && Object.keys(application.selectedSms).length > 0) {
      return <SmsMessageViewer className={styles['message-viewer']} />;
    }

    return (
      <Fragment>
        <MessageList className={styles['message-grid']} guidNotFound={this.state.guidNotFound} onShowGuidNotFound={this.resetGuidNotFound} />
        <DialogComponent
          id="info2Dialog"
          //header=' ' 
          visible={this.state.hideAlertDialog}
          // visible={true} 
          animationSettings={this.animationSettings}
          width='500px'
          content={content}
          //content={(this.props.attachments.length === 0 ? noAttachModal : (this.state.bigAttachments ? bigFileModal : noSignersModal))}
          ref={alertdialog => this.alertDialogInstance = alertdialog}
          isModal={true}
        //target='#target' 
        //buttons={this.alertButtons} 
        // open={this.dialogOpen.bind(this)} 
        // close={this.dialogClose.bind(this)}
        // showCloseIcon={true}
        //position={ this.position }
        />
        {/* <DialogComponent
          id = "infoDialogGuidNotFound"
          visible = { this.state.showGuidNotFoundCenDialog }
          animationSettings={this.animationSettings} 
          width='500px'
          content={ content2 }
          ref = {alertdialog2 => this.alertDialogInstance2 = alertdialog2} 
          isModal = { true }
          close ={ this.dialogClose }
        /> */}
        {/*<div className={styles["fab-container"]}>
          {outbox === null ? (
            <button
              className={`${mainCss["mdc-fab"]} ${mainCss["mdc-button--raised"]}`}
            >
              <span className={`material-icons ${mainCss["mdc-fab__icon"]}`}>
                chat_bubble_outline
              </span>
            </button>
          ) : null}
        </div>*/}
      </Fragment>
    );
  }

  registerConnectorApp() {
    // let el = document.getElementById('main-lexon-connector')
    // if (!el) {
    //     try {
    //         const activityFunction = location => location.pathname.startsWith('/');
    //         registerApplication('lexon-app-dev', () => import('../lex-on_connector/index.js'), activityFunction);
    //         start();
    //         //registerLexonApp();
    //         registerMainnavApp();
    //         //singleSpa.start();
    //     }
    //     catch (error) {
    //         //console.error(error);
    //     }
    // }
  }

  openSignature(signature) {
    this.props.signatureClicked(signature);
    this.props.setGUID(null);
    return false;
  }

  openEmail(email) {
    this.props.emailClicked(email);
    this.props.setGUID(null);
    return false;
  }

  openSms(sms) {
    this.props.smsClicked(sms);
    this.props.setGUID(null);
    return false;
  }

  // async getLexonSmsContacts(){
  //   var {lefebvre} = this.props;
  //   var contactsInfo = [];

  //   var lexDataBases = await getBBDDLexon(lefebvre.userId, lefebvre.env);

  //   if (lexDataBases && lexDataBases.data !== null) {
  //     const numDatabases = lexDataBases.data.companies.length;
  //     const lexUserId = lexDataBases.data.idUser;
  //     var prs = [];
  //     lexDataBases.data.companies.forEach(company => {
  //       prs.push(getContactsLexon(lexUserId, company.bbdd, lefebvre.env))
  //     });

  //     await Promise.allSettled(prs)
  //       .then(results => {
  //         if (results.length === numDatabases){
  //           results.forEach(result => {
  //             lefebvre.mailContacts.forEach(contact => {
  //               var c = result.value.data.data.find( c => c.mobilePhone === contact);
  //               if (c !== undefined){
  //                 contactsInfo.push({address: `${c.phone}`, name: c.name, email: c.email, phone: `${c.phone}`})
  //               }
  //             })
  //           });

  //           if (contactsInfo.length === 0){
  //             contactsInfo = lefebvre.mailContacts;
  //           }
  //         }
  //       })
  //   }
  //   return contactsInfo;
  // }

  async componentDidMount() {

    document.title = 'Lefebvre Firma';
    var { mailContacts, adminContacts, userApp, cenContacts, lexContacts, cusContacts, targetService } = this.props.lefebvre;
    var self = this;
    let dataMailContacts = [];
    let dataAdminContacts = [];

    (mailContacts)
      ?
      mailContacts.map(address => {
        if (targetService === 'certifiedSms' && (userApp === 'centinela' || userApp === 'centinelaReport') && cenContacts && cenContacts.length > 0) {
          var contactInfo = cenContacts.filter(contact => contact.phone === address);
          return (contactInfo && contactInfo.length > 0)
            ? dataMailContacts.push({ address: address, name: contactInfo[0].name, email: contactInfo[0].email, phone: contactInfo[0].phone })
            : dataMailContacts.push({ address: address, name: '', email: '', phone: '' });
        } else if (targetService === 'certifiedSms' && (userApp === 'lexon') && lexContacts && lexContacts.length > 0) {
          var contactInfo = lexContacts.filter(contact => contact.phone === address);
          return (contactInfo && contactInfo.length > 0)
            ? dataMailContacts.push({ address: address, name: contactInfo[0].name, email: contactInfo[0].email, phone: contactInfo[0].phone })
            : dataMailContacts.push({ address: address, name: '', email: '', phone: '' });
        } else if (targetService === 'certifiedSms' && (userApp === 'customerarea') && cusContacts && cusContacts.length > 0) {
          var contactInfo = cusContacts.filter(contact => contact.phone === address);
          return (contactInfo && contactInfo.length > 0)
            ? dataMailContacts.push({ address: address, name: contactInfo[0].name, email: contactInfo[0].email, phone: contactInfo[0].phone })
            : dataMailContacts.push({ address: address, name: '', email: '', phone: '' });
        }
        else {
          return dataMailContacts.push({ address: address, name: '', email: '', phone: '' })
        }
      })
      : null;
    (adminContacts) ? adminContacts.map(address => { return dataAdminContacts.push({ address: address, name: '', email: '', phone: '' }) }) : null;

    //Starting poll to update the inbox automatically
    //this.startPoll();
    this.refreshPollTimeout = setInterval(this.startPoll.bind(this), window.POLLING_INTERVAL);
    //this.startPoll.bind(this);
    //adding connector App to right slide panel
    //setTimeout(function () { this.registerConnectorApp(); }, 2200);
    this.registerConnectorApp();

    const { lefebvre } = this.props;

    if (lefebvre.targetService === 'signature') {
      this.props.setSelectedService('signature');
      this.props.setAppTitle(i18n.t('topBar.app'));

      if (lefebvre.guid !== null) {
        searchSignatures('ES', lefebvre.userId, this.props.auth, 'lefebvre_guid', lefebvre.guid, 0)
          .then(signature => {
            if (signature.length === 0) {
              this.props.setTitle(i18n.t('messageEditor.title'));
              this.getFilesByUserApp(lefebvre.userApp)
                .then(files => {
                  if (files === null || files === undefined) {
                    this.setState({ hideAlertDialog: false, attachmentsDownloadError: true })
                    this.props.setUserApp('lefebvre');
                    this.props.newMessage('signature', dataMailContacts, dataAdminContacts);
                  } else {
                    this.setState({ hideAlertDialog: false });
                    this.props.backendRequestCompleted();
                    this.props.setIdDocuments(files.documentsInfo);
                    this.props.newMessage('signature', dataMailContacts, dataAdminContacts, null, files.attachmentsList);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({ hideAlertDialog: false });
                  this.props.backendRequestCompleted();
                  this.props.newMessage('signature', dataMailContacts, dataAdminContacts);
                });
              
            } else {
              this.props.setUserApp('lefebvre');
              this.props.setTitle(i18n.t('signatureViewer.title'));
              this.openSignature(signature[0]);
            }
          })
          .catch(err => {
            console.log(err);
            this.props.setUserApp('lefebvre');
            this.props.setTitle(i18n.t('signatureViewer.title'));
          })
      }

      this.props.preloadFirstSignatures(lefebvre.userId)
        .catch(err => { throw new Error(err); });

      this.props.preloadSignatures(lefebvre.userId)
        .catch(err => { throw new Error(err); });

      (lefebvre.roles.some(r => r === "Email Certificado")) ? this.props.preloadEmails(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "SMS Certificado")) ? this.props.preloadSms(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "Documentos Certificados")) ? this.props.preloadCertifiedDocuments(lefebvre.userId) : null;
    } else if (lefebvre.targetService === "certifiedEmail") {
      this.props.setSelectedService('certifiedEmail');
      this.props.setAppTitle(i18n.t('topBar.certifiedEmail'));

      if (lefebvre.guid !== null) {
        searchEmails('ES', lefebvre.userId, this.props.auth, 'lefebvre_guid', lefebvre.guid, 0)
          .then(email => {
            if (email.length === 0) {
              this.props.setTitle(i18n.t('messageEditor.certifiedEmailTitle'));
              this.getFilesByUserApp(lefebvre.userApp)
                .then(files => {
                  if (files === null || files === undefined) {
                    this.setState({ hideAlertDialog: false, attachmentsDownloadError: true })
                    this.props.setUserApp('lefebvre');
                    this.props.newMessage('emailCertificate', dataMailContacts, dataAdminContacts);
                  } else {
                    files.attachmentsList = this.addOriginDocument(files.attachmentsList,lefebvre.userApp);
                    this.setState({ hideAlertDialog: false });
                    this.props.backendRequestCompleted();
                    this.props.setIdDocuments(files.documentsInfo);
                    this.props.newMessage('emailCertificate', dataMailContacts, dataAdminContacts, null, files.attachmentsList);
                  }
                })
                .catch(() => {
                  console.log(err);
                  this.setState({ hideAlertDialog: false });
                  this.props.backendRequestCompleted();
                  this.props.newMessage('emailCertificate', dataMailContacts, dataAdminContacts);
                });
              
            } else {
              this.props.setUserApp('lefebvre');
              this.props.setTitle(i18n.t('emailViewer.title'));
              this.openEmail(email[0]);
            }
          })
          .catch(err => {
            console.log(err);
            this.props.setUserApp('lefebvre');
          })
      }

      this.props.preloadEmails(lefebvre.userId)
        .catch(err => { throw new Error(err); });

      (lefebvre.roles.some(r => r === "Firma Digital")) ? this.props.preloadSignatures(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "SMS Certificado")) ? this.props.preloadSms(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "Documentos Certificados")) ? this.props.preloadCertifiedDocuments(lefebvre.userId) : null;
    } else if (lefebvre.targetService === "certifiedSms") {
      var lexContacts = [];
      this.props.setSelectedService('certifiedSms');
      this.props.setTitle(i18n.t('messageEditor.certifiedSmsTitle'));
      this.props.setAppTitle(i18n.t('topBar.certifiedSms'));
      // if (lefebvre.userApp === "lexon"){
      //   lexContacts = await this.getLexonSmsContacts();
      // }
      if (lefebvre.guid !== null) {
        searchSms('ES', lefebvre.userId, this.props.auth, 'lefebvre_guid', lefebvre.guid, 0)
          .then(sms => {
            if (sms.length === 0) {
              if ((lefebvre.userApp === "lex" || lefebvre.userApp === "lexon" || lefebvre.userApp === "1")) { // Hay que recuperar un adjunto de lexon
                if (lefebvre.idDocuments && lefebvre.idDocuments.length > 0) {
                  this.getFilesFromLexon()
                    .then(files => {
                      if (files === null || files === undefined) {
                        this.props.setUserApp('lefebvre');
                        this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts);
                      } else {
                        // if (lexContacts.length === 0){
                        //   lexContacts = dataMailContacts;
                        // }

                        // this.setState({hideAlertDialog: false});
                        // this.props.backendRequestCompleted();
                        // this.props.setIdDocuments(files.documentsInfo);
                        // this.props.newMessage('smsCertificate', lexContacts, dataAdminContacts, null, files.attachmentsList);

                        var contactsInfo = [];
                        getBBDDLexon(lefebvre.userId, lefebvre.idClienteLef, lefebvre.env)
                          .then(lexDataBases => {
                            if (lexDataBases && lexDataBases.data !== null) {
                              const numDatabases = lexDataBases.data.companies.length;
                              const lexUserId = lexDataBases.data.idUser;
                              var counter = 0;
                              var prs = [];
                              lexDataBases.data.companies.forEach(company => {
                                prs.push(getContactsLexon(lexUserId, company.idCompany, company.bbdd, lefebvre.env))
                              });

                              Promise.allSettled(prs)
                                .then(results => {
                                  if (results.length === numDatabases) {
                                    results.forEach(result => {
                                      dataMailContacts.forEach(contact => {
                                        var c = result.value.data.data.find(c => c.mobilePhone === contact.address);
                                        if (c !== undefined) {
                                          if (contactsInfo.find(p => p.address === c.mobilePhone) === undefined) {
                                            contactsInfo.push({ address: `${c.mobilePhone}`, name: c.name, email: c.email, phone: `${c.mobilePhone}` });
                                          }
                                        }
                                      })
                                    });

                                    if (contactsInfo.length === 0) {
                                      contactsInfo = dataMailContacts;
                                    } else if (contactsInfo.length < dataMailContacts.length) {
                                      dataMailContacts.forEach(contactMissing => {
                                        var c = contactsInfo.find(c => c.address === contactMissing.address);
                                        if (c === undefined) {
                                          contactsInfo.push({ address: `${contactMissing.address}`, name: '', email: '', phone: `${contactMissing.address}` });

                                        }
                                      })

                                    }
                                    files.attachmentsList = this.addOriginDocument(files.attachmentsList,lefebvre.userApp);
                                    this.setState({ hideAlertDialog: false });
                                    this.props.backendRequestCompleted();
                                    this.props.setIdDocuments(files.documentsInfo);
                                    this.props.newMessage('smsCertificate', contactsInfo, dataAdminContacts, null, files.attachmentsList);

                                  }
                                })
                                .catch(err => {
                                  console.log(err);
                                  this.setState({ hideAlertDialog: false });
                                  this.props.backendRequestCompleted();
                                  this.props.setIdDocuments(files.documentsInfo);
                                  this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts, null, files.attachmentsList);
                                })
                            } else {
                              this.setState({ hideAlertDialog: false });
                              this.props.backendRequestCompleted();
                              this.props.setIdDocuments(files.documentsInfo);
                              this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts, null, files.attachmentsList);
                            }
                            //const contactsLexon = await getContactsLexon(user, bbdd, env);
                          })
                          .catch(err => {
                            console.log(err);
                            this.setState({ hideAlertDialog: false });
                            this.props.backendRequestCompleted();
                            this.props.setIdDocuments(files.documentsInfo);
                            this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts, null, files.attachmentsList);
                          });
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      this.setState({ hideAlertDialog: false });
                      this.props.backendRequestCompleted();
                      this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts);
                    });
                }
              } else if ((lefebvre.userApp === "cen" || lefebvre.userApp === "centinela" || lefebvre.userApp === "2" || lefebvre.userApp === "centinelaReport" || lefebvre.userApp === "4")) {
                if (lefebvre.idDocuments && lefebvre.idDocuments.length > 0) {
                  this.getFilesFromCentinela()
                    .then(files => {
                      if (files === null || files === undefined) {
                        this.props.setUserApp('lefebvre');
                        this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts);
                      }
                      else {
                        getContactsCentinelaSMS(lefebvre.userId)
                          .then(contacts => {
                            var contactsInfo = []
                            mailContacts.forEach(phone => {
                              var contact = contacts.data.filter(c => c.phoneNumber1 === phone || c.phoneNumber2 === phone);
                              if (contact.length > 0) {
                                contactsInfo.push({ address: `${phone}`, name: contact[0].fullName, email: contact[0].email, phone: `${phone}` })
                              }
                            });
                            if (contactsInfo.length > 0) {
                              this.props.setCenContacts(contactsInfo);
                            }
                            files.attachmentsList = this.addOriginDocument(files.attachmentsList,lefebvre.userApp);
                            this.setState({ hideAlertDialog: false });
                            this.props.backendRequestCompleted();
                            this.props.setIdDocuments(files.documentsInfo);
                            this.props.newMessage('smsCertificate', contactsInfo, dataAdminContacts, null, files.attachmentsList);
                          })
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      this.setState({ hideAlertDialog: false });
                      this.props.backendRequestCompleted();
                      this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts);
                    })
                }
                else {
                  // Received guid is of a document that is in signing process in centinela's backend but we don´t have it
                  // Show modal
                  // Send Centinela a Cancelation Request for that guid.
                  this.setState({ guidNotFound: true })
                }
              }else if ((lefebvre.userApp === "customerarea" || lefebvre.userApp === "9")) {
                if (lefebvre.idDocuments && lefebvre.idDocuments.length > 0) {
                  this.getFilesFromCustomerarea()
                    .then(files => {
                      if (files === null || files === undefined) {
                        this.props.setUserApp('lefebvre');
                        this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts);
                      }
                      else {
                        getContactsCustomerarea(lefebvre.idClienteLef, lefebvre.idCliente, lefebvre.token)
                          .then(contacts => {
                            var contactsInfo = []
                            mailContacts.forEach(phone => {
                              var contact = contacts.data.contacts.filter(c => c.mobilePhone === phone || c.mobilePhone === phone);
                              if (contact.length > 0) {
                                contactsInfo.push({ address: `${phone}`, name: contact[0].fullName, email: contact[0].email, phone: `${phone}` })
                              }
                            });
                            if (contactsInfo.length > 0) {
                              this.props.setCenContacts(contactsInfo);
                            }
                            files.attachmentsList = this.addOriginDocument(files.attachmentsList,lefebvre.userApp);
                            this.setState({ hideAlertDialog: false });
                            this.props.backendRequestCompleted();
                            this.props.setIdDocuments(files.documentsInfo);
                            this.props.newMessage('smsCertificate', contactsInfo, dataAdminContacts, null, files.attachmentsList);
                          })
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      this.setState({ hideAlertDialog: false });
                      this.props.backendRequestCompleted();
                      this.props.newMessage('smsCertificate', dataMailContacts, dataAdminContacts);
                    })
                }
                else {
                  this.setState({ guidNotFound: true })
                }
              }
            } else {
              this.props.setUserApp('lefebvre');
              this.props.setTitle(i18n.t('smsViewer.title'));
              this.openSms(sms[0]);
            }
          })
          .catch(err => {
            console.log(err);
            this.props.setUserApp('lefebvre');

          })
      }

      this.props.preloadSms(lefebvre.userId)
        .catch(err => { throw new Error(err); });

      (lefebvre.roles.some(r => r === "Firma Digital")) ? this.props.preloadSignatures(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "Email Certificado")) ? this.props.preloadEmails(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "Documentos Certificados")) ? this.props.preloadCertifiedDocuments(lefebvre.userId) : null;
    } else if (lefebvre.targetService === "certifiedDocument") {
      this.props.setSelectedService('certifiedDocument');
      this.props.setTitle(i18n.t('messageEditor.certifiedDocumentTitle'));
      this.props.setAppTitle(i18n.t('topBar.certifiedDocument'));
      this.props.preloadCertifiedDocuments(lefebvre.userId)
        .catch(err => { throw new Error(err); });
      (lefebvre.roles.some(r => r === "Firma Digital")) ? this.props.preloadSignatures(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "Email Certificado")) ? this.props.preloadEmails(lefebvre.userId) : null;
      (lefebvre.roles.some(r => r === "SMS Certificado")) ? this.props.preloadSms(lefebvre.userId) : null;
    }
  }

  addOriginDocument(attachments, userApp){
    attachments.forEach(doc => {
      doc['origin'] = userApp;
     });
     return attachments;
  }

  getFilesFromLexon() {
    return new Promise((resolve, reject) => {
      const { lefebvre } = this.props;

      let documentsInfo = [];
      let attachmentsList = [];
      let i = 0;
      let error = 0;

      if (lefebvre.idDocuments.length > 0) {
        this.props.backendRequest();
        this.setState({ hideAlertDialog: true });
      }

      lefebvre.idDocuments.forEach(document => {
        this.props.getAttachmentLex(lefebvre.idUserApp, document.docId, lefebvre.companyId)
          .then((attachment) => {
            if (attachment.data === null) { //El fichero no existe o no se ha podido recuperar
              error += 1;
              // cancelSignatureLex(document.docId)
              // .then(res => {
              // })
              // .catch(err => {
              //   console.log(err);
              // });
            }
            else {
              const newAttachment = {
                fileName: attachment.data.fileName,
                size: attachment.data.size,
                contentType: attachment.data.contentType,
                content: attachment.data.content
              }

              if (attachment.data.contentType.toUpperCase() === 'APPLICATION/PDF') {
                const pdfjsLib = require('pdfjs-dist');
                pdfjsLib.GlobalWorkerOptions.workerSrc = '../../assets/scripts/pdf.worker.js'

                pdfjsLib.getDocument({ data: atob(attachment.data.content) })
                  .promise.then(doc => {
                    var numPages = doc.numPages;
                    newAttachment.pages = numPages;
                  });
              }

              attachmentsList.push(newAttachment);
              documentsInfo.push({ docId: document.docId, docName: attachment.data.fileName });
            }
            i += 1;

            if (i > 0 && (lefebvre.idDocuments.length === attachmentsList.length + error)) {
              if (error > 0) {
                this.setState({ hideAlertDialog: false, attachmentsDownloadError: true })
              }
              resolve({ attachmentsList, documentsInfo });
            }
          })
          .catch(err => {
            reject(err);
          });
      });


    })

  }

  getFilesByUserApp(userApp){
    if ((userApp === "lex" || userApp === "lexon" || userApp === "1")){
      return this.getFilesFromLexon();
    }
    else if((userApp === "cen" || userApp === "centinela" || userApp === "2" || userApp === "centinelaReport" || userApp === "4")){
      return this.getFilesFromCentinela()
    }
    else if(userApp === "customerarea" || userApp === "9"){
      return this.getFilesFromCustomerarea();
    }
  }
  getFilesFromCentinela() {
    return new Promise((resolve, reject) => {
      const { lefebvre } = this.props;
      const userApp = lefebvre.userApp;

      let documentsInfo = [];
      let attachmentsList = [];
      let i = 0;
      let error = 0;

      if (lefebvre.idDocuments.length > 0) {
        this.props.backendRequest();
        this.setState({ hideAlertDialog: true });
      }

      lefebvre.idDocuments.forEach(document => {
        this.props.getAttachmentCen(lefebvre.userId, document.docId, userApp)
          .then((attachment) => {
            if (attachment.data === null) { //El fichero no existe o no se ha podido recuperar
              error += 1;
              cancelSignatureCen(document.docId)
                .then(res => {
                })
                .catch(err => {
                  console.log(err);
                });
            }
            else {
              const length = attachment.data.length;
              const fileName = (attachment.infos[0].message.includes("- id:")) ? attachment.infos[0].message.split("fichero:")[1].split("- id:")[0].replace(/"/g, '').trim() : attachment.infos[0].message.split("fichero:")[1].replace(/"/g, '').trim();
              const fileType = fileName.split('.');
              const newAttachment = {
                fileName: fileName,
                size: length,
                contentType: getFileType(fileName),
                content: attachment.data
              }

              if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
                const pdfjsLib = require('pdfjs-dist');
                pdfjsLib.GlobalWorkerOptions.workerSrc = '../../assets/scripts/pdf.worker.js'

                pdfjsLib.getDocument({ data: atob(attachment.data) })
                  .promise.then(doc => {
                    var numPages = doc.numPages;
                    newAttachment.pages = numPages;
                  });
              }

              attachmentsList.push(newAttachment);
              documentsInfo.push({ docId: document.docId, docName: fileName });
            }
            i += 1;

            if (i > 0 && (lefebvre.idDocuments.length === attachmentsList.length + error)) {
              if (error > 0) {
                this.setState({ hideAlertDialog: false, attachmentsDownloadError: true })
              }
              resolve({ attachmentsList, documentsInfo });
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  }

  getFilesFromCustomerarea() {
    return new Promise((resolve, reject) => {
      const { lefebvre } = this.props;
      const userApp = lefebvre.userApp;

      let documentsInfo = [];
      let attachmentsList = [];
      let i = 0;
      let error = 0;

      if (lefebvre.idDocuments.length > 0) {
        this.props.backendRequest();
        this.setState({ hideAlertDialog: true });
      }

      lefebvre.idDocuments.forEach(document => {
        this.props.getAttachmentCus(lefebvre.guid, lefebvre.token)
          .then((attachment) => {
            if (attachment.data === null) { //El fichero no existe o no se ha podido recuperar
              error += 1;
              cancelSignatureCus(lefebvre.guid, lefebvre.token)
                .then(res => {
                })
                .catch(err => {
                  console.log(err);
                });
            }
            else {
              const length = attachment.data.size;
              const fileName = attachment.data.fileName;
              const fileType = fileName.split('.');
              const newAttachment = {
                fileName: fileName,
                size: length,
                contentType: getFileType(fileName),
                content: attachment.data.content
              }

              if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
                const pdfjsLib = require('pdfjs-dist');
                pdfjsLib.GlobalWorkerOptions.workerSrc = '../../assets/scripts/pdf.worker.js'

                pdfjsLib.getDocument({ data: atob(newAttachment.content) })
                  .promise.then(doc => {
                    var numPages = doc.numPages;
                    newAttachment.pages = numPages;
                  });
              }

              attachmentsList.push(newAttachment);
              documentsInfo.push({ docId: document.docId, docName: fileName });
            }
            i += 1;

            if (i > 0 && (lefebvre.idDocuments.length === attachmentsList.length + error)) {
              if (error > 0) {
                this.setState({ hideAlertDialog: false, attachmentsDownloadError: true })
              }
              resolve({ attachmentsList, documentsInfo });
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    });
  }



  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  componentWillUnmount() {
    clearInterval(this.refreshPollTimeout);
    this.activeCallSignature = false;
    this.activeCallEmail = false;
    this.activeCallSms = false;

    window.removeEventListener(
      'GetUserFromLexonConnector',
      this.handleGetUserFromLexonConnector
    );
  }

  startPoll() {
    const { lefebvre, application } = this.props;
    if ((application.selectedSignature === null || (application.selectedSignature.constructor === Object && Object.entries(application.selectedSignature).length === 0))
      && (application.selectedEmail === null || (application.selectedEmail.constructor === Object && Object.entries(application.selectedEmail).length === 0))
      && (application.selectedSms === null || (application.selectedSms.constructor === Object && Object.entries(application.selectedSms).length === 0))) {

      if (application.selectedService === 'signature') {
        if (!this.activeCallSignature){
          this.activeCallSignature = true;
          this.props.preloadSignatures(lefebvre.userId)
          .then(res => {this.activeCallSignature = false;})
          .catch(err => {
            this.activeCallSignature = false;
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }
          });
        }
      } else if (application.selectedService === 'certifiedEmail') {
        if (!this.activeCallEmail){
          this.activeCallEmail = true;
          this.props.preloadEmails(lefebvre.userId)
          .then(res => {this.activeCallEmail = false;})
          .catch(err => {
            this.activeCallEmail = false;
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }
          });
        }
      } else if (application.selectedService === 'certifiedSms') {
        if (!this.activeCallSms){
          this.activeCallSms = true;
          this.props.preloadSms(lefebvre.userId)
          .then(res => {this.activeCallSms = false;})
          .catch(err => {
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }
        
      } else if (application.selectedService === 'certifiedDocuments') {
        this.props.preloadCertifiedDocuments(lefebvre.userId);
      } else {
        if (!this.activeCallSignature){
          this.activeCallSignature = true;
          this.props.preloadSignatures(lefebvre.userId)
          .then(res => {this.activeCallSignature = false})
          .catch(err => {
            this.activeCallSignature = false
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }
        
        if (!this.activeCallEmail){
          this.activeCallEmail = true;
          this.props.preloadEmails(lefebvre.userId)
          .then(res => {this.activeCallEmail = false})
          .catch(err => {
            this.activeCallEmail = false;
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }
        

        if (!this.activeCallSms){
          this.activeCallSms = true;
          this.props.preloadSms(lefebvre.userId)
          .then(res => {this.activeCallSms = false})
          .catch(err => {
            this.activeCallSms = false;
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }
        

        this.props.preloadCertifiedDocuments(lefebvre.userId);
      }
      //this.props.backendRequestCompleted();
    } else {
      if (application.selectedService === 'signature') {
        if (!this.activeCallSignature){
          this.activeCallSignature = true;
          this.props.preloadSignatures(lefebvre.userId)
          .then(res => {this.activeCallSignature = false})
          .catch(err => {
            this.activeCallSignature = false;
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }
        
        let signature = application.signatures.find(s => s.id === application.selectedSignature.id)
        if (signature != undefined) {
          this.props.signatureClicked(signature);
        }
      } else if (application.selectedService === 'certifiedEmail') {
        if (!this.activeCallEmail){
          this.activeCallEmail = true;
          this.props.preloadEmails(lefebvre.userId)
          .then(res => {this.activeCallEmail = false})
          .catch(err => {
            this.activeCallEmail = false;
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }

        let email = application.emails.find(s => s.id === application.selectedEmail.id)
        if (email != undefined) {
          this.props.emailClicked(email);
        }
      } else if (application.selectedService === 'certifiedSms') {
        if (!this.activeCallSms){
          this.activeCallSms = true;
          this.props.preloadSms(lefebvre.userId)
          .then(res => {this.activeCallSms = false})
          .catch(err => {
            this.activeCallSms = false
            if (err && err === 'Access token expired') {
              this.routeLogout();
              this.props.history.push(`/expired`);
            }
            else if (err && (err === 'Access token invalid' || err === 'Access token not found' || err === 'Bad user' || err === 'User missing')) {
              this.routeLogout();
              this.props.history.push('/unauthorized');
            }

          });
        }
        

        let sms = application.smsList.find(s => s.id === application.selectedSms.id);
        if (sms != undefined) {
          this.props.smsClicked(sms);
        }
      } else if (application.selectedService === 'certifiedDocuments') {
        this.props.preloadCertifiedDocuments(lefebvre.userId);
      }
    }
  }


  toggleSideBar() {
    const toggleCollapsed = !this.state.sideBar.collapsed;
    this.setState({
      sideBar: {
        collapsed: toggleCollapsed
      }
    });
  }
}

App.propTypes = {
  application: PropTypes.object,
  outbox: PropTypes.object,
  folders: PropTypes.object,
  loadMessageByFolder: PropTypes.func,
  newMessage: PropTypes.func.isRequired //,
  // resetIdEmail: PropTypes.func
};

const mapStateToProps = state => ({
  application: state.application,
  auth: state.application.user.credentials.encrypted,
  outbox: state.application.outbox,
  folders: state.folders,
  messages: state.messages,
  lefebvre: state.lefebvre,
  email: state.login.formValues.user,
  all: state
});

const mapDispatchToProps = dispatch => ({
  newMessage: (sendingType, to, cc, sign, attachments) => editNewMessage(dispatch, sendingType, to, cc, sign, attachments),
  selectFolder: (folder, user) => {
    dispatch(selectFolder(folder));
    clearSelectedMessage(dispatch);
    dispatch(clearSelected());
  },
  messageClicked: (credentials, downloadedMessages, folder, message) => {
    dispatch(selectMessage(message))
  },
  // setEmailShown: flag => dispatch(setEmailShown(flag)),
  outboxEventNotified: () => dispatch(outboxEventNotified()),
  logout: () => {
    dispatch(clearUserCredentials());
    history.push('/login');
  },
  close: application => {
    dispatch(editMessage(null));
    //persistApplicationNewMessageContent(application, "");
  },
  setError: (err, msg) => dispatch(setError(err, msg)),
  // resetIdEmail: () => dispatch(resetIdEmail()),
  // setCaseFile: casefile => dispatch(setCaseFile(casefile)),
  // setSelected: (messages, selected, shiftKey) =>
  //   dispatch(setSelected(messages, selected, shiftKey)),
  setGUID: guid => dispatch(setGUID(guid)),
  setSign: sign => dispatch(setSign(sign)),
  setUserApp: app => dispatch(setUserApp(app)),
  preloadFirstSignatures: (client, userId, auth) => preloadFirstSignatures(dispatch, client, userId, auth),
  preloadSignatures: (client, userId, auth) => preloadSignatures2(dispatch, client, userId, auth),
  preloadEmails: (client, userId, auth) => preloadEmails(dispatch, client, userId, auth),
  preloadSms: (client, userId, auth) => preloadSms(dispatch, client, userId, auth),
  preloadCertifiedDocuments: (userId, auth) => preloadCertifiedDocuments(dispatch, userId, auth),
  signatureClicked: signature => dispatch(selectSignature(signature)),
  emailClicked: email => dispatch(selectEmail(email)),
  smsClicked: sms => dispatch(selectSms(sms)),
  getAttachmentLex: (userId, documentId, companyId, auth, env) => getAttachmentLex(userId, documentId, companyId, auth, env),
  getAttachmentCen: (userId, documentId, userApp) => getAttachmentCen(userId, documentId, userApp),
  getAttachmentCus: (guid, auth) => getAttachmentCus(guid, auth),
  setIdDocuments: ids => dispatch(setIdDocuments(ids)),
  backendRequest: () => dispatch(backendRequest()),
  backendRequestCompleted: () => dispatch(backendRequestCompleted()),
  setSelectedService: service => dispatch(setSelectedService(service)),
  setAppTitle: title => dispatch(setAppTitle(title)),
  setTitle: title => dispatch(setTitle(title)),
  setCenContacts: contacts => dispatch(setCenContacts(contacts))
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    selectFolder: folder =>
      dispatchProps.selectFolder(folder, stateProps.application.user),
    messageClicked: message =>
      dispatchProps.messageClicked(
        stateProps.application.user.credentials,
        stateProps.application.downloadedMessages,
        stateProps.receivedFolder,
        message
      ),
    // setEmailShown: flag => dispatchProps.setEmailShown(flag),
    outboxEventNotified: () => dispatchProps.outboxEventNotified(),
    close: application => dispatchProps.close(stateProps.application),
    setError: (err, msg) => dispatchProps.setError(err, msg),
    // resetIdEmail: () => dispatchProps.resetIdEmail(),
    // setCaseFile: casefile => dispatchProps.setCaseFile(casefile),
    preloadSignatures: (userId) => dispatchProps.preloadSignatures(stateProps.application.country, userId, stateProps.application.user.credentials.encrypted),
    preloadFirstSignatures: (userId) => dispatchProps.preloadFirstSignatures(stateProps.application.country, userId, stateProps.application.user.credentials.encrypted),
    preloadEmails: (userId) => dispatchProps.preloadEmails(stateProps.application.country, userId, stateProps.application.user.credentials.encrypted),
    preloadSms: (userId) => dispatchProps.preloadSms(stateProps.application.country, userId, stateProps.application.user.credentials.encrypted),
    preloadCertifiedDocuments: (userId) => dispatchProps.preloadCertifiedDocuments(userId, stateProps.application.user.credentials.encrypted),
    signatureClicked: signature => dispatchProps.signatureClicked(signature),
    emailClicked: email => dispatchProps.emailClicked(email),
    smsClicked: sms => dispatchProps.smsClicked(sms),
    getAttachmentLex: (userId, documentId, companyId) => dispatchProps.getAttachmentLex(userId, documentId, companyId, stateProps.application.user.credentials.encrypted, stateProps.lefebvre.env),
    getAttachmentCen: (userId, documentId, userApp) => dispatchProps.getAttachmentCen(userId, documentId, userApp),
    getAttachmentCus: (guid, auth) => dispatchProps.getAttachmentCus(guid, auth),
    setIdDocuments: ids => dispatchProps.setIdDocuments(ids),
    backendRequest: () => dispatchProps.backendRequest(),
    backendRequestCompleted: () => dispatchProps.backendRequestCompleted(),
    setSelectedService: service => dispatchProps.setSelectedService(service),
    setAppTitle: title => dispatchProps.setAppTitle(title),
    setTitle: title => dispatchProps.setTitle(title),
    setCenContacts: contacts => dispatchProps.setCenContacts(contacts)
  });


export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate()(App));
