import React from 'react';
import MenuList from './menu-list';
import MenuListFR from './menu-listFR';
let clickEle;
export const MenuContainer = (props) => {
  const { collapsed } = props;
  if (props.country === 'FR') {
    return (
      <MenuListFR collapsed={collapsed}  data={clickEle}/> 
    )
  }
  return (
    <MenuList collapsed={collapsed}  data={clickEle}/> 
  );
};

export default (MenuContainer);