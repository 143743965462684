import React, { PureComponent } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import stylesES from './header.scss';
import stylesFR from './headerFR.scss';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      styles: null
    }
    switch (this.props.country) {
      case "FR":
        this.state.styles = stylesFR;
        break;
      case "ES":
        this.state.styles = stylesES;
      default:
        break;
    }
  }
  
  render() {
    const { userName, showUser = true, country } = this.props;
    const {styles} = this.state;

    return (
      <div>
      <header className={styles['login-header']}>
        <div className={`${styles['front-login__header']} row`}>
          {
            (country === 'FR') 
              ?
                <a
                  className={`${styles['front-login__go-home']} col-md-5`}
                  href='https://www.lefebvre-dalloz.fr'
                  rel='noopener noreferrer'
                  target='_blank'>
                  <img
                    src='/assets/images/lefebvre-dalloz(noir).svg'
                    alt='LEFEBVRE'
                    className={`${styles['front-login__logo-lefebvre']}`}
                  />
                </a>
              :
                <a
                  className={`${styles['front-login__go-home']} col-md-5`}
                  href='https://lefebvre.es'
                  rel='noopener noreferrer'
                  target='_blank'>
                  <img
                    src='https://assets.lefebvre.es/media/logos/web/comunes/lefebvre-bl-120x24.png'
                    alt='LEFEBVRE'
                    className='front-login__logo-lefebvre'
                  />
                </a>
          }
          {showUser && (
            <div className={styles['front-login__go-shop']}>
              <span className='lf-icon-user'></span>
              <span className={styles['user-name']}>{userName}</span>
            </div>
          )}
          {!showUser && country === 'ES' && (
            <a
              className={styles['front-login__go-shop']}
              rel='noopener noreferrer'
              href='https://www.efl.es/'
              target='_blank'>
              <i className={`lf-icon-shop ${styles['front-login__go-shop-icon']}`}></i>
              <span data-translate='front-login.shop' className='ng-scope'>
                TIENDA
              </span>
            </a>
          )}
        </div>
      </header>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  country: state.application.country
});

export default connect(mapStateToProps, null)(Header);