import React, { Component } from "react";
import { connect } from 'react-redux';
import { Router, Route, Switch, BrowserRouter } from "react-router-dom";
import history from "./history";
import PrivateRoute from "./private-route";
import App from "../components/app";
import AppFR from "../components/appFR";

import UserLefebvre from "../components/user-lefebvre/UserLefebvre";
import Login from "../components/login2/Login";
import Settings from "../components/settings/settings";
//import "../styles/main.scss";
import Unauthorized from "../components/unauthorized/Unauthorized";
import Expired from "../components/expired/Expired";
import Completed from "../components/completed/Completed";
/*FRANCE*/
import UserLefebvreFR from "../components/user-lefebvre/UserLefebvreFR";
import UnauthorizedFR from "../components/unauthorized/UnauthorizedFR";
import ExpiredFR from "../components/expired/ExpiredFR";
import CompletedFR from "../components/completed/CompletedFR";

import { Suspense } from "react";
import { setCountry } from "../actions/application";
import { link } from "fs";

class Routes extends Component {
  constructor(props) {
    super(props);
    const favicons_ES = [{size: '32x32', href: '/assets/favicons/favicon-32x32.png'}, {size: '96x96', href: '/assets/favicons/favicon-96x96.png'}, {size: '16x16', href: '/assets/favicons/favicon-16x16.png'}];
    const favicons_FR = [{size: '96x96', href: 'https://www.lefebvre-dalloz.fr/wp-content/uploads/2021/03/favicon-1.png'}, {size: '192x192', href: 'https://www.lefebvre-dalloz.fr/wp-content/uploads/2021/03/favicon-1.png'}];
    let favicons = [];
    switch (window.location.host) {
      case window.HOST_ES_INT:
      case window.HOST_ES_EXT:
        props.setCountry('ES');
        favicons = favicons_ES;
        break;
      case window.HOST_FR_INT:
      case window.HOST_FR_EXT:
        props.setCountry('FR');
        favicons = favicons_FR;
        break;
      default:
        props.setCountry('ES');
        favicons = favicons_ES;
        break;
    }
    // switch (window.location.pathname) {
    //     case "/login-fr":
    //         console.log('FR');
    //         props.setCountry('FR');
    //         favicons = favicons_FR;
    //         break;
    //     default:
    //         props.setCountry('ES');
    //         favicons = favicons_ES;
    //         break;
    // }
    favicons.forEach(icon => {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.type = 'image/png';
      link.sizes = icon.size;
      link.href = icon.href;
      document.getElementsByTagName('head')[0].appendChild(link);
    });
  }

  render() {
    const {country} = this.props;
    return (
      <Router basename="/" history={history}>
        {/* <Suspense fallback={<div>Page is Loading...</div>}> */}
        <Switch>
          <Route exact path="/access/:token" component={UserLefebvre} />
          <Route exact path="/access/:token/fr" component={UserLefebvreFR} />
          {/* <Route exact path="/login" render={() => <Login />} /> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route exact path="/expired" component={Expired} />
          <Route exact path="/completed" component={Completed} />
          <Route exact path="/expired-fr" component={ExpiredFR} />
          <Route exact path="/completed-fr" component={CompletedFR} />
          <Route exact path="/unauthorized-fr" component={UnauthorizedFR} />
          {
            country === 'FR'
              ? <PrivateRoute exact path="/" component={AppFR} />
              : <PrivateRoute exact path="/" component={App} />
          }
          <PrivateRoute exact path="/settings" component={Settings} />
        </Switch>
        {/* </Suspense> */}
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  country: state.application.country
});

const mapDispatchToProps = dispatch => ({
  setCountry: country => dispatch(setCountry(country))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
