import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import MenuContainer from '../folders/menu-container';
import { DroppablePayloadTypes } from '../folders/menu-list';
import IconButton from '../buttons/icon-button';
import mainCss from '../../styles/mainFR.scss';
import styles from './side-barFR.scss';
import { editNewMessage } from '../../services/application';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { setAvailableSignatures, setNumAvailableSignatures, setUserApp } from '../../actions/lefebvre';
import { setTitle, setAppTitle } from '../../actions/application';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import SendingTypeSelector from './sending-type-selector/sending-type-selector';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dragOver: false,
      hideSendingTypeDialog: false,
      hideConfirmDialog: false
    };
    this.handleOnNewSending = this.onNewSending.bind(this);    
    //Sin firmas 
    this.animationSettings = { effect: 'None' };
  }


  sendTypeDialogClose() {
    this.setState({
      hideSendingTypeDialog: false
  });
  }

  dialogOpen() {
    //this.alertButtonEle.style.display = 'none';
  }

  onFocus(args) {
      this.spanEle.classList.add('e-input-focus');
  }

  onBlur(args) {
      this.spanEle.classList.remove('e-input-focus');
  }


  render() {

    const { t, collapsed, lefebvre } = this.props;
    const { dragOver } = this.state;

    return (
      <aside
        onDragOver={this.handleOnDragOver}
        onDragLeave={this.handleOnDragLeave}
        onDrop={this.handleOnDrop}
        className={`${styles['side-bar']}
          ${mainCss['mdc-drawer']}
          ${mainCss['mdc-drawer--dismissible']}
          ${SideBar.getCollapsedClassName(collapsed)}
          ${dragOver ? styles.dropZone : ''}`}>
        <div
          className={`${mainCss['mdc-drawer__header']} 
          ${styles['top-container']} 
          ${!collapsed ? styles['divheader'] : 
          styles['divheader-without-side-bar']}`}>
          {this.props.errors.diskQuotaExceeded && (
            <span
              isotip={t('sideBar.errors.diskQuotaExceeded')}
              isotip-position='bottom-start'
              isotip-size='small'>
              disc_full
            </span>
          )}
          <div className={`${!collapsed ? styles['add-signature-toggle'] : ''}`}>

            { collapsed ?  
              <span
               className={styles['toggle-without-side-bar']}
               isotip-position='bottom-end'
               isotip-size='larger'>
                <IconButton onClick={this.props.sideBarToggle}>
                <span className='lf-icon-angle-right'></span>
                </IconButton>   
              </span> :  
              <span
               className={styles.toggle, styles['button-toggle']}
               isotip={t('sideBar.hide')}
               isotip-position='bottom-end'
               isotip-size='larger'>
                <IconButton onClick={this.props.sideBarToggle}>
                <span className='lf-icon-angle-left'></span>
                </IconButton> 
               </span> 
            } 
            <button
              style={{ height: 48 }}
              className={`${mainCss['mdc-button']}
                      ${mainCss['mdc-button']} ${styles['nueva-firma']}`}
              onClick={this.handleOnNewSending}>
              <img
                className={styles.plusbuttton}
                border='0'
                src='assets/images/icon-add-round.svg'></img>
              { !collapsed ?<span className='mdc-button__label' style={{ fontSize: 10.6 }}>
                {t('sideBar.newRequest')}
              </span> : "" }
            </button>
          </div>
        </div>
        <PerfectScrollbar>
          <MenuContainer collapsed={collapsed} country={this.props.application.country}/>
        </PerfectScrollbar>
      </aside>
    );
  }


  onNewSending() {
    const { lefebvre } = this.props;

    this.props.newMessage('signature', lefebvre.sign);
    this.props.setAppTitle(i18n.t('topBar.app'));
    this.props.setTitle(i18n.t('messageEditor.title'));
    this.props.setUserApp('lefebvredalloz'); 
  }

  static getCollapsedClassName(collapsed) {
    return collapsed ? '' : `${styles.open} ${mainCss['mdc-drawer--open']}`;
  }
}

SideBar.propTypes = {
  t: PropTypes.func.isRequired,
  sideBarToggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  application: PropTypes.object,
  errors: PropTypes.object,
  newMessage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  application: state.application,
  errors: state.application.errors,
  lefebvre: state.lefebvre
});

const mapDispatchToProps = dispatch => ({
  newMessage: (sendingType, sign) => editNewMessage(dispatch, sendingType, [], [], sign),
  setAvailableSignatures: num => dispatch(setAvailableSignatures(num)),
  setNumAvailableSignatures: num => dispatch(setNumAvailableSignatures(num)),
  setTitle: title => dispatch(setTitle(title)),
  setAppTitle: title => dispatch(setAppTitle(title)),
  setUserApp: app => dispatch(setUserApp(app))
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    setAvailableSignatures: num => dispatchProps.setAvailableSignatures(num),
    setNumAvailableSignatures: num => dispatchProps.setNumAvailableSignatures(num),
    setTitle: title => dispatchProps.setTitle(title),
    setUserApp: app => dispatchProps.setUserApp(app)
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate()(SideBar));
