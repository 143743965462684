import React, { Component } from "react";
import { connect } from "react-redux";
import TopBarMessageList from "./top-bar-message-list";
import stylesES from "./main-bar.scss";
import mainCssES from "../../styles/main.scss";
import stylesFR from "./main-barFR.scss";
import mainCssFR from "../../styles/mainFR.scss";

export class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    switch (this.props.application.country) {
      case "FR":
        this.state.styles = stylesFR;
        this.state.mainCss = mainCssFR;
        break;
      case "ES":
        this.state.styles = stylesES;
        this.state.mainCss = mainCssES;
      default:
        break;
    }
  }

  render() {
    const props = this.props;
    const { styles, mainCss } = this.state;
    const {
      sideBarToggle,
      selectedMessages,
      selectedMessagesAllUnread,
      outbox,
      toggleMessageSeen
    } = props;
    const collapsed = props.sideBarCollapsed;
    let title = props.title;
    if (
      props.selectedFolder &&
      props.selectedFolder.name &&
      props.selectedFolder.type !== FolderTypes.INBOX
    ) {
      //title = `${props.selectedFolder.name} - ${title}`;
      //title = `${props.selectedFolder.name}`;
    }
    return (
      <header
        className={`${styles.topBar}  }
      ${collapsed ? "" : styles["with-side-bar"]}
      ${mainCss["mdc-top-app-bar"]} ${mainCss["mdc-top-app-bar--fixed"]} ${
          styles.divheader
        }`}
      >
        <TopBarMessageList
          title={title}
          collapsed={collapsed}
          sideBarToggle={sideBarToggle}
          selectedMessages={selectedMessages}
          onDeleteClick={() => this.onDelete(props.deleteMessages)}
          selectedMessagesAllUnread={selectedMessagesAllUnread}
          onMarkReadClick={() => props.setMessagesSeen(true)}
          onMarkUnreadClick={() => props.setMessagesSeen(false)}
        />
      </header>
    );
  }
}

const mapStateToProps = state => ({
  application: state.application
});


export default connect(mapStateToProps, null)(TopBar);
