import React, { useState, useEffect, useRef } from "react";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import i18n from 'i18next';
import { getContactsCentinela, getContactsLexon, getBBDDLexon, getContactsCentinelaSMS, getContactsCustomerarea } from '../../../services/api-signaturit';
import style from './contacts.scss';
import Checkbox from "../../form/checkbox/checkbox";
import { DialogComponent } from '@syncfusion/ej2-react-popups';


const Contacts = props => {
  const centinela = props.lefebvre.roles.some(rol => rol === 'Centinela');

  const lexon = props.lefebvre.roles.some(rol => rol === 'Lexon');

  const customerarea = props.lefebvre.roles.some(rol => rol === 'Portal de Clientes');
  
  const contactValue = (!centinela && lexon)? 'lexon' : ((!centinela && !lexon && customerarea)? 'customerarea' : 'centinela');
  
  const [contacts, setContacts] = useState([]);

  const [selectedOption, setSelectedOption] = useState(contactValue);

  const [contactsCustomerarea, setContactsCustomerarea] = useState([]);

  const [contactsCentinela, setContactsCentinela] = useState([]);

  const [contactsLexon, setContactsLexon] = useState([]);

  const [isData, setIsdata] = useState(true);

  const [numberCheckeds, setNumberCheckeds] = useState(0);

  const [selectContact, setSelectContact] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const prevAddress = usePrevious(props.addresses);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const [filter, setFilter] = useState('');

  const validData = contacts => {
    if (contacts.data.length === 0) {
      setIsdata(false);
    }
  };


  const getDataCentinela = async () => {
    if (contacts.length == 0 && isData) {
      const user = props.lefebvre.userId;
      let contactsCentinela;
      if (props.sendingType === 'smsCertificate'){
        contactsCentinela = await getContactsCentinelaSMS(user);
      } else {
        contactsCentinela = await getContactsCentinela(user);
      }
      validData(contactsCentinela);
      const newContactsCentinela = [];
      contactsCentinela.data.forEach(contact => {
        const emailExists = props.addresses.some(address => (address.address === contact.email));
        contact.checked = emailExists;
        newContactsCentinela.push(contact);
      });
      setContacts([...newContactsCentinela]);
      setContactsCentinela([...newContactsCentinela]);
    }
  };

  const getDataLexon = async () => {
    if (contacts.length === 0 && isData) {
      //const user = props.lefebvre.idUserApp;
      //const bbdd = "lexon_admin_02"; //props.lefebvre.bbdd;
      const env = props.lefebvre.env;

      getBBDDLexon(props.lefebvre.userId, props.lefebvre.idClienteLef, env)
        .then(lexDataBases => {
          if (lexDataBases && lexDataBases.data !== null) {
            const lexUserId = lexDataBases.data.idUser;
            const contactsLexon = [];

            lexDataBases.data.companies.forEach(company => {
              getContactsLexon(lexUserId, company.idCompany, company.bbdd, env)
                .then(contacts => {
                  contacts.data.data.forEach(contact => {
                    if (contact.email && contact.email !== null) {
                      const emailExists = props.addresses.some(address => (address.address === contact.email));
                      contact.id = `${contact.id}_${contact.idType}_${contact.entityType}_${company.bbdd}`;
                      contact.checked = emailExists;
                      //if (contactsLexon.length < 350){
                      contactsLexon.push(contact);
                      //}
                    }
                  });
                  setContactsLexon([...contactsLexon]);
                });
            });
          }
        //const contactsLexon = await getContactsLexon(user, bbdd, env);
        })
        .catch(err => console.log(err));

      //setContactsLexon([...newContactsLexon]);
    }
  };

  const validDataCustomerarea = contacts => {
    if (contacts.data.contacts.length === 0) {
      setIsdata(false);
    }
  };

  const getDataCustomerarea = async () => {
    if (contacts.length == 0 && isData) {
      const idClienteLef = props.lefebvre.idClienteLef;
      const idClient= props.lefebvre.idCliente
      let contactsCustomerarea;
      contactsCustomerarea = await getContactsCustomerarea(idClienteLef, idClient, props.lefebvre.token);
      
      validDataCustomerarea(contactsCustomerarea);
      const newContactsCustomerarea = [];
      var i =0;
      contactsCustomerarea.data.contacts.forEach(contact => {
        const emailExists = props.addresses.some(address => (address.address === contact.email));
        contact.id = `${i}`;
        contact.checked = emailExists;
        newContactsCustomerarea.push(contact);
        i++;
      });
      setContacts([...newContactsCustomerarea]);
      setContactsCustomerarea([...newContactsCustomerarea]);
    }
  };

  useEffect(() => {
    const numberCheckeds = contacts.filter(contact => contact.checked == true);
    setNumberCheckeds(numberCheckeds.length);
  }, [numberCheckeds, contacts]);

  function findRemovedContact(currentValue, index, arr) {
    let ret = false;
    this.forEach(address => {
      if (currentValue.checked === true && currentValue.email === address.address) {
        ret = true;
      }
    });
    return ret;
  }

  useEffect(() => {
    if (prevAddress && props.addresses && prevAddress.length > props.addresses.length) {
      let contactFound = false;
      if (centinela && contactsCentinela && contactsCentinela.length) {
        const cenCheckedContactsOriginal = contactsCentinela.filter(c => c.checked === true);
        const cenCheckedContactsFormated = [];
        cenCheckedContactsOriginal.map(c => cenCheckedContactsFormated.push({address: c.email, name: c.name}));
        if (cenCheckedContactsOriginal) {
          const cenRemovedContacts = cenCheckedContactsFormated.filter(({ address: id1 }) => !props.addresses.some(({ address: id2 }) => id2 === id1));
          const removedIndex = contactsCentinela.findIndex(findRemovedContact, cenRemovedContacts);
          if (prevAddress.length > props.addresses.length) {
            if (removedIndex !== -1) {
              contactsCentinela[removedIndex].checked = false;
              contactFound = true;
            }
          }

          setContactsCentinela([...contactsCentinela]);
        }
      }

      if (!contactFound && lexon && contactsLexon && prevAddress.length > props.addresses.length) {
        const lexCheckedContactsOriginal = contactsLexon.filter(c => c.checked === true);
        const lexCheckedContactsFormated = [];
        lexCheckedContactsOriginal.map(c => lexCheckedContactsFormated.push({address: c.email, name: c.name}));
        if (lexCheckedContactsOriginal) {
          const lexRemovedContacts = lexCheckedContactsFormated.filter(({ address: id1 }) => !props.addresses.some(({ address: id2 }) => id2 === id1));
          const removedIndexLex = contactsLexon.findIndex(findRemovedContact, lexRemovedContacts);
          if (prevAddress.length > props.addresses.length) {
            if (removedIndexLex !== -1) {
              contactsLexon[removedIndexLex].checked = false;
              contactFound = true;
            }
          }
          setContactsLexon([...contactsLexon]);
        }
      }

      if (!contactFound && customerarea && contactsCustomerarea && prevAddress.length > props.addresses.length) {
        const cusCheckedContactsOriginal = contactsCustomerarea.filter(c => c.checked === true);
        const cusCheckedContactsFormated = [];
        cusCheckedContactsOriginal.map(c => cusCheckedContactsFormated.push({address: c.email, name: c.name}));
        if (cusCheckedContactsOriginal) {
          const cusRemovedContacts = cusCheckedContactsFormated.filter(({ address: id1 }) => !props.addresses.some(({ address: id2 }) => id2 === id1));
          const removedIndexCus = contactsCustomerarea.findIndex(findRemovedContact, cusRemovedContacts);
          if (prevAddress.length > props.addresses.length) {
            if (removedIndexCus !== -1) {
              contactsCustomerarea[removedIndexCus].checked = false;
              contactFound = true;
            }
          }
          setContactsCustomerarea([...contactsCustomerarea]);
        }
      }
      setContacts([...contacts]);
    }
  }, [props.addresses.join(",")]);

  useEffect(() => {
    
    if(lexon){
      getDataLexon();
      if(!centinela){
        setSelectedOption('lexon');
      }
    }
    if(customerarea){
      getDataCustomerarea();
      if(!centinela && !lexon){
        setSelectedOption('customerarea');
      }
    }
    if(centinela){
      getDataCentinela();
      setSelectedOption('centinela');
    }

    
  }, []);

  useEffect(() => {
    setSelectContact([]);
    if(centinela){
      setSelectContact(prevSelectContact => [
        ...prevSelectContact,
        {Id: 'centinela', SelectContact: i18n.t('contacts.centinela')}
      ]);
    }

    if(lexon){
      setSelectContact(prevSelectContact => [
        ...prevSelectContact,
        { Id: 'lexon', SelectContact: i18n.t('contacts.lexon')}
      ]);
    }
    if(customerarea){
      setSelectContact(prevSelectContact => [
        ...prevSelectContact,
        { Id: 'customerarea', SelectContact: i18n.t('contacts.customerarea')}
      ]);
    }


  }, []);

  useEffect(() => {
    if (selectedOption === 'centinela') {
      setSelectedOption('centinela');
      setContacts(contactsCentinela);
    } else if (selectedOption === 'lexon') {
      setSelectedOption('lexon');
      setContacts(contactsLexon);
    } else if (selectedOption === 'customerarea') {
      setSelectedOption('customerarea');
      setContacts(contactsCustomerarea);
    }
  });

  const contactFields = { text: 'SelectContact', value: 'Id' };

  const onChangeContacts = e => {
    setSelectedOption(e.value);
    if (e.value === 'lexon') {
      setContacts(contactsLexon);
    } else if (e.value === 'centinela') {
      setContacts(contactsCentinela);
    } else if (e.value === 'customerarea') {
      setContacts(contactsCustomerarea);
    }
  };

  const filterContact = e => {
    setFilter(e.target.value);
  };

  const handleChecked = e => {
    const isCheck = !e.target.checked ? false : true;
    const contactId = e.target.value;
    contacts.forEach(contact => {
      if (selectedOption === 'lexon') {
        if (contact.id === contactId) {
          if (props.sendingType === 'smsCertificate' && (contact.mobilePhone === '' || contact.mobilePhone === null || contact.mobilePhone === undefined)) {
            contact.checked = false;
            setShowModal(true);
          } else {
            contact.checked = isCheck;
          }
        }
      } else if (selectedOption === 'centinela') {
        if (contact.contactId == contactId) {
          if (props.sendingType === 'smsCertificate' && (contact.phoneNumber1 === '' || contact.phoneNumber1 === null || contact.phoneNumber1 === undefined)) {
            contact.checked = false;
            setShowModal(true);
          } else {
            contact.checked = isCheck;
          }
        }
      } else if (selectedOption === 'customerarea') {
        if (contact.contactId == contactId) {
          if (props.sendingType === 'smsCertificate' && (contact.mobilePhone === '' || contact.mobilePhone === null || contact.mobilePhone === undefined)) {
            contact.checked = false;
            setShowModal(true);
          } else {
            contact.checked = isCheck;
          }
        }
      }
    });
    setContacts([...contacts]);
  };

  const getContactsInfo = () => {
    const newContacts = [];
    contactsCentinela.forEach(contact => {
      if (contact.checked) {
        const address = props.sendingType === 'smsCertificate' ? contact.phoneNumber1 : contact.email;
        const name = contact.name;
        const email = contact.email;
        const phone = contact.phoneNumber1;
        setTimeout(() => {
          //newContacts.push(props.id, contact.email, contact.name);
          //props.onAddressAdd(props.id, address, name, email, phone);
          newContacts.push(props.id, address, name, email, phone);
          props.onAddressAdd(props.id, address, name, email, phone);
        });
      }
    });
    contactsLexon.forEach(contact => {
      if (contact.checked) {
        const address = props.sendingType === 'smsCertificate' ? contact.mobilePhone : contact.email;
        const name = contact.name;
        const email = contact.email;
        const phone = contact.mobilePhone;
        setTimeout(() => {
          // newContacts.push(props.id, contact.email, contact.name);
          // props.onAddressAdd(props.id, contact.email, contact.name);
          newContacts.push(props.id, address, name, email, phone);
          props.onAddressAdd(props.id, address, name, email, phone);
        });
      }
    });
    contactsCustomerarea.forEach(contact => {
      if (contact.checked) {
        const address = props.sendingType === 'smsCertificate' ? contact.mobilePhone : contact.email;
        const name = contact.name;
        const email = contact.email;
        const phone = contact.mobilePhone;
        setTimeout(() => {
          // newContacts.push(props.id, contact.email, contact.name);
          // props.onAddressAdd(props.id, contact.email, contact.name);
          newContacts.push(props.id, address, name, email, phone);
          props.onAddressAdd(props.id, address, name, email, phone);
        });
      }
    });
    //setContacts([]);
    //setAddresses([...props.addresses, ...newContacts])
    //setSelectedOption('');
    props.dialogClose();
  };

  const dialogClose = () => {
    //setContacts([]);
    //setContactsLexon([]);
    props.dialogClose();
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const dialogContent = `
    <span class="lf-icon-information modal-icon-content"></span>
    <div class="modal-text-content">
      ${i18n.t('noPhoneModal.text')}
    </div>`;

  return (
    <div id="main-contact" className={style['main-contact']}>
      <div className="contact row">
        <div className="input-field col s7 left">
          <input
            name='search'
            type='text'
            placeholder={i18n.t('contacts.search')}
            className={style['serch-contacts']}
            onChange={filterContact}
          />
          <span className={`${style['position-icon']} lf-icon-search`}></span>
        </div>
        <div className={`${style.right} input-field col s5`}>
          <DropDownListComponent
            id="select-contact"
            className={style['select-contact']}
            dataSource={selectContact}
            fields={contactFields}
            change={onChangeContacts}
            value={contactValue}
            popupHeight="220px" />
        </div>
      </div>
      <hr className="clearfix" />
      <ul className="contactos">
        {contacts
          .filter(contact => filter === ''
           || contact.name.includes(filter)
           || contact.email.includes(filter)
           || (contact.mobilePhone !=undefined && contact.mobilePhone.includes(filter))
           || (contact.phoneNumber1 !=undefined && contact.phoneNumber1.includes(filter))
           || contact.name.toLowerCase().includes(filter)
           || contact.email.toLowerCase().includes(filter)
           || contact.name.toUpperCase().includes(filter)
           || contact.email.toUpperCase().includes(filter))
          .slice(0, 100)
          .map((contact, i) =>
            <li className={style['container-list-contacts']} key={(selectedOption === 'centinela') ? i : `${i}_${contact.id}`}>
              <div>
                <p className="light-blue-text font-weight-bold">{(selectedOption === 'centinela' ? contact.phoneNumber1 : contact.mobilePhone)}</p>
              </div>
              <div className={style['list-checked']}>
                <label>
                  <input
                    type="checkbox"
                    checked={contact.checked}
                    onChange={handleChecked}
                    name="checked"
                    id={(selectedOption === 'centinela' || selectedOption === 'customerara') ? contact.contactId : `${contact.id}`}
                    value={(selectedOption === 'centinela' || selectedOption === 'customerara') ? contact.contactId : `${contact.id}`}
                  />
                  <Checkbox
                    checked={contact.checked}
                    onChange={handleChecked}
                    name="checked"
                    id={(selectedOption === 'centinela' || selectedOption === 'customerara') ? contact.contactId : `${contact.id}`}
                    value={(selectedOption === 'centinela' || selectedOption === 'customerara') ? contact.contactId : `${contact.id}`}
                  />
                  <span
                    className={contact.checked
                      ? 'light-blue-text font-weight-bold' :
                      'grey-text font-weight-bold'} >
                    {contact.name}
                  </span>
                  <div className={`${style.email} grey-text`}>{contact.email}</div>
                </label>
              </div>
            </li>
          )}
      </ul>
      <div className="row cont-inf-seleccionados">
        <div className={`${style['select-contact-length']} col s5 light-blue-text`}>
          {numberCheckeds}/{contacts.length} {i18n.t('contacts.selected')}
        </div>
        <div className="col s7 right-align">
          <button className={`${style['btn-modal']} ${style['btn-gen-border']}`}
            onClick={dialogClose} >
            {i18n.t('expirationWidget.cancelButton')}
          </button>
          <button className={`${style['btn-modal']} ${style['btn-gen']}`}
            onClick={getContactsInfo} >
            {i18n.t('expirationWidget.acceptButton')}
          </button>
        </div>
      </div>
      <div className="clearfix"></div>
      <DialogComponent
        id="info2Dialog"
        header=''
        visible={showModal}
        showCloseIcon={true}
        content={dialogContent}
        close={modalClose}
      />
    </div>
  );
};

export default Contacts;
